import React from 'react';
import { SelectionOption } from '../../types/selectionOption';
import { AdoptechReactSelect } from '../AdoptechReactSelect/AdoptechReactSelect';
import { WithCountries } from '../WithCountries/WithCountries';
import { AdoptechReactSelect2 } from '../AdoptechReacSelect2/AdoptechReactSelect2';

interface AdoptechCountrySelectContentProps extends AdoptechCountrySelectProps {
  options: SelectionOption[];
}

const AdoptechCountrySelectContent: React.FC<
  AdoptechCountrySelectContentProps
> = ({
  onChange,
  value,
  disabled,
  placeholder,
  label,
  isMultiSelect,
  options,
  hasError,
  theme,
}) => {
  if (options?.length) {
    const uk = options.find(x => x.value === 'GB');
    const us = options.find(x => x.value === 'US');
    options.splice(options.indexOf(uk), 1);
    options.splice(options.indexOf(us), 1);
    options.unshift(uk, us);
  }

  const SelectComponent =
    theme === 'new' ? AdoptechReactSelect2 : AdoptechReactSelect;

  return (
    <SelectComponent
      id="adoptechCountrySelect"
      isDisabled={disabled}
      isMulti={isMultiSelect}
      options={options}
      onChange={onChange}
      hasError={hasError}
      value={
        isMultiSelect
          ? options.filter(x => value?.split(',').includes(x.value))
          : options.find(x => x.value === value)
      }
      placeholder={placeholder}
      label={label}
    />
  );
};

interface AdoptechCountrySelectProps {
  onChange?: (value: SelectionOption) => void;
  value: string;
  disabled?: boolean;
  placeholder?: string;
  isMultiSelect?: boolean;
  label?: string;
  hasError?: boolean;
  theme?: 'new';
}

export const AdoptechCountrySelect: React.FC<
  AdoptechCountrySelectProps
> = props => (
  <WithCountries>
    {countryOptions => (
      <AdoptechCountrySelectContent {...props} options={countryOptions} />
    )}
  </WithCountries>
);
