import React, { useEffect, useState } from 'react';
import './SupplierProfilePage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { ApplicationState } from '../../../types/applicationState';
import { fetchSupplier } from '../store/supplierThunks';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  supplierPageItemContractsRoute,
  supplierPageItemRoute,
  supplierPageRoute,
} from '../../../components/Routes/Routes';
import { push } from 'connected-react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import classNames from 'classnames';
import { CompanyInformation } from '../../companyProfile/CompanyInformation/CompanyInformation';
import { StatusBadge } from '../SuppliersPage/StatusBadge/StatusBadge';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { EditSupplierDrawer } from '../EditSupplierDrawer/EditSupplierDrawer';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle';
import { useCanFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { VendorSupplyContractorStatus } from '../../../swagger/models/VendorSupplyContractorStatus';
import {
  faHourglassHalf,
  faSignOutAlt,
} from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { EditSupplierStatusDrawer } from '../EditSupplierStatusDrawer/EditSupplierStatusDrawer';
import { SupplierProfileContractsPage } from '../SupplierProfileContractsPage/SupplierProfileContractsPage';

const NotFound = ({ type }: { type: string }) => {
  return (
    <div className="notFound">
      <div style={{ marginTop: '10px' }}>{capitaliseFirst(type)} not found</div>
    </div>
  );
};

export const SupplierProfilePage: React.FC = () => {
  const dispatch = useDispatch();
  const params = useParams() as {
    id: string;
    tabsType: 'contracts' | 'services';
  };
  const id = params.id;
  const tabsType = params.tabsType;

  const [currentTab, setCurrentTab] = useState<
    'profile' | 'contracts' | 'services'
  >('profile');
  const { supplier, fetchSupplierStatus } = useSelector(
    (state: ApplicationState) => state.supplier
  );
  const is404 = useSelector(
    (state: ApplicationState) => state.supplier.fetchSupplierStatus === 'failed'
  );

  useEffect(() => {
    id && dispatch(fetchSupplier(id));
  }, [id]);

  useEffect(() => {
    if (tabsType === 'contracts') {
      setCurrentTab('contracts');
      return;
    }

    if (tabsType === 'services') {
      setCurrentTab('services');
      return;
    }

    setCurrentTab('profile');
  }, [tabsType]);
  const baseCss = 'supplierDetailsPage';
  const [showEditDrawer, setShowEditDrawer] = useState(false);
  const [showEditStatusDrawer, setShowEditStatusDrawer] = useState(false);
  const goBack = () => dispatch(push(supplierPageRoute));

  const handleShowDrawer = () => {
    setShowEditDrawer(true);
  };

  const handleShowStatusDrawer = () => {
    setShowEditStatusDrawer(true);
  };

  const canEdit = supplier?.supplyContractor.access?.update;
  if (is404) {
    return <NotFound type="supplier" />;
  }
  if (fetchSupplierStatus === 'loading' || !supplier) return <LoadingSpinner />;

  const statusIcon = (() => {
    switch (supplier.status) {
      case VendorSupplyContractorStatus.DueDiligence:
        return faUserCircle;
      case VendorSupplyContractorStatus.Pending:
        return faHourglassHalf;
      case VendorSupplyContractorStatus.Approved:
        return faCheckCircle;
      case VendorSupplyContractorStatus.Offboarding:
        return faSignOutAlt;
      case VendorSupplyContractorStatus.Closed:
        return faExclamationTriangle;
      default:
        return faQuestionCircle;
    }
  })();

  return (
    <div>
      <div className={baseCss + '--header'}>
        <FontAwesomeIcon
          onClick={goBack}
          className={baseCss + '--navBack'}
          icon={faAngleLeft}
        />
        <AdoptechOverflowLine className={baseCss + '--title'}>
          {supplier.supplyContractor.name}
        </AdoptechOverflowLine>
        <StatusBadge
          styles={{ marginLeft: '10px' }}
          statusClass={
            supplier.supplyContractor.type === 'SupplyContractor::VendorManaged'
              ? 'vendorManaged'
              : 'adoptechManaged'
          }
          status={
            supplier.supplyContractor.type === 'SupplyContractor::VendorManaged'
              ? 'Vendor Managed'
              : 'Managed by Adoptech'
          }
        />
        <div style={{ marginLeft: 'auto' }}>
          {canEdit && (
            <>
              <AdoptechButton
                variant={AdoptechButtonVariant.Default}
                extraClass={`mr-2 ${baseCss}--status-${supplier.status}`}
                size="middle2"
                icon={statusIcon}
                onClick={handleShowStatusDrawer}
              >
                {capitaliseFirst(supplier.status)}
              </AdoptechButton>
              <AdoptechButton
                variant={AdoptechButtonVariant.Primary}
                icon={faUserCircle}
                size="middle2"
                onClick={handleShowDrawer}
              >
                Edit profile
              </AdoptechButton>
            </>
          )}
          {showEditDrawer && (
            <EditSupplierDrawer
              onClose={() => setShowEditDrawer(false)}
              supplierId={supplier.id}
              supplierContractorId={supplier.supplyContractor.id}
              supplierDetails={supplier}
            />
          )}
          {showEditStatusDrawer && (
            <EditSupplierStatusDrawer
              onClose={() => setShowEditStatusDrawer(false)}
              vendorSupplyContractor={{
                id: supplier.id,
                status: supplier.status,
                additionalNotes: supplier.additionalNotes,
                ownerId: supplier.owner.id,
              }}
            />
          )}
        </div>
      </div>
      <div className="pageTabs">
        <div
          className={classNames('pageTabs--tab', {
            active: currentTab === 'profile',
          })}
          onClick={() =>
            dispatch(push(supplierPageItemRoute.replace(':id', supplier.id)))
          }
        >
          Profile
        </div>
        <div
          className={classNames('pageTabs--tab', {
            active: currentTab === 'contracts',
          })}
          onClick={() =>
            dispatch(
              push(supplierPageItemContractsRoute.replace(':id', supplier.id))
            )
          }
        >
          Contracts
        </div>
        <div
          className={classNames('pageTabs--tab', {
            active: currentTab === 'services',
          })}
          onClick={() => alert('TODO redirect to services')}
        >
          Services
        </div>
      </div>
      {currentTab === 'profile' && (
        <CompanyInformation
          supplyContractor={supplier.supplyContractor}
          type="supplyContractor"
        />
      )}
      {currentTab === 'contracts' && (
        <SupplierProfileContractsPage vendorSupplyContractorId={supplier.id} />
      )}
    </div>
  );
};
