import React from 'react';
import './CompanyInformation.scss';
import { Panel } from '../../../components/Panel/Panel';
import { AdoptechButton } from '../../../components/AdoptechButton/AdoptechButton';
import { CompanyExtended, SupplyContractorModel } from '../../../swagger';
import { formatShortDate } from '../../../functions/formatShortDate';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { AdoptechCheckbox } from '../../../components/AdoptechCheckbox/AdoptechCheckbox';
import { AdoptechTextArea } from '../../../components/AdoptechTextArea/AdoptechTextArea';
import { AdoptechBusinessTypeSelect } from '../AdoptechBusinessTypeSelect/AdoptechBusinessTypeSelect';
import { AdoptechDatePicker } from '../../../components/AdoptechDatePicker/AdoptechDatePicker';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { SelectionOption } from '../../../types/selectionOption';
import { useCompanyInformation } from './useCompanyInformation';
import { useCanFeature } from '../../../functions/access';
import { AccessObject } from '../../../types/accessObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import {
  AdoptechTooltip,
  AdoptechTooltipVariant,
} from '../../../components/AdoptechTooltip/AdoptechTooltip';
import { useBackground } from '../../../hooks/useBackground';

const LeiFieldLabel: React.FC<{ contentsCss: string; identifier: string }> = ({
  contentsCss,
  identifier,
}) => (
  <div className={`${contentsCss}--label`}>
    <AdoptechTooltip
      text="If your company or clients are EU-regulated financial institutions, this is required to comply with DORA"
      identifier={identifier}
      showTooltip
      variant={AdoptechTooltipVariant.Middle}
    >
      <div>
        Legal Entity Identifier(LEI)
        <FontAwesomeIcon className="ml-2" icon={faInfoCircle} />
      </div>
    </AdoptechTooltip>
  </div>
);

interface CompanyInformationProps {
  company?: CompanyExtended;
  supplyContractor?: SupplyContractorModel;
  type: 'company' | 'supplyContractor';
}

export const CompanyInformation: React.FC<CompanyInformationProps> = props => {
  const isSupplier = props.type === 'supplyContractor';
  const isCompany = props.type === 'company';

  useBackground(null);

  const formHandler = useCompanyInformation(props.company);
  const canEditCompany = useCanFeature(AccessObject.company_manage);
  const canEdit = isCompany ? canEditCompany : false;

  if (formHandler.isInEditMode && isCompany) {
    return <EditCompanyInformation formHandler={formHandler} />;
  }

  const baseCss = 'companyInformation';
  const contentsCss = `${baseCss}--contents`;

  const companyOrContractor = isCompany
    ? props.company
    : props.supplyContractor;

  return (
    <div className={baseCss}>
      <Panel>
        <div className={`${baseCss}--header`}>
          <div className={`${baseCss}--title`}>Company Information</div>
          {canEdit && (
            <div className={`${baseCss}--button`}>
              <AdoptechButton
                busy={
                  formHandler.updateCompanyProfileRequest ||
                  formHandler.isCreatingCompanyIdentifier
                }
                icon={faPen}
                onClick={formHandler.handleEditClick}
              >
                Edit
              </AdoptechButton>
            </div>
          )}
        </div>
        <div className={`${baseCss}--contents`}>
          <div className={`${contentsCss}--row`}>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Legal Name</div>
              <div className={`${contentsCss}--data`}>
                {isSupplier
                  ? props.supplyContractor.name
                  : props.company.legalName}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>
                Trading name{' '}
                {isSupplier
                  ? props.supplyContractor.tradeName
                  : props.company.preferTradingName && (
                      <>(used in company policies)</>
                    )}
              </div>
              <div className={`${contentsCss}--data`}>
                {companyOrContractor.tradeName || '-'}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>
                Country of Incorporation
              </div>
              <div className={`${contentsCss}--data`}>
                {isCompany
                  ? props.company.countryOfIncorporationName
                  : props.supplyContractor.countryOfIncorporationName || '-'}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>
                Date of Incorporation
              </div>
              <div className={`${contentsCss}--data`}>
                {props.supplyContractor
                  ? props.supplyContractor.dateOfIncorporation
                    ? formatShortDate(
                        props.supplyContractor.dateOfIncorporation?.toDateString() ||
                          '-'
                      )
                    : '-'
                  : formatShortDate(props.company.incorporatedOn)}
              </div>
            </div>
          </div>
          <div className={`${contentsCss}--row`}>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Business Type</div>
              <div className={`${contentsCss}--data`}>
                {props.supplyContractor
                  ? props.supplyContractor.businessTypeName || '-'
                  : props.company.companyTypeDescription}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Company Identifier</div>
              <div className={`${contentsCss}--data`}>
                {props.supplyContractor
                  ? props.supplyContractor.companyIdentifier || '-'
                  : formHandler.companyIdentifierDisplay || '-'}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <LeiFieldLabel contentsCss={contentsCss} identifier="show-lei" />
              <div className={`${contentsCss}--data`}>
                {companyOrContractor.legalEntityIdentifier || '-'}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Registered address</div>
              <div className={`${contentsCss}--data`}>
                {props.supplyContractor
                  ? props.supplyContractor.registeredAddress || '-'
                  : companyOrContractor.registeredAddress || '-'}
              </div>
            </div>
          </div>
          <div
            className={`${contentsCss}--row ${contentsCss}--row-wide-column`}
          >
            <div className={`${contentsCss}--column`}>
              {' '}
              <div
                className={`${contentsCss}--label ${contentsCss}--label-span`}
              >
                Business Summary
              </div>
              <div className={`${contentsCss}--data ${contentsCss}--data-span`}>
                {isSupplier
                  ? props.supplyContractor.businessSummary || '-'
                  : props.company.businessSummary || '-'}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
};

interface EditCompanyInformationProps {
  formHandler: ReturnType<typeof useCompanyInformation>;
}

export const leiError = (lei: string) => {
  if (!lei) return false;

  const hasLeiError = lei.length > 0 && (lei.length > 20 || lei.length < 10);
  return hasLeiError;
};

const EditCompanyInformation: React.FC<EditCompanyInformationProps> = ({
  formHandler,
}) => {
  const {
    company,
    updatedCompany,
    setUpdatedCompany,
    updatedIdentifier,
    setUpdatedIdentifier,
    updatedIdentifierType,
    setUpdatedIdentifierType,
    identifierTypesOptions,
    handleSaveClick,
    setIsInEditMode,
    updateCompanyProfileRequest,
    isCreatingCompanyIdentifier,
  } = formHandler;

  const baseCss = 'companyInformation';
  const contentsCss = `${baseCss}--contents`;

  const leiValue = updatedCompany.legalEntityIdentifier || '';

  const hasLeiError = leiError(leiValue);

  return (
    <div className={baseCss}>
      <Panel>
        <div className={`${baseCss}--header`}>
          <div className={`${baseCss}--title`}>Company Information</div>
          <div className={`${baseCss}--button`}>
            <AdoptechButton
              onClick={() => {
                setUpdatedCompany({ ...company });
                setIsInEditMode(false);
              }}
            >
              Cancel
            </AdoptechButton>
            <AdoptechButton
              extraClass="companyInformation--button-withLeftMargin"
              onClick={handleSaveClick}
              disabled={hasLeiError}
            >
              Save
            </AdoptechButton>
          </div>
        </div>
        <div className={contentsCss}>
          <div className={`${contentsCss}--row`}>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Legal Name</div>
              <div className={`${contentsCss}--data`}>
                <AdoptechTextInput
                  id="legalName"
                  onChange={event => {
                    setUpdatedCompany({
                      ...updatedCompany,
                      legalName: event.target.value,
                    });
                  }}
                  type="text"
                  value={updatedCompany.legalName}
                />
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>
                Trading name{' '}
                {company.preferTradingName && <>(used in company policies)</>}
              </div>
              <div className={`${contentsCss}--data`}>
                <AdoptechTextInput
                  id="tradeName"
                  onChange={event => {
                    setUpdatedCompany({
                      ...updatedCompany,
                      tradeName: event.target.value,
                    });
                  }}
                  type="text"
                  value={updatedCompany.tradeName}
                />
                <AdoptechCheckbox
                  className="companyInformation--checkbox"
                  disabled={!(updatedCompany.tradeName?.trim().length > 0)}
                  checked={updatedCompany.preferTradingName}
                  id="preferTradingName"
                  label="Use trading name in Policies?"
                  onChange={event =>
                    setUpdatedCompany({
                      ...updatedCompany,
                      preferTradingName: event.currentTarget.checked,
                    })
                  }
                />
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>
                Country of Incorporation
              </div>
              <div className={`${contentsCss}--data`}>
                {company.countryOfIncorporationName}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>
                Date of Incorporation
              </div>
              <div className={`${contentsCss}--data`}>
                <AdoptechDatePicker
                  deadLines={[]}
                  disabled={false}
                  labelText={null}
                  minDate={null}
                  outputDate={new Date(updatedCompany.incorporatedOn)}
                  onDateSelect={date =>
                    setUpdatedCompany({
                      ...updatedCompany,
                      incorporatedOn: date.toDateString(),
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className={`${contentsCss}--row`}>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Business Type</div>
              <div className={`${contentsCss}--data`}>
                <AdoptechBusinessTypeSelect
                  onChange={option =>
                    setUpdatedCompany({
                      ...updatedCompany,
                      companyType: option.value,
                      companyTypeDescription: option.label,
                    })
                  }
                  value={updatedCompany.companyType}
                />
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Company Identifier</div>
              <div className={`${contentsCss}--data`}>
                <AdoptechReactSelect
                  id="identifierType"
                  onChange={(option: SelectionOption) => {
                    setUpdatedIdentifierType(option.value);
                  }}
                  options={identifierTypesOptions}
                  placeholder="Please select identifier type"
                  value={identifierTypesOptions?.find(
                    opt => opt.value === updatedIdentifierType
                  )}
                />
                <AdoptechTextInput
                  id="companyIdentifier"
                  onChange={event => {
                    setUpdatedIdentifier(event.target.value);
                  }}
                  type="text"
                  value={updatedIdentifier}
                />
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <LeiFieldLabel contentsCss={contentsCss} identifier="edit-lei" />
              <div className={`${contentsCss}--data`}>
                <AdoptechTextInput
                  id="legalEntityIdentifier"
                  onChange={event => {
                    setUpdatedCompany({
                      ...updatedCompany,
                      legalEntityIdentifier: event.target.value,
                    });
                  }}
                  type="text"
                  value={updatedCompany.legalEntityIdentifier}
                  hasError={hasLeiError}
                />
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Registered address</div>
              <div className={`${contentsCss}--data`}>
                <AdoptechTextInput
                  id="registeredAddress"
                  onChange={event => {
                    setUpdatedCompany({
                      ...updatedCompany,
                      registeredAddress: event.target.value,
                    });
                  }}
                  type="text"
                  value={updatedCompany.registeredAddress}
                />
              </div>
            </div>
          </div>
          <div
            className={`${contentsCss}--row ${contentsCss}--row-wide-column`}
          >
            <div className={`${contentsCss}--column`}>
              <div
                className={`${contentsCss}--label ${contentsCss}--label-span`}
              >
                Business Summary
              </div>
              <div className={`${contentsCss}--data ${contentsCss}--data-span`}>
                <AdoptechTextArea
                  id="businessSummary"
                  onChange={event => {
                    setUpdatedCompany({
                      ...updatedCompany,
                      businessSummary: event.target.value,
                    });
                  }}
                  placeholder="Describe your business services. This will be used in documents you generate. For example: ACME Ltd was founded in XXXX and is a specialist in cloud computing services. We work with government bodies, financial service organisations, and high-growth start-ups globally supporting them day in and day out to achieve their goals."
                  value={updatedCompany.businessSummary}
                  extraStyles={{ height: '100px' }}
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
};
