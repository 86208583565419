/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SupplyContractItemBringServiceInHouseComplexity {
  Easy = 'easy',
  Medium = 'medium',
  High = 'high',
}

export function SupplyContractItemBringServiceInHouseComplexityFromJSON(
  json: any
): SupplyContractItemBringServiceInHouseComplexity {
  return SupplyContractItemBringServiceInHouseComplexityFromJSONTyped(
    json,
    false
  );
}

export function SupplyContractItemBringServiceInHouseComplexityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractItemBringServiceInHouseComplexity {
  return json as SupplyContractItemBringServiceInHouseComplexity;
}

export function SupplyContractItemBringServiceInHouseComplexityToJSON(
  value?: SupplyContractItemBringServiceInHouseComplexity | null
): any {
  return value as any;
}
