/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SupplyContractContractType {
  Standalone = 'standalone',
  Master = 'master',
  Associated = 'associated',
}

export function SupplyContractContractTypeFromJSON(
  json: any
): SupplyContractContractType {
  return SupplyContractContractTypeFromJSONTyped(json, false);
}

export function SupplyContractContractTypeFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractContractType {
  return json as SupplyContractContractType;
}

export function SupplyContractContractTypeToJSON(
  value?: SupplyContractContractType | null
): any {
  return value as any;
}
