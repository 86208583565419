/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SupplyContractDocumentLink,
  SupplyContractDocumentLinkFromJSON,
  SupplyContractDocumentLinkFromJSONTyped,
  SupplyContractDocumentLinkToJSON,
  SupplyContractItemBringServiceInHouseComplexity,
  SupplyContractItemBringServiceInHouseComplexityFromJSON,
  SupplyContractItemBringServiceInHouseComplexityFromJSONTyped,
  SupplyContractItemBringServiceInHouseComplexityToJSON,
  SupplyContractItemDataSensitivity,
  SupplyContractItemDataSensitivityFromJSON,
  SupplyContractItemDataSensitivityFromJSONTyped,
  SupplyContractItemDataSensitivityToJSON,
  SupplyContractItemImpactOfDiscontinuing,
  SupplyContractItemImpactOfDiscontinuingFromJSON,
  SupplyContractItemImpactOfDiscontinuingFromJSONTyped,
  SupplyContractItemImpactOfDiscontinuingToJSON,
  SupplyContractItemPortability,
  SupplyContractItemPortabilityFromJSON,
  SupplyContractItemPortabilityFromJSONTyped,
  SupplyContractItemPortabilityToJSON,
  SupplyContractItemReliance,
  SupplyContractItemRelianceFromJSON,
  SupplyContractItemRelianceFromJSONTyped,
  SupplyContractItemRelianceToJSON,
  SupplyContractItemStatus,
  SupplyContractItemStatusFromJSON,
  SupplyContractItemStatusFromJSONTyped,
  SupplyContractItemStatusToJSON,
  SupplyContractItemSubstitutionChallenge,
  SupplyContractItemSubstitutionChallengeFromJSON,
  SupplyContractItemSubstitutionChallengeFromJSONTyped,
  SupplyContractItemSubstitutionChallengeToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface SupplyContractItemModel
 */
export interface SupplyContractItemModel {
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  description: string | null;
  /**
   *
   * @type {SupplyContractItemStatus}
   * @memberof SupplyContractItemModel
   */
  status: SupplyContractItemStatus;
  /**
   *
   * @type {VendorUser}
   * @memberof SupplyContractItemModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  serviceId: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  serviceType: string | null;
  /**
   *
   * @type {number}
   * @memberof SupplyContractItemModel
   */
  annualCost: number | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  nextReviewDate: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  currency: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SupplyContractItemModel
   */
  critical: boolean | null;
  /**
   *
   * @type {Array<string>}
   * @memberof SupplyContractItemModel
   */
  businessFunctions: Array<string>;
  /**
   *
   * @type {Array<SupplyContractDocumentLink>}
   * @memberof SupplyContractItemModel
   */
  documentLinks: Array<SupplyContractDocumentLink>;
  /**
   *
   * @type {SupplyContractItemReliance}
   * @memberof SupplyContractItemModel
   */
  reliance: SupplyContractItemReliance;
  /**
   *
   * @type {SupplyContractItemImpactOfDiscontinuing}
   * @memberof SupplyContractItemModel
   */
  impactOfDiscontinuing: SupplyContractItemImpactOfDiscontinuing;
  /**
   *
   * @type {SupplyContractItemPortability}
   * @memberof SupplyContractItemModel
   */
  portability: SupplyContractItemPortability;
  /**
   *
   * @type {SupplyContractItemBringServiceInHouseComplexity}
   * @memberof SupplyContractItemModel
   */
  bringServiceInHouseComplexity: SupplyContractItemBringServiceInHouseComplexity;
  /**
   *
   * @type {SupplyContractItemSubstitutionChallenge}
   * @memberof SupplyContractItemModel
   */
  substitutionChallenge: SupplyContractItemSubstitutionChallenge;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  serviceContinuityPlan: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  alternativeSuppliers: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  serviceProvisionLocation: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  dataProcessingLocation: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  dataStorageLocation: string | null;
  /**
   *
   * @type {SupplyContractItemDataSensitivity}
   * @memberof SupplyContractItemModel
   */
  dataSensitivity: SupplyContractItemDataSensitivity;
  /**
   *
   * @type {boolean}
   * @memberof SupplyContractItemModel
   */
  personallyIdentifiableInformationStored: boolean | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  supplyContractId: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SupplyContractItemModel
   */
  dataStored: boolean | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractItemModel
   */
  updatedAt: string;
}

export function SupplyContractItemModelFromJSON(
  json: any
): SupplyContractItemModel {
  return SupplyContractItemModelFromJSONTyped(json, false);
}

export function SupplyContractItemModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractItemModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
    status: SupplyContractItemStatusFromJSON(json['status']),
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    serviceId: json['service_id'],
    serviceType: json['service_type'],
    annualCost: json['annual_cost'],
    nextReviewDate: json['next_review_date'],
    currency: json['currency'],
    critical: json['critical'],
    businessFunctions: json['business_functions'],
    documentLinks: (json['document_links'] as Array<any>).map(
      SupplyContractDocumentLinkFromJSON
    ),
    reliance: SupplyContractItemRelianceFromJSON(json['reliance']),
    impactOfDiscontinuing: SupplyContractItemImpactOfDiscontinuingFromJSON(
      json['impact_of_discontinuing']
    ),
    portability: SupplyContractItemPortabilityFromJSON(json['portability']),
    bringServiceInHouseComplexity:
      SupplyContractItemBringServiceInHouseComplexityFromJSON(
        json['bring_service_in_house_complexity']
      ),
    substitutionChallenge: SupplyContractItemSubstitutionChallengeFromJSON(
      json['substitution_challenge']
    ),
    serviceContinuityPlan: json['service_continuity_plan'],
    alternativeSuppliers: json['alternative_suppliers'],
    serviceProvisionLocation: json['service_provision_location'],
    dataProcessingLocation: json['data_processing_location'],
    dataStorageLocation: json['data_storage_location'],
    dataSensitivity: SupplyContractItemDataSensitivityFromJSON(
      json['data_sensitivity']
    ),
    personallyIdentifiableInformationStored:
      json['personally_identifiable_information_stored'],
    supplyContractId: json['supply_contract_id'],
    dataStored: json['data_stored'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function SupplyContractItemModelToJSON(
  value?: SupplyContractItemModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    status: SupplyContractItemStatusToJSON(value.status),
    owner: VendorUserToJSON(value.owner),
    service_id: value.serviceId,
    service_type: value.serviceType,
    annual_cost: value.annualCost,
    next_review_date: value.nextReviewDate,
    currency: value.currency,
    critical: value.critical,
    business_functions: value.businessFunctions,
    document_links: (value.documentLinks as Array<any>).map(
      SupplyContractDocumentLinkToJSON
    ),
    reliance: SupplyContractItemRelianceToJSON(value.reliance),
    impact_of_discontinuing: SupplyContractItemImpactOfDiscontinuingToJSON(
      value.impactOfDiscontinuing
    ),
    portability: SupplyContractItemPortabilityToJSON(value.portability),
    bring_service_in_house_complexity:
      SupplyContractItemBringServiceInHouseComplexityToJSON(
        value.bringServiceInHouseComplexity
      ),
    substitution_challenge: SupplyContractItemSubstitutionChallengeToJSON(
      value.substitutionChallenge
    ),
    service_continuity_plan: value.serviceContinuityPlan,
    alternative_suppliers: value.alternativeSuppliers,
    service_provision_location: value.serviceProvisionLocation,
    data_processing_location: value.dataProcessingLocation,
    data_storage_location: value.dataStorageLocation,
    data_sensitivity: SupplyContractItemDataSensitivityToJSON(
      value.dataSensitivity
    ),
    personally_identifiable_information_stored:
      value.personallyIdentifiableInformationStored,
    supply_contract_id: value.supplyContractId,
    data_stored: value.dataStored,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
