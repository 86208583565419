/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorConnectionPermissions,
  VendorConnectionPermissionsFromJSON,
  VendorConnectionPermissionsFromJSONTyped,
  VendorConnectionPermissionsToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorConnectionUpdatePayloadVendorConnection
 */
export interface VendorConnectionUpdatePayloadVendorConnection {
  /**
   *
   * @type {string}
   * @memberof VendorConnectionUpdatePayloadVendorConnection
   */
  ownerId?: string;
  /**
   *
   * @type {VendorConnectionPermissions}
   * @memberof VendorConnectionUpdatePayloadVendorConnection
   */
  permissions?: VendorConnectionPermissions;
}

export function VendorConnectionUpdatePayloadVendorConnectionFromJSON(
  json: any
): VendorConnectionUpdatePayloadVendorConnection {
  return VendorConnectionUpdatePayloadVendorConnectionFromJSONTyped(
    json,
    false
  );
}

export function VendorConnectionUpdatePayloadVendorConnectionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorConnectionUpdatePayloadVendorConnection {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    permissions: !exists(json, 'permissions')
      ? undefined
      : VendorConnectionPermissionsFromJSON(json['permissions']),
  };
}

export function VendorConnectionUpdatePayloadVendorConnectionToJSON(
  value?: VendorConnectionUpdatePayloadVendorConnection | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    owner_id: value.ownerId,
    permissions: VendorConnectionPermissionsToJSON(value.permissions),
  };
}
