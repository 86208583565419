/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface SupplyContractorUpdatePayloadSupplyContractor
 */
export interface SupplyContractorUpdatePayloadSupplyContractor {
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  tradeName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  countryOfIncorporation?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  registeredAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  headOfficeAddress?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  businessSummary?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  businessType?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  companyIdentifier?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  dateOfIncorporation?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  legalEntityIdentifier?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  currencyCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  financialEntityType?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  competentAuthority?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  parentCompanyName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorUpdatePayloadSupplyContractor
   */
  parentCompanyLegalEntityIdentifier?: string | null;
}

export function SupplyContractorUpdatePayloadSupplyContractorFromJSON(
  json: any
): SupplyContractorUpdatePayloadSupplyContractor {
  return SupplyContractorUpdatePayloadSupplyContractorFromJSONTyped(
    json,
    false
  );
}

export function SupplyContractorUpdatePayloadSupplyContractorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractorUpdatePayloadSupplyContractor {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    tradeName: !exists(json, 'trade_name') ? undefined : json['trade_name'],
    countryOfIncorporation: !exists(json, 'country_of_incorporation')
      ? undefined
      : json['country_of_incorporation'],
    registeredAddress: !exists(json, 'registered_address')
      ? undefined
      : json['registered_address'],
    headOfficeAddress: !exists(json, 'head_office_address')
      ? undefined
      : json['head_office_address'],
    businessSummary: !exists(json, 'business_summary')
      ? undefined
      : json['business_summary'],
    businessType: !exists(json, 'business_type')
      ? undefined
      : json['business_type'],
    companyIdentifier: !exists(json, 'company_identifier')
      ? undefined
      : json['company_identifier'],
    dateOfIncorporation: !exists(json, 'date_of_incorporation')
      ? undefined
      : json['date_of_incorporation'],
    legalEntityIdentifier: !exists(json, 'legal_entity_identifier')
      ? undefined
      : json['legal_entity_identifier'],
    currencyCode: !exists(json, 'currency_code')
      ? undefined
      : json['currency_code'],
    financialEntityType: !exists(json, 'financial_entity_type')
      ? undefined
      : json['financial_entity_type'],
    competentAuthority: !exists(json, 'competent_authority')
      ? undefined
      : json['competent_authority'],
    parentCompanyName: !exists(json, 'parent_company_name')
      ? undefined
      : json['parent_company_name'],
    parentCompanyLegalEntityIdentifier: !exists(
      json,
      'parent_company_legal_entity_identifier'
    )
      ? undefined
      : json['parent_company_legal_entity_identifier'],
  };
}

export function SupplyContractorUpdatePayloadSupplyContractorToJSON(
  value?: SupplyContractorUpdatePayloadSupplyContractor | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    trade_name: value.tradeName,
    country_of_incorporation: value.countryOfIncorporation,
    registered_address: value.registeredAddress,
    head_office_address: value.headOfficeAddress,
    business_summary: value.businessSummary,
    business_type: value.businessType,
    company_identifier: value.companyIdentifier,
    date_of_incorporation: value.dateOfIncorporation,
    legal_entity_identifier: value.legalEntityIdentifier,
    currency_code: value.currencyCode,
    financial_entity_type: value.financialEntityType,
    competent_authority: value.competentAuthority,
    parent_company_name: value.parentCompanyName,
    parent_company_legal_entity_identifier:
      value.parentCompanyLegalEntityIdentifier,
  };
}
