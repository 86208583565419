import React from 'react';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import './AddSupplierSearchScreen.scss';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAdoptechDebounce } from '../../../hooks/useAdoptechDebounce';
import { fetchSearchedSuppliers } from '../store/supplierThunks';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import AdoptechGridTable, {
  adoptechGridTableCss,
} from '../../../components/AdoptechGridTable/AdoptechGridTable';
import classNames from 'classnames';
import { AdoptechTooltip } from '../../../components/AdoptechTooltip/AdoptechTooltip';
import { Interweave } from 'interweave';
import { AddSupplierForm } from './AddSupplierModal';
import { SupplyContractorSearchItem } from '../../../swagger';

export interface SearchScreenProps {
  form: AddSupplierForm;
  onHide: () => void;
  handleChange: (changes: { [key: string]: any }) => void;
}

export const AddSupplierSearchScreen: React.FC<SearchScreenProps> = ({
  form,
  onHide,
  handleChange,
}) => {
  const modalTitle = 'Search the supplier register';
  const description =
    'Let’s first check to see if the company is already registered with Adoptech.';
  const baseCss = 'addSupplierSearchScreen';
  const dispatch = useAppDispatch();
  const debounce = useAdoptechDebounce();
  const [selectedId, setSelectedId] = React.useState<string>(null);
  const [searchName, setSearchName] = React.useState<string>('');
  const [searchSuppliers, setSearchSuppliers] = React.useState<
    SupplyContractorSearchItem[]
  >([]);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedId(null);

    const name = e.currentTarget.value;
    setSearchName(name);
    if (!searchName || searchName.length < 1) {
      setSearchSuppliers([]);
      return;
    }
    debounce(async () => {
      const result = await dispatch(
        fetchSearchedSuppliers({
          name: searchName,
        })
      ).unwrap();
      setSearchSuppliers(result);
    });
  };

  const { fetchSearchedSuppliersStatus: isLoading } = useSelector(
    (state: ApplicationState) => state.supplier
  );

  const searchNameEmpty = searchName.length === 0;
  const showManualFlow = !searchNameEmpty && searchSuppliers.length === 0;
  const showSearchItems = !searchNameEmpty && searchSuppliers.length > 0;
  const searchKeyword =
    searchName.length > 15
      ? `<b>"${searchName.substring(0, 15)}..."</b>`
      : `<b>"${searchName}"</b>`;

  const isManaged = (supplier: SupplyContractorSearchItem): boolean => {
    return supplier.type === 'SupplyContractor::VendorManaged';
  };

  const isDisabled = (supplier: SupplyContractorSearchItem): boolean => {
    return !supplier.availableForConnection;
  };
  return (
    <AdoptechModal className={baseCss} onHide={onHide} rounded show>
      <Modal.Header>
        <div>{modalTitle}</div>
      </Modal.Header>
      <Modal.Body>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <div className={baseCss + '--body'}>
            <div className={baseCss + '--description'}>{description}</div>
            <div className={baseCss + '--fieldRow'}>
              <div className={baseCss + '--field'}>
                <AdoptechTextInput
                  id="searchName"
                  value={searchName}
                  onChange={handleInputChange}
                  type="text"
                  rounded
                  placeholder="Search the register"
                  icon={faSearch}
                  additionalClass="adoptechTextInput-search"
                  size="large"
                />
              </div>
            </div>
            {showManualFlow && (
              <div className={baseCss + '--fieldRow'}>
                <div className={baseCss + '--noResultsTitle'}>Results</div>
                <div className={baseCss + '--noResults'}>
                  <Interweave
                    content={`We haven't found a vendor with this name ${searchKeyword}
                    on the register.`}
                  />
                </div>
              </div>
            )}

            {showSearchItems && (
              <div className={baseCss + '--fieldRow'}>
                <div className={`${adoptechGridTableCss} noPaddingTheme`}>
                  <AdoptechGridTable
                    header={
                      <div className={`${adoptechGridTableCss}--header`}>
                        <div>{`Results (${searchSuppliers.length})`}</div>
                        <div>Connected</div>
                      </div>
                    }
                  >
                    {searchSuppliers.map(supplier => (
                      <div
                        key={supplier.id}
                        className={classNames(`${adoptechGridTableCss}--row`, {
                          selected: selectedId === supplier.id,
                          disabled: isDisabled(supplier),
                        })}
                        onClick={() => {
                          if (isDisabled(supplier)) {
                            return;
                          }
                          const selfClick = selectedId === supplier.id;
                          if (selfClick) {
                            setSelectedId(null);
                            return;
                          }
                          setSelectedId(supplier.id);
                        }}
                      >
                        <div>{supplier.name}</div>
                        <div>
                          {!isManaged(supplier) ? (
                            <AdoptechTooltip
                              identifier={supplier.id + 'supplierSearch'}
                              text="Supplier is directly connected to Adoptech"
                              showTooltip
                            >
                              <div className={baseCss + '--badge'}>Yes</div>
                            </AdoptechTooltip>
                          ) : (
                            <AdoptechTooltip
                              identifier={supplier.id + 'supplierSearch'}
                              text="Supplier details are managed by Adoptech"
                              showTooltip
                            >
                              <div>Adoptech Mgd</div>
                            </AdoptechTooltip>
                          )}{' '}
                        </div>
                      </div>
                    ))}
                  </AdoptechGridTable>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onHide}>Cancel</AdoptechButton>
        <AdoptechButton
          disabled={searchNameEmpty}
          busy={false}
          onClick={() => {
            if (showManualFlow) {
              handleChange({ currentScreen: 'manual-choose' });
            } else {
              handleChange({
                currentScreen: 'connection-form',
                selectedSearchSupplier: {
                  vendorId: searchSuppliers.find(s => s.id === selectedId)
                    ?.vendorId,
                  name: searchSuppliers.find(s => s.id === selectedId)?.name,
                },
              });
            }
          }}
          variant={AdoptechButtonVariant.PrimaryOrange}
          icon={faPlus}
          iconSize="large"
          rounded
        >
          {showManualFlow ? (
            <Interweave content={`Add new supplier ${searchKeyword}`} />
          ) : (
            'Add Supplier'
          )}
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
