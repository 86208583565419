import { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { canIndex } from '../../../functions/access';
import { selectIdentifierTypesOptions } from '../../../selectors/selectIdenifierTypesOptions';
import {
  fetchIdentifierTypes,
  fetchCompanyIdentifiers,
  patchCompanyProfile,
  simpleDeleteCompanyIdentifier,
  createCompanyIdentifier,
} from '../../../store/vendors/vendorsThunks';
import { Company, CompanyExtended } from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { ApplicationState } from '../../../types/applicationState';

export const useCompanyInformation = (company: CompanyExtended) => {
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [updatedCompany, setUpdatedCompany] =
    useState<CompanyExtended>(company);
  const [updatedIdentifier, setUpdatedIdentifier] = useState<
    string | undefined
  >();
  const [updatedIdentifierType, setUpdatedIdentifierType] = useState<
    string | undefined
  >();

  const companyIdentifiers = useSelector(
    (state: ApplicationState) => state.vendors.companyIdentifiers
  );
  const identifierTypesOptions = useSelector(selectIdentifierTypesOptions);
  const {
    updateCompanyProfileRequest,
    companyDetailsState: { isCreatingCompanyIdentifier },
  } = useSelector((state: ApplicationState) => state.vendors);

  const dispatch = useDispatch();
  const canIndexCompanyIdentifiers = canIndex(AccessObject.company_identifiers);

  const companyDetails = company ? companyIdentifiers?.[company.id]?.[0] : null;

  const companyIdentifier = companyDetails?.identifier || '';
  const companyIdentifierType = companyDetails?.type || '';
  const companyIdentifierDisplay = companyDetails
    ? `${companyDetails.displayName}: ${companyIdentifier}`
    : '';

  useEffect(() => {
    if (!company) return;

    if (!identifierTypesOptions) {
      dispatch(fetchIdentifierTypes());
    }

    if (canIndexCompanyIdentifiers) {
      dispatch(fetchCompanyIdentifiers(company.id));
    }
  }, [identifierTypesOptions, canIndexCompanyIdentifiers, company?.id]);

  const getChangedFields = () => {
    return Object.keys(updatedCompany).reduce(
      (acc: Partial<CompanyExtended>, key: keyof Company) => {
        if (updatedCompany[key] !== company[key]) {
          (acc as any)[key] = updatedCompany[key];
        }
        return acc;
      },
      {} as Partial<CompanyExtended>
    );
  };

  const handleSaveClick = () => {
    const changedFields = getChangedFields();
    const formHasChanges = Object.keys(changedFields).length > 0;

    if (formHasChanges) {
      dispatch(
        patchCompanyProfile({ company: { ...changedFields, id: company.id } })
      );
    }

    if (
      updatedIdentifier !== companyIdentifier ||
      updatedIdentifierType !== companyIdentifierType
    ) {
      if (companyIdentifiers[company.id]?.[0]) {
        dispatch(
          simpleDeleteCompanyIdentifier(companyIdentifiers[company.id][0].id)
        );
      }

      dispatch(
        createCompanyIdentifier(
          { identifier: updatedIdentifier, type: updatedIdentifierType },
          company.id,
          () => null
        )
      );
    }

    setIsInEditMode(false);
  };

  const handleEditClick = () => {
    setUpdatedCompany({ ...company });
    setUpdatedIdentifier(companyIdentifier);
    setUpdatedIdentifierType(companyIdentifierType);
    setIsInEditMode(true);
  };
  return {
    company,
    isInEditMode,
    setIsInEditMode,
    updatedCompany,
    setUpdatedCompany,
    updatedIdentifier,
    setUpdatedIdentifier,
    updatedIdentifierType,
    setUpdatedIdentifierType,
    identifierTypesOptions,
    handleSaveClick,
    handleEditClick,
    updateCompanyProfileRequest,
    isCreatingCompanyIdentifier,
    companyIdentifierDisplay,
  };
};
