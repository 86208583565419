/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BusinessFunctionCreatePayloadBusinessFunction,
  BusinessFunctionCreatePayloadBusinessFunctionFromJSON,
  BusinessFunctionCreatePayloadBusinessFunctionFromJSONTyped,
  BusinessFunctionCreatePayloadBusinessFunctionToJSON,
} from './';

/**
 *
 * @export
 * @interface BusinessFunctionCreatePayload
 */
export interface BusinessFunctionCreatePayload {
  /**
   *
   * @type {BusinessFunctionCreatePayloadBusinessFunction}
   * @memberof BusinessFunctionCreatePayload
   */
  businessFunction: BusinessFunctionCreatePayloadBusinessFunction;
}

export function BusinessFunctionCreatePayloadFromJSON(
  json: any
): BusinessFunctionCreatePayload {
  return BusinessFunctionCreatePayloadFromJSONTyped(json, false);
}

export function BusinessFunctionCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessFunctionCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    businessFunction: BusinessFunctionCreatePayloadBusinessFunctionFromJSON(
      json['business_function']
    ),
  };
}

export function BusinessFunctionCreatePayloadToJSON(
  value?: BusinessFunctionCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    business_function: BusinessFunctionCreatePayloadBusinessFunctionToJSON(
      value.businessFunction
    ),
  };
}
