export enum Grid {
  AllDocuments,
  ArchivedDocuments,
  AvailablePolicies,
  CompletedAssessments,
  DocumentAdmin,
  InProgressPolicies,
  LivePolicies,
  PendingApprovalPolicies,
  PendingAssessments,
  PendingDocuments,
  RecentlyCreatedAssessments,
  AttestationManagement,
  ApprovalManagement,
  ActiveChecklists,
  CompleteChecklists,
  DraftAgreements,
  AwaitingSignatureAgreements,
  CompletedAgreements,
  Users,
  EscrowAccounts,
  ComplianceTasks,
  FrameworkPolicies,
  DataRoom,
  DataRoomTree,
  VendorRegister,
  LegalRegister,
  PestelPolitical,
  PestelEconomicCommercial,
  PestelSocial,
  PestelEnvironmental,
  PestelLegal,
  PestelTechnological,
  Audits,
  Risks,
  RisksTemplates,
  RisksReports,
  Reports,
  Calendar,
  Controls,
  TrustHubUserAdminAccessRequests,
  TrustHubUserAdminCompanies,
  TrustHubUserAdminCompanyUsers,
  TrustHubUserAdminActivities,
  TrustHubUserAdminVendorUsers,
  TrustHubUserAdminVendorUserDocumentPermissions,
  TrustHubUserAdminVendorUserActivities,
  SupplierRegister,
  BusinessFunctions,
}
