import React from 'react';
import { AdoptechButton } from '../../../components/AdoptechButton/AdoptechButton';
import { Panel } from '../../../components/Panel/Panel';
import './RegulatoryInformation.scss';
import { faPen } from '@fortawesome/pro-light-svg-icons/faPen';
import { useRegulatoryInformation } from './useRegulatoryInformation';
import { components, GroupBase, OptionProps } from 'react-select';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { CompanyExtended } from '../../../swagger';
import { AccessObject } from '../../../types/accessObject';
import { useCanFeature } from '../../../functions/access';

interface RegulatoryInformationProps {
  company: CompanyExtended;
}

export const RegulatoryInformation: React.FC<
  RegulatoryInformationProps
> = props => {
  const canEdit = useCanFeature(AccessObject.company_manage);
  const formHandler = useRegulatoryInformation(props.company);
  const { isInEditMode, config, configItemName } = formHandler;

  if (isInEditMode) {
    return <EditRegulatoryInformation formHandler={formHandler} />;
  }
  const baseCss = 'regulatoryInformation';
  const contentsCss = `${baseCss}--contents`;

  return (
    <div className={baseCss}>
      <Panel>
        <div className={`${baseCss}--header`}>
          <div className={`${baseCss}--title`}>Regulatory Information</div>
          {canEdit && (
            <div className={`${baseCss}--button`}>
              <AdoptechButton
                busy={formHandler.updateCompanyProfileRequest}
                icon={faPen}
                onClick={formHandler.handleEditClick}
              >
                Edit
              </AdoptechButton>
            </div>
          )}
        </div>
        <div className={contentsCss}>
          <div className={`${contentsCss}--row ${contentsCss}--row-3-columns`}>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>
                Type of Financial Entity
              </div>
              <div className={`${contentsCss}--data`}>
                {props.company.financialEntityName}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Competent Authority</div>
              <div className={`${contentsCss}--data`}>
                {props.company.competentAuthorityName}
              </div>
            </div>
            <div className={`${contentsCss}--column`}>
              <div className={`${contentsCss}--label`}>Currency</div>
              <div className={`${contentsCss}--data`}>
                {props.company.currencyCode}
              </div>
            </div>
          </div>
        </div>
      </Panel>
    </div>
  );
};

interface EditRegulatoryInformationProps {
  formHandler: ReturnType<typeof useRegulatoryInformation>;
}

const EditRegulatoryInformation: React.FC<EditRegulatoryInformationProps> = ({
  formHandler,
}) => {
  const {
    company,
    updatedCompany,
    setUpdatedCompany,
    handleSaveClick,
    setIsInEditMode,
    config,
  } = formHandler;

  const baseCss = 'regulatoryInformation';
  const contentsCss = `${baseCss}--contents`;

  const handleFieldChange = (field: keyof CompanyExtended) => (option: any) => {
    setUpdatedCompany({
      ...updatedCompany,
      [field]: option.value,
    });
  };

  const handleFinancialEntityChange = handleFieldChange('financialEntityType');
  const handleCompetentAuthorityChange =
    handleFieldChange('competentAuthority');
  const handleCurrencyChange = handleFieldChange('currencyCode');

  return (
    <div className={baseCss}>
      <Panel>
        <div className={`${baseCss}--header`}>
          <div className={`${baseCss}--title`}>Edit Regulatory Information</div>
          <div className={`${baseCss}--button`}>
            <AdoptechButton
              onClick={() => {
                setUpdatedCompany({ ...company });
                setIsInEditMode(false);
              }}
            >
              Cancel
            </AdoptechButton>
            <AdoptechButton
              extraClass="regulatoryInformation--button-withLeftMargin"
              onClick={handleSaveClick}
            >
              Save
            </AdoptechButton>
          </div>
        </div>
        <div className={contentsCss}>
          <div className={`${contentsCss}--row ${contentsCss}--row-3-columns`}>
            <EditableConfigField
              label="Type of Financial Entity"
              options={config.financialEntityTypes}
              value={updatedCompany.financialEntityType}
              onChange={handleFinancialEntityChange}
            />
            <EditableConfigField
              label="Competent Authority"
              options={config.authorities}
              value={updatedCompany.competentAuthority}
              onChange={handleCompetentAuthorityChange}
            />
            <EditableConfigField
              label="Currency"
              options={config.currencies}
              value={updatedCompany.currencyCode}
              onChange={handleCurrencyChange}
            />
          </div>
        </div>
      </Panel>
    </div>
  );
};
interface EditableConfigFieldProps {
  label: string;
  options: {
    identifier: string;
    name: string;
    description?: string;
    guidance?: string;
  }[];
  value: string;
  onChange: (option: any) => void;
}
interface OptionData {
  label: string;
  description: string;
}
export const AdoptechSelectDescriptionOption: React.FC<
  OptionProps<OptionData, false, GroupBase<OptionData>>
> = props => {
  return (
    <components.Option {...props}>
      <div>
        <div>{props.data.label}</div>
        <div style={{ fontSize: '0.8em', color: '#666' }}>
          {props.data.description}
        </div>
      </div>
    </components.Option>
  );
};

const EditableConfigField: React.FC<EditableConfigFieldProps> = ({
  label,
  options,
  value,
  onChange,
}) => {
  const selectOptions = options.map(option => ({
    value: option.identifier,
    label: option.name || option.identifier,
    description: option.guidance || option.description,
  }));

  const baseCss = 'regulatoryInformation';
  const contentCss = `${baseCss}--contents`;

  return (
    <div className={`${contentCss}--column`}>
      <div className={`${contentCss}--label`}>{label}</div>
      <div className={`${contentCss}--data`}>
        <AdoptechReactSelect
          id={label.replace(/\s+/g, '').toLowerCase()}
          options={selectOptions}
          onChange={onChange}
          value={selectOptions.find(option => option.value === value)}
          placeholder="Start typing to search"
          components={{ Option: AdoptechSelectDescriptionOption }}
        />
      </div>
    </div>
  );
};
