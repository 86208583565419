/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  BusinessFunctionUpdatePayloadBusinessFunction,
  BusinessFunctionUpdatePayloadBusinessFunctionFromJSON,
  BusinessFunctionUpdatePayloadBusinessFunctionFromJSONTyped,
  BusinessFunctionUpdatePayloadBusinessFunctionToJSON,
} from './';

/**
 *
 * @export
 * @interface BusinessFunctionUpdatePayload
 */
export interface BusinessFunctionUpdatePayload {
  /**
   *
   * @type {BusinessFunctionUpdatePayloadBusinessFunction}
   * @memberof BusinessFunctionUpdatePayload
   */
  businessFunction: BusinessFunctionUpdatePayloadBusinessFunction;
}

export function BusinessFunctionUpdatePayloadFromJSON(
  json: any
): BusinessFunctionUpdatePayload {
  return BusinessFunctionUpdatePayloadFromJSONTyped(json, false);
}

export function BusinessFunctionUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessFunctionUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    businessFunction: BusinessFunctionUpdatePayloadBusinessFunctionFromJSON(
      json['business_function']
    ),
  };
}

export function BusinessFunctionUpdatePayloadToJSON(
  value?: BusinessFunctionUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    business_function: BusinessFunctionUpdatePayloadBusinessFunctionToJSON(
      value.businessFunction
    ),
  };
}
