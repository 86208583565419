/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VendorSupplyContractorStatus {
  DueDiligence = 'due_diligence',
  Pending = 'pending',
  Approved = 'approved',
  Offboarding = 'offboarding',
  Closed = 'closed',
}

export function VendorSupplyContractorStatusFromJSON(
  json: any
): VendorSupplyContractorStatus {
  return VendorSupplyContractorStatusFromJSONTyped(json, false);
}

export function VendorSupplyContractorStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplyContractorStatus {
  return json as VendorSupplyContractorStatus;
}

export function VendorSupplyContractorStatusToJSON(
  value?: VendorSupplyContractorStatus | null
): any {
  return value as any;
}
