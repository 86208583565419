/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SupplyContractItemPortability {
  EasilySubstitutable = 'easily_substitutable',
  MediumComplexitySwitch = 'medium_complexity_switch',
  HighlyComplexSwitch = 'highly_complex_switch',
  NotSubstitutable = 'not_substitutable',
}

export function SupplyContractItemPortabilityFromJSON(
  json: any
): SupplyContractItemPortability {
  return SupplyContractItemPortabilityFromJSONTyped(json, false);
}

export function SupplyContractItemPortabilityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractItemPortability {
  return json as SupplyContractItemPortability;
}

export function SupplyContractItemPortabilityToJSON(
  value?: SupplyContractItemPortability | null
): any {
  return value as any;
}
