import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { useAdoptechSearch } from '../../../hooks/useAdoptechSearch';
import { fetchContracts } from '../store/supplierThunks';
import { setPageHeaderLabel } from '../../../store/global/globalSlice';
import { setPageTitle } from '../../../components/PageHeader/PageHeader';
import AdoptechFlexTable from '../../../components/AdoptechFlexTable/AdoptechFlexTable';
import { adoptechGridTableCss } from '../../../components/AdoptechGridTable/AdoptechGridTable';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import { StatusBadge } from '../SuppliersPage/StatusBadge/StatusBadge';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { SupplyContractModel } from '../../../swagger/models/SupplyContractModel';
import { EditContractDrawer } from '../EditContractDrawer/EditContractDrawer';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import './SupplierProfileContractsPage.scss';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';
import { formatShortDate } from '../../../functions/formatShortDate';

export const SupplierProfileContractsPage: React.FC<{
  vendorSupplyContractorId: string;
}> = props => {
  const dispatch = useDispatch();
  const { contracts: collection } = useSelector(
    (state: ApplicationState) => state.supplier
  );

  const {
    search,
    setSearch,
    results: contracts,
  } = useAdoptechSearch({
    keys: ['name'],
    collection,
  });

  const [showEditContractDrawer, setShowEditContractDrawer] =
    React.useState(false);
  const [currentContractId, setCurrentContractId] =
    React.useState<SupplyContractModel['id']>(null);

  useEffect(() => {
    setPageTitle('Contracts');
    dispatch(setPageHeaderLabel('Supplier Management'));
    dispatch(fetchContracts());
  }, []);

  const baseCss = 'contractsPage';

  return (
    <div className={baseCss}>
      {showEditContractDrawer && (
        <EditContractDrawer
          contractId={currentContractId}
          vendorSupplyContractorId={props.vendorSupplyContractorId}
          onClose={() => setShowEditContractDrawer(false)}
        />
      )}
      <AdoptechFlexTable
        draggable={false}
        tableHeaders={
          <div className={adoptechGridTableCss + '--header'}>
            <div>Contract ID</div>
            <div>Status</div>
            <div>Name</div>
            <div>Owner</div>
            <div>Type</div>
            <div>End Date</div>
          </div>
        }
        tableRows={contracts.map(contract => (
          <div
            key={contract.id}
            onClick={() => {
              setCurrentContractId(contract.id);
              setShowEditContractDrawer(true);
            }}
            className={`${adoptechGridTableCss}--row ${baseCss}--tableRow`}
          >
            <>
              <div>
                <AdoptechOverflowLine>
                  {contract.contractId}
                </AdoptechOverflowLine>
              </div>
              <StatusBadge status={contract.status} />
              <AdoptechOverflowLine>{contract.name}</AdoptechOverflowLine>
              <AdoptechOverflowLine>
                <div>
                  <UserAvatar user={contract.owner} size="small" />
                </div>
              </AdoptechOverflowLine>
              <div>{capitaliseFirst(contract.contractType)}</div>
              <div>{formatShortDate(contract.endDate)}</div>
            </>
          </div>
        ))}
        extraClass=""
        collection={contracts}
        header={
          <ContractsPageHeader
            contracts={contracts}
            search={search}
            setSearch={setSearch}
            vendorSupplyContractorId={props.vendorSupplyContractorId}
            setShowEditContractDrawer={setShowEditContractDrawer}
            setCurrentContractId={setCurrentContractId}
          />
        }
        noDataText="No contracts to show"
      />
    </div>
  );
};

const ContractsPageHeader: React.FC<{
  contracts: SupplyContractModel[];
  search: string;
  setSearch: (val: string) => void;
  vendorSupplyContractorId: string;
  setShowEditContractDrawer: (val: boolean) => void;
  setCurrentContractId: (val: SupplyContractModel['id']) => void;
}> = ({
  contracts,
  search,
  setSearch,
  vendorSupplyContractorId,
  setShowEditContractDrawer,
  setCurrentContractId,
}) => {
  const baseCss = 'suppliersPage';
  // TODO: implement it on the backend
  const canCreateContracts = true;

  return (
    <div className={baseCss + '--tableHeader'}>
      <div>
        <div className={baseCss + '--counters'}>
          Contracts
          <Lozenge value={contracts.length} />
        </div>
      </div>
      <div className={baseCss + '--filterButtons'}>
        <div className={baseCss + '--search'}>
          <AdoptechTextInput
            id="search"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
            type="text"
            placeholder="Search"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
      </div>
      <div className={baseCss + '--actionsButtons'}>
        {canCreateContracts && (
          <>
            <AdoptechButton
              variant={AdoptechButtonVariant.PrimaryOrange}
              icon={faPlus}
              iconSize="large"
              onClick={() => {
                setCurrentContractId(null);
                setShowEditContractDrawer(true);
              }}
              rounded
            >
              Add Contract
            </AdoptechButton>
          </>
        )}
      </div>
    </div>
  );
};
