/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorConnectionPermissions
 */
export interface VendorConnectionPermissions {
  /**
   *
   * @type {boolean}
   * @memberof VendorConnectionPermissions
   */
  supplierRegister?: boolean;
}

export function VendorConnectionPermissionsFromJSON(
  json: any
): VendorConnectionPermissions {
  return VendorConnectionPermissionsFromJSONTyped(json, false);
}

export function VendorConnectionPermissionsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorConnectionPermissions {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    supplierRegister: !exists(json, 'supplier_register')
      ? undefined
      : json['supplier_register'],
  };
}

export function VendorConnectionPermissionsToJSON(
  value?: VendorConnectionPermissions | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    supplier_register: value.supplierRegister,
  };
}
