/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SupplyContractorModel,
  SupplyContractorModelFromJSON,
  SupplyContractorModelFromJSONTyped,
  SupplyContractorModelToJSON,
  VendorSupplyContractorStatus,
  VendorSupplyContractorStatusFromJSON,
  VendorSupplyContractorStatusFromJSONTyped,
  VendorSupplyContractorStatusToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorSupplyContractorExtended
 */
export interface VendorSupplyContractorExtended {
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorExtended
   */
  id: string;
  /**
   *
   * @type {VendorSupplyContractorStatus}
   * @memberof VendorSupplyContractorExtended
   */
  status: VendorSupplyContractorStatus;
  /**
   *
   * @type {VendorUser}
   * @memberof VendorSupplyContractorExtended
   */
  owner: VendorUser;
  /**
   *
   * @type {SupplyContractorModel}
   * @memberof VendorSupplyContractorExtended
   */
  supplyContractor: SupplyContractorModel;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorExtended
   */
  additionalNotes?: string | null;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorExtended
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorExtended
   */
  updatedAt: string;
}

export function VendorSupplyContractorExtendedFromJSON(
  json: any
): VendorSupplyContractorExtended {
  return VendorSupplyContractorExtendedFromJSONTyped(json, false);
}

export function VendorSupplyContractorExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplyContractorExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    status: VendorSupplyContractorStatusFromJSON(json['status']),
    owner: VendorUserFromJSON(json['owner']),
    supplyContractor: SupplyContractorModelFromJSON(json['supply_contractor']),
    additionalNotes: !exists(json, 'additional_notes')
      ? undefined
      : json['additional_notes'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function VendorSupplyContractorExtendedToJSON(
  value?: VendorSupplyContractorExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    status: VendorSupplyContractorStatusToJSON(value.status),
    owner: VendorUserToJSON(value.owner),
    supply_contractor: SupplyContractorModelToJSON(value.supplyContractor),
    additional_notes: value.additionalNotes,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
