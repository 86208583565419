import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import zxcvbn from 'zxcvbn';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../src/components/AdoptechButton/AdoptechButton';
import { isValidEmailAddress } from '../../../../src/functions/isValidEmailAddress';
import { sendAnalytics } from '../../../../src/functions/sendAnalytics';
import { clearSignUpError } from '../../store/authentication/authenticationSlice';
import { signUp } from '../../store/authentication/authenticationThunks';
import { ApplicationState } from '../../types/applicationState';
import DividerWithText from '../DividerWithText/DividerWithText';
import { InputField } from '../InputField/InputField';
import { PasswordStrength } from '../PasswordStrength/PasswordStrength';
import './SignUp.scss';
import { AdoptechCheckbox } from '../../../../src/components/AdoptechCheckbox/AdoptechCheckbox';
import {
  externalProvidersRoutes,
  portalRoutes,
} from '../../../../src/components/Routes/Routes';
import { formSubmit } from '../../../../src/functions/formSubmit';
import { useConnectionId } from './useConnection';

const SignUp: React.FC = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);

  let signUpError = useSelector(
    (state: ApplicationState) => state.authentication.signUpError
  );

  const isSigningUp = useSelector(
    (state: ApplicationState) => state.authentication.isSigningUp
  );

  const signupRedirectCallback = (path: string) => () => {
    formSubmit('post', path, {
      prompt: 'select_account',
    });
  };

  let mismatchedPasswords = false;
  const showEmailInvalidError = email !== '' && !isValidEmailAddress(email);
  const showPasswordMismatchError =
    repeatPassword !== '' && password != '' && repeatPassword !== password;
  if (showPasswordMismatchError) {
    mismatchedPasswords = true;
    signUpError = signUpError
      ? signUpError + ' The passwords do not match.'
      : 'The passwords do not match.';
  }
  const passwordStrength = zxcvbn(password);
  const passwordInvalid =
    password === '' ||
    repeatPassword === '' ||
    passwordStrength.score < 2 ||
    mismatchedPasswords;

  const showPasswordInvalidError =
    (repeatPassword !== '' && password !== '' && passwordStrength.score < 2) ||
    mismatchedPasswords;

  const signUpButtonDisabled =
    passwordInvalid || !isValidEmailAddress(email) || !termsChecked;

  // Show terms error only after "disabled button wrapper div click" to make it unobtrusive ( display less validation errors )
  const formValidExceptTerms =
    !passwordInvalid && isValidEmailAddress(email) && !termsChecked;
  const showTermsCheckedError = termsChecked === null;

  const baseCss = 'signUp';

  const emailFieldClasses = classNames({
    [`${baseCss}--emailField`]: true,
    [`${baseCss}--emailField-red`]: showEmailInvalidError,
  });

  const passwordFieldClasses = classNames({
    [`${baseCss}--passwordField`]: true,
    [`${baseCss}--passwordField-red`]: showPasswordInvalidError,
  });

  const { setConnectionId } = useConnectionId();
  const handleSubmit = useCallback(async () => {
    dispatch(clearSignUpError());
    if (!executeRecaptcha) {
      alert('ReCaptcha not available');
      return;
    }

    const recaptchaResult = await executeRecaptcha('signup');
    sendAnalytics('sign_up');
    setConnectionId();
    dispatch(signUp(email, password, repeatPassword, recaptchaResult));
  }, [email, executeRecaptcha, password, repeatPassword]);

  const dispatch = useDispatch();
  return (
    <div className={baseCss}>
      <div className={`${baseCss}--panel`}>
        <div className={`${baseCss}--content`}>
          <div className={`${baseCss}--header`}>Get started</div>
          <div className="login--fullSizeButton">
            <AdoptechButton
              busy={false}
              variant={AdoptechButtonVariant.Google}
              onClick={signupRedirectCallback(externalProvidersRoutes.google)}
            >
              Sign up with Google
            </AdoptechButton>
          </div>
          <div className="login--fullSizeButton">
            <AdoptechButton
              busy={false}
              variant={AdoptechButtonVariant.Microsoft}
              onClick={signupRedirectCallback(
                externalProvidersRoutes.microsoft
              )}
            >
              Sign up with Microsoft
            </AdoptechButton>
          </div>
          <DividerWithText text="or" />
          <div className={emailFieldClasses} tabIndex={0}>
            <InputField
              autoFocus
              disableAutoComplete
              onChange={value => {
                setEmail(value);
              }}
              placeholder="Enter your email address"
              type="email"
            />
          </div>
          <div>
            <div className={passwordFieldClasses}>
              <InputField
                disableAutoComplete
                isPassword
                placeholder="Choose a password"
                onChange={value => {
                  setPassword(value);
                }}
                type="password"
              />
            </div>
            <div className={passwordFieldClasses}>
              <InputField
                disableAutoComplete
                isPassword
                placeholder="Repeat password"
                onChange={value => {
                  setRepeatPassword(value);
                }}
                type="password"
              />
            </div>
            <PasswordStrength password={password} result={passwordStrength} />
            {signUpError && (
              <div className={baseCss + '--passwordOrApiError'}>
                {signUpError}
              </div>
            )}
          </div>
          <div
            className={classNames(
              baseCss + '--terms',

              { [`${baseCss}--terms-red`]: showTermsCheckedError }
            )}
          >
            <AdoptechCheckbox
              id="terms-and-privacy"
              checked={termsChecked}
              onChange={e => {
                setTermsChecked(e.currentTarget.checked);
              }}
              label={
                <div>
                  Tick to agree to the{' '}
                  <a
                    href={portalRoutes.privacy}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>{' '}
                  and{' '}
                  <a href={portalRoutes.terms} target="_blank" rel="noreferrer">
                    Terms & Conditions
                  </a>
                </div>
              }
            />
          </div>
          {showTermsCheckedError && (
            <div className={baseCss + '--termsError'}>
              You must agree to the Privacy Policy and Terms & Conditions
            </div>
          )}
          <div
            className={`${baseCss}--fullSizeButton ${baseCss}`}
            onClick={() => {
              if (formValidExceptTerms) {
                setTermsChecked(null);
              }
            }}
          >
            <AdoptechButton
              onClick={() => {
                handleSubmit();
              }}
              uppercase
              disabled={signUpButtonDisabled}
              busy={isSigningUp}
              variant={AdoptechButtonVariant.Primary}
            >
              <span>Sign up with email</span>
            </AdoptechButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
