import { useState, useRef, useCallback, useEffect } from 'react';

interface DragState {
  isDragging: boolean;
  startX: number;
  scrollLeft: number;
}
// Note - to make it works you need to add overflow: hidden to the ref;
const useDragToScroll = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [dragState, setDragState] = useState<DragState>({
    isDragging: false,
    startX: 0,
    scrollLeft: 0,
  });

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    if (ref.current) {
      setDragState({
        isDragging: true,
        startX: e.pageX - ref.current.offsetLeft,
        scrollLeft: ref.current.scrollLeft,
      });
      ref.current.classList.add('dragging');
    }
  }, []);
  const SCROLL_SPEED = 2;
  const handleMouseMove = useCallback(
    e => {
      if (dragState.isDragging && ref.current) {
        // e.pageX - distance from the left of the document to the mouse
        // ref.current.offsetLeft - distance from the left of the document to the element
        // x - distance from the left of the element to the mouse
        const x = e.pageX - ref.current.offsetLeft;
        const walk = (x - dragState.startX) * SCROLL_SPEED;
        ref.current.scrollLeft = dragState.scrollLeft - walk;
      }
    },
    [dragState]
  );

  const stopDragging = useCallback(() => {
    if (ref.current) {
      setDragState(prevState => ({ ...prevState, isDragging: false }));

      ref.current.classList.remove('dragging');
    }
  }, []);

  useEffect(() => {
    const handleEvents = (action: 'add' | 'remove') => {
      window[`${action}EventListener`]('mousemove', handleMouseMove);
      window[`${action}EventListener`]('mouseup', stopDragging);
      window[`${action}EventListener`]('mouseleave', stopDragging);
    };

    handleEvents(dragState.isDragging ? 'add' : 'remove');

    return () => handleEvents('remove');
  }, [dragState.isDragging, handleMouseMove, stopDragging]);

  return {
    ref,
    handleMouseDown,
    handleMouseLeave: stopDragging,
    handleMouseUp: stopDragging,
    handleMouseMove,
  };
};

export default useDragToScroll;
