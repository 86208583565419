import { createSelector } from '@reduxjs/toolkit';
import { sort } from '../../../functions/sort';
import { sortByOwner } from '../../../functions/sortByOwner';
import { VendorConnectionModel } from '../../../swagger/models/VendorConnectionModel';
import { VendorUser } from '../../../swagger/models/VendorUser';
import { ApplicationState } from '../../../types/applicationState';
import { ColumnSort } from '../../../types/columnSort';
import { Grid } from '../../../types/grid';
import { selectSortSettings } from '../../../selectors/selectSortSettings';

const sortClients = (
  a: VendorConnectionModel,
  b: VendorConnectionModel,
  columnSort: ColumnSort
) => {
  const typedColumn = columnSort.name as keyof VendorConnectionModel;

  if (['owner'].includes(typedColumn)) {
    return sortByOwner(
      a[typedColumn] as VendorUser,
      b[typedColumn] as VendorUser,
      columnSort.direction
    );
  }

  return sort(a[typedColumn] || '', b[typedColumn] || '', columnSort.direction);
};

export const selectClients = createSelector(
  (state: ApplicationState) => state.clientRegister.clients,
  selectSortSettings(Grid.SupplierRegister),
  (clients: VendorConnectionModel[], sortSetting) => {
    return [...clients].sort((a, b) =>
      sortClients(a, b, sortSetting.columnSort)
    );
  }
);
