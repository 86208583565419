/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SupplyContractItemStatus {
  PendingReview = 'pending_review',
  ReviewInProgress = 'review_in_progress',
  Approved = 'approved',
  Rejected = 'rejected',
}

export function SupplyContractItemStatusFromJSON(
  json: any
): SupplyContractItemStatus {
  return SupplyContractItemStatusFromJSONTyped(json, false);
}

export function SupplyContractItemStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractItemStatus {
  return json as SupplyContractItemStatus;
}

export function SupplyContractItemStatusToJSON(
  value?: SupplyContractItemStatus | null
): any {
  return value as any;
}
