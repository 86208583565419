/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SupplyContractContractType,
  SupplyContractContractTypeFromJSON,
  SupplyContractContractTypeFromJSONTyped,
  SupplyContractContractTypeToJSON,
  SupplyContractDocumentLink,
  SupplyContractDocumentLinkFromJSON,
  SupplyContractDocumentLinkFromJSONTyped,
  SupplyContractDocumentLinkToJSON,
  SupplyContractStatus,
  SupplyContractStatusFromJSON,
  SupplyContractStatusFromJSONTyped,
  SupplyContractStatusToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface SupplyContractModel
 */
export interface SupplyContractModel {
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  description: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  contractId: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  masterContractId: string | null;
  /**
   *
   * @type {SupplyContractContractType}
   * @memberof SupplyContractModel
   */
  contractType: SupplyContractContractType;
  /**
   *
   * @type {SupplyContractStatus}
   * @memberof SupplyContractModel
   */
  status: SupplyContractStatus;
  /**
   *
   * @type {VendorUser}
   * @memberof SupplyContractModel
   */
  owner?: VendorUser;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  signatory: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  signedOnBehalfOf: string | null;
  /**
   *
   * @type {Array<SupplyContractDocumentLink>}
   * @memberof SupplyContractModel
   */
  documentLinks: Array<SupplyContractDocumentLink>;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  governingLaw: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  startDate: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  endDate: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  location: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  terminationReason: SupplyContractModelTerminationReasonEnum;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  vendorSupplyContractorId: string | null;
  /**
   *
   * @type {boolean}
   * @memberof SupplyContractModel
   */
  intragroup: boolean | null;
  /**
   *
   * @type {number}
   * @memberof SupplyContractModel
   */
  supplierTerminationNoticeDaysAmount: number | null;
  /**
   *
   * @type {number}
   * @memberof SupplyContractModel
   */
  customerTerminationNoticeDaysAmount: number | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractModel
   */
  updatedAt: string;
}

export function SupplyContractModelFromJSON(json: any): SupplyContractModel {
  return SupplyContractModelFromJSONTyped(json, false);
}

export function SupplyContractModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    description: json['description'],
    contractId: json['contract_id'],
    masterContractId: json['master_contract_id'],
    contractType: SupplyContractContractTypeFromJSON(json['contract_type']),
    status: SupplyContractStatusFromJSON(json['status']),
    owner: !exists(json, 'owner')
      ? undefined
      : VendorUserFromJSON(json['owner']),
    signatory: json['signatory'],
    signedOnBehalfOf: json['signed_on_behalf_of'],
    documentLinks: (json['document_links'] as Array<any>).map(
      SupplyContractDocumentLinkFromJSON
    ),
    governingLaw: json['governing_law'],
    startDate: json['start_date'],
    endDate: json['end_date'],
    location: json['location'],
    terminationReason: json['termination_reason'],
    vendorSupplyContractorId: json['vendor_supply_contractor_id'],
    intragroup: json['intragroup'],
    supplierTerminationNoticeDaysAmount:
      json['supplier_termination_notice_days_amount'],
    customerTerminationNoticeDaysAmount:
      json['customer_termination_notice_days_amount'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function SupplyContractModelToJSON(
  value?: SupplyContractModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    description: value.description,
    contract_id: value.contractId,
    master_contract_id: value.masterContractId,
    contract_type: SupplyContractContractTypeToJSON(value.contractType),
    status: SupplyContractStatusToJSON(value.status),
    owner: VendorUserToJSON(value.owner),
    signatory: value.signatory,
    signed_on_behalf_of: value.signedOnBehalfOf,
    document_links: (value.documentLinks as Array<any>).map(
      SupplyContractDocumentLinkToJSON
    ),
    governing_law: value.governingLaw,
    start_date: value.startDate,
    end_date: value.endDate,
    location: value.location,
    termination_reason: value.terminationReason,
    vendor_supply_contractor_id: value.vendorSupplyContractorId,
    intragroup: value.intragroup,
    supplier_termination_notice_days_amount:
      value.supplierTerminationNoticeDaysAmount,
    customer_termination_notice_days_amount:
      value.customerTerminationNoticeDaysAmount,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}

/**
 * @export
 * @enum {string}
 */
export enum SupplyContractModelTerminationReasonEnum {
  Expired = 'expired',
  BreachOfApplicableLaw = 'breach_of_applicable_law',
  ImpedimentsIdentified = 'impediments_identified',
  ManagementOrSecurityWeakness = 'management_or_security_weakness',
  RequestedByCompetentAuthority = 'requested_by_competent_authority',
  Other = 'other',
}
