import { VoidThunk } from '../../../types/voidThunk';
import { showGlobalToast } from '../../../store/global/globalSlice';
import {
  getErrorMessage,
  handleThunkError,
} from '../../../store/helpers/thunkHelpers';
import {
  createCertificateFailure,
  createCertificateRequest,
  createCertificateSuccess,
  deleteCertificateFailure,
  deleteCertificateRequest,
  deleteCertificateSuccess,
  fetchCertificateDocumentFailure,
  fetchCertificateDocumentRequest,
  fetchCertificateDocumentSuccess,
  fetchCertificateFailure,
  fetchCertificateRequest,
  fetchCertificateSuccess,
  fetchCertificatesFailure,
  fetchCertificatesRequest,
  fetchCertificatesSuccess,
} from './companyProfileSlice';
import { api } from '../../../api/api';
import {
  ConfigsConfigIdGetRequest,
  VendorsVendorIdCertificatesPostRequest,
} from '../../../swagger/apis/DefaultApi';
import {
  BusinessFunctionCreatePayload,
  BusinessFunctionUpdatePayload,
  CertificateModel,
} from '../../../swagger';
import { getRequest } from '../../../functions/getRequest';
import { hideDeleteCertificateModal } from '../../../store/vendors/vendorsSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationState } from '../../../types/applicationState';

export const fetchCertificates =
  (): VoidThunk => async (dispatch, getState) => {
    try {
      const vendorId = getState().vendors.currentVendor.id;
      dispatch(fetchCertificatesRequest());
      const response = await api().vendorsVendorIdCertificatesGet({
        vendorId,
      });
      dispatch(fetchCertificatesSuccess(response));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching Certificates. Please try again or contact support.'
      );
      dispatch(fetchCertificatesFailure(apiErrorMessage));
    }
  };

export const deleteCertificate =
  (id: string): VoidThunk =>
  async dispatch => {
    try {
      dispatch(deleteCertificateRequest());
      await api().certificatesIdDelete({
        id,
      });
      dispatch(deleteCertificateSuccess(id));
      dispatch(hideDeleteCertificateModal());
      dispatch(showGlobalToast('Certificate has been successfully deleted'));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while deleting a Certificate. Please try again or contact support.'
      );
      dispatch(deleteCertificateFailure(apiErrorMessage));
    }
  };

export const createCertificate =
  (
    body: VendorsVendorIdCertificatesPostRequest,
    onSuccess: () => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(createCertificateRequest());
      const response = await api().vendorsVendorIdCertificatesPost(body);
      dispatch(createCertificateSuccess(response));
      onSuccess();
      dispatch(showGlobalToast('Certificate has been successfully added'));
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while creating Certificate. Please try again or contact support.'
      );
      dispatch(createCertificateFailure(apiErrorMessage));
    }
  };

export const fetchCertificate =
  (
    id: CertificateModel['id'],
    onSuccess: (certificate: CertificateModel) => void
  ): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchCertificateRequest());
      const response = await api().certificatesIdGet({ id });
      dispatch(fetchCertificateSuccess(response));
      onSuccess(response);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching Certificate. Please try again or contact support.'
      );
      dispatch(fetchCertificateFailure(apiErrorMessage));
    }
  };

export const fetchCertificateDocument =
  ({
    id,
    successCallback,
  }: {
    id: CertificateModel['id'];
    successCallback?: (response: string) => void;
  }): VoidThunk =>
  async dispatch => {
    try {
      dispatch(fetchCertificateDocumentRequest());
      // TODO: fix BE swag void type issue
      const response = await getRequest(`/api/v1/certificates/${id}/document`);
      const convertedData = await response.blob();
      const url = URL.createObjectURL(convertedData);

      dispatch(fetchCertificateDocumentSuccess());
      successCallback && successCallback(url);
    } catch (err) {
      const apiErrorMessage = await getErrorMessage(
        err,
        'An error occurred while fetching certificate document. Please try again or contact support.'
      );
      dispatch(fetchCertificateDocumentFailure(apiErrorMessage));
    }
  };

export const fetchCompanyConfig = createAsyncThunk(
  'companyProfile/fetchCompanyConfig',
  async (params: ConfigsConfigIdGetRequest, { dispatch }) => {
    try {
      return await api().configsConfigIdGet(params);
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching company config. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchVendorTree = createAsyncThunk(
  'companyProfile/fetchVendorTree',
  async (_, { dispatch, getState }) => {
    try {
      const currentVendorId = (getState() as ApplicationState).vendors
        .currentVendor.id;
      return await api().vendorsIdTreeGet({ id: currentVendorId });
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching vendor tree . Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
export const fetchBusinessFunctions = createAsyncThunk(
  'companyProfile/fetchBusinessFunctions',
  async (_, { dispatch, getState }) => {
    try {
      const currentVendorId = (getState() as ApplicationState).vendors
        .currentVendor.id;
      const response = await api().vendorsVendorIdBusinessFunctionsGet({
        vendorId: currentVendorId,
      });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching business functions. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const postBusinessFunction = createAsyncThunk(
  'companyProfile/postBusinessFunction',
  async (payload: BusinessFunctionCreatePayload, { dispatch, getState }) => {
    try {
      const currentVendorId = (getState() as ApplicationState).vendors
        .currentVendor.id;
      const response = await api().vendorsVendorIdBusinessFunctionsPost({
        vendorId: currentVendorId,
        body: payload,
      });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while posting business function. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchBusinessFunction = createAsyncThunk(
  'companyProfile/fetchBusinessFunction',
  async (id: string, { dispatch }) => {
    try {
      const response = await api().businessFunctionsIdGet({ id });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching business function. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchBusinessFunction = createAsyncThunk(
  'companyProfile/patchBusinessFunction',
  async (
    { id, payload }: { id: string; payload: BusinessFunctionUpdatePayload },
    { dispatch }
  ) => {
    try {
      const response = await api().businessFunctionsIdPatch({
        id,
        body: payload,
      });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while patching business function. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const deleteBusinessFunction = createAsyncThunk(
  'companyProfile/deleteBusinessFunction',
  async (id: string, { dispatch }) => {
    try {
      await api().businessFunctionsIdDelete({ id });
      return id;
    } catch (error) {
      await handleThunkError(
        'An error occurred while deleting business function. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
