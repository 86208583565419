/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorProductIndexFeatures
 */
export interface VendorProductIndexFeatures {
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  policies?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  agreements?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  vendorRegister?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  legalRegister?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  pestel?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  audits?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  assessments?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  checklists?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  complianceFrameworks?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  controls?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  tasks?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  dataRoom?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  riskRegister?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  calendar?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  people?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  escrow?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  profile?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  companyDocuments?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  reports?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  integrations?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  dashboard?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof VendorProductIndexFeatures
   */
  supplyContractors?: boolean;
}

export function VendorProductIndexFeaturesFromJSON(
  json: any
): VendorProductIndexFeatures {
  return VendorProductIndexFeaturesFromJSONTyped(json, false);
}

export function VendorProductIndexFeaturesFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorProductIndexFeatures {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    policies: !exists(json, 'policies') ? undefined : json['policies'],
    agreements: !exists(json, 'agreements') ? undefined : json['agreements'],
    vendorRegister: !exists(json, 'vendor_register')
      ? undefined
      : json['vendor_register'],
    legalRegister: !exists(json, 'legal_register')
      ? undefined
      : json['legal_register'],
    pestel: !exists(json, 'pestel') ? undefined : json['pestel'],
    audits: !exists(json, 'audits') ? undefined : json['audits'],
    assessments: !exists(json, 'assessments') ? undefined : json['assessments'],
    checklists: !exists(json, 'checklists') ? undefined : json['checklists'],
    complianceFrameworks: !exists(json, 'compliance_frameworks')
      ? undefined
      : json['compliance_frameworks'],
    controls: !exists(json, 'controls') ? undefined : json['controls'],
    tasks: !exists(json, 'tasks') ? undefined : json['tasks'],
    dataRoom: !exists(json, 'data_room') ? undefined : json['data_room'],
    riskRegister: !exists(json, 'risk_register')
      ? undefined
      : json['risk_register'],
    calendar: !exists(json, 'calendar') ? undefined : json['calendar'],
    people: !exists(json, 'people') ? undefined : json['people'],
    escrow: !exists(json, 'escrow') ? undefined : json['escrow'],
    profile: !exists(json, 'profile') ? undefined : json['profile'],
    companyDocuments: !exists(json, 'company_documents')
      ? undefined
      : json['company_documents'],
    reports: !exists(json, 'reports') ? undefined : json['reports'],
    integrations: !exists(json, 'integrations')
      ? undefined
      : json['integrations'],
    dashboard: !exists(json, 'dashboard') ? undefined : json['dashboard'],
    supplyContractors: !exists(json, 'supply_contractors')
      ? undefined
      : json['supply_contractors'],
  };
}

export function VendorProductIndexFeaturesToJSON(
  value?: VendorProductIndexFeatures | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    policies: value.policies,
    agreements: value.agreements,
    vendor_register: value.vendorRegister,
    legal_register: value.legalRegister,
    pestel: value.pestel,
    audits: value.audits,
    assessments: value.assessments,
    checklists: value.checklists,
    compliance_frameworks: value.complianceFrameworks,
    controls: value.controls,
    tasks: value.tasks,
    data_room: value.dataRoom,
    risk_register: value.riskRegister,
    calendar: value.calendar,
    people: value.people,
    escrow: value.escrow,
    profile: value.profile,
    company_documents: value.companyDocuments,
    reports: value.reports,
    integrations: value.integrations,
    dashboard: value.dashboard,
    supply_contractors: value.supplyContractors,
  };
}
