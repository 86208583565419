import { useState, useEffect } from 'react';

export const useFormChangedFields = <T>(initialFormValues: T) => {
  const [form, setForm] = useState<T>(initialFormValues);
  const [formCopy, setFormCopy] = useState<T>(initialFormValues);

  const getChangedFields = () => {
    return (Object.keys(form) as (keyof T)[]).filter(
      key => form[key] !== formCopy[key]
    );
  };

  const getChangedFieldsWithValues = () => {
    return getChangedFields().reduce((acc, key) => {
      acc[key] = form[key];
      return acc;
    }, {} as Partial<T>);
  };

  const resetForm = (newFormValues: T) => {
    setForm(newFormValues);
    setFormCopy(newFormValues);
  };

  return {
    form,
    setForm,
    getChangedFields,
    getChangedFieldsWithValues,
    resetForm,
  };
};
