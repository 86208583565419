import React from 'react';
import AdoptechModal from '../../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import './AddSupplierManualDetailsScreen.scss';
import { SearchScreenProps } from '../AddSupplierSearchScreen';

export const AddSupplierManualDetailsScreen: React.FC<SearchScreenProps> = ({
  form,
  onHide,
  handleChange,
}) => {
  const modalTitle = 'Add supplier';
  const description =
    "You’re about to add a new supplier, which means you'll manage their information directly. If possible, select ‘Cancel’ and invite the supplier to join the Adoptech network instead. If you wish to continue, please have the following information ready to complete the process.";
  const baseCss = 'addSupplierManualDetailsScreen';
  const dispatch = useAppDispatch();
  return (
    <AdoptechModal className={baseCss} onHide={onHide} rounded show>
      <Modal.Header>
        <div>{modalTitle}</div>
      </Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          <div className={baseCss + '--description'}>{description}</div>
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--requirements'}>
              <ul>
                <li>Company legal name</li>
                <li>Registered address</li>
                <li>Company number</li>
                <li>Contract details</li>
                <li>Service details</li>
              </ul>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onHide}>Cancel</AdoptechButton>
        <AdoptechButton
          busy={false}
          onClick={() =>
            handleChange({
              currentScreen: 'manual-form',
            })
          }
          variant={AdoptechButtonVariant.PrimaryOrange}
          rounded
        >
          Continue
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
