import classNames from 'classnames';
import { Interweave } from 'interweave';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openChatWidget } from '../../../functions/chatWidgetHelper';
import {
  setCustomAppContentClasses,
  setPageHeaderLabel,
} from '../../../store/global/globalSlice';
import { VendorProductIndexFeatures } from '../../../swagger';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../AdoptechButton/AdoptechButton';
import './PaymentLandingPage.scss';
import {
  paymentLandingPageConfig,
  subscriptionOptionsText,
} from './paymentLandingPageConfig';
import { hideBottomFooterClass } from '../../Routes/Routes';

export const productTitles: Record<keyof VendorProductIndexFeatures, string> = {
  agreements: 'Agreements',
  checklists: 'Checklists',
  policies: 'Policies',
  assessments: 'Assessments',
  vendorRegister: 'Vendor Register',
  legalRegister: 'Legal Register',
  complianceFrameworks: 'Compliance',
  dataRoom: 'Data Room',
  pestel: 'Pestel',
  audits: 'Audits',
  riskRegister: 'Risk Register',
  integrations: 'Integrations',
  reports: 'Reports',
  people: 'People',
  profile: 'Profile',
  calendar: 'Calendar',
  companyDocuments: 'Company Documents',
  escrow: 'Escrow',
  controls: 'Controls',
  tasks: 'Checks',
  dashboard: 'Dashboard',
  supplyContractors: 'Supplier Register',
};

interface ProductLandingPageProps {
  fromProduct?: keyof VendorProductIndexFeatures;
  onClick?(): void;
}

export const PaymentLandingPage: React.FC<ProductLandingPageProps> = ({
  fromProduct,
  onClick,
}) => {
  const from = fromProduct; // Deprecated, not used now: || (queryParams.get('from') as ProductLandingPageProps['fromProduct']);
  const config = paymentLandingPageConfig[from];
  const baseCss = 'paymentLandingPage';

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setPageHeaderLabel(productTitles[from]));
    dispatch(setCustomAppContentClasses([hideBottomFooterClass]));
    return () => {
      dispatch(setCustomAppContentClasses());
    };
  }, [from]);

  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>{config.title}</div>
      <div className={baseCss + '--description'}>{config.description}</div>

      <div
        className={classNames(
          `${baseCss}--button`,
          `${baseCss}--button--above-image`
        )}
      >
        <AdoptechButton
          variant={AdoptechButtonVariant.Primary}
          onClick={() => {
            if (config.ctaText === subscriptionOptionsText)
              onClick ? onClick() : alert('TODO: investigate this code');
            else {
              openChatWidget();
            }
          }}
        >
          {config.ctaText}
        </AdoptechButton>
        {config.planInfo && (
          <div className={baseCss + '--planInfo'}>
            <Interweave content={config.planInfo} />
          </div>
        )}
      </div>

      <div className={baseCss + '--image'}>
        <img src={config.image} />
      </div>
    </div>
  );
};
