import React, { useState } from 'react';
import AdoptechModal from '../../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import './AddSupplierManualFormScreen.scss';
import { SearchScreenProps } from '../AddSupplierSearchScreen';
import { AdoptechTextInput } from '../../../../components/AdoptechTextInput/AdoptechTextInput';
import { AdoptechCountrySelect } from '../../../../components/AdoptechCountrySelect/AdoptechCountrySelect';
import { AdoptechTooltip } from '../../../../components/AdoptechTooltip/AdoptechTooltip';
import { postSupplier } from '../../store/supplierThunks';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../types/applicationState';
import { useErrorHandler } from '../AddSupplierInviteScreen/AddSupplierInviteScreen';
import { supplierPageItemRoute } from '../../../../components/Routes/Routes';
import { push } from 'connected-react-router';

export const AddSupplierManualFormScreen: React.FC<SearchScreenProps> = ({
  form,
  onHide,
  handleChange,
}) => {
  const modalTitle = 'Add supplier';
  const description =
    'Get started by adding basic information on the company, you will be able to add more information later. ';
  const baseCss = 'addSupplierManualFormScreen';
  const dispatch = useAppDispatch();
  const [manualForm, setManualForm] = useState<{
    companyName: string;
    website: string;
    tradingName: string;
    selectedCountry: string;
    companyIdentifier: string;
  }>({
    companyName: '',
    website: '',
    tradingName: '',
    selectedCountry: '',
    companyIdentifier: '',
  });

  const submitStatus = useSelector(
    (state: ApplicationState) => state.supplier.postSupplierStatus
  );

  const { error } = useErrorHandler({ submitStatus });

  const handleFormSubmit = async () => {
    const payload = {
      name: manualForm.companyName,
      tradeName: manualForm.tradingName,
      countryOfIncorporation: manualForm.selectedCountry,
      companyIdentifier: manualForm.companyIdentifier,
    };
    const result = await dispatch(postSupplier(payload)).unwrap();
    if (!result?.id) return;
    onHide();
    dispatch(push(supplierPageItemRoute.replace(':id', result.id)));
  };

  return (
    <AdoptechModal className={baseCss} onHide={onHide} rounded show>
      <Modal.Header>
        <div>{modalTitle}</div>
      </Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          <div className={baseCss + '--description'}>{description}</div>
          <div className={baseCss + '--fieldRow'}>
            <AdoptechTextInput
              label="Company’s legal name *"
              value={manualForm.companyName}
              id="companyName"
              onChange={e =>
                setManualForm({ ...manualForm, companyName: e.target.value })
              }
              type="text"
              hasError={!manualForm.companyName}
            />
          </div>
          <div className={baseCss + '--fieldRow'}>
            <AdoptechTextInput
              label="Website"
              value={manualForm.website}
              id="website"
              onChange={e =>
                setManualForm({ ...manualForm, website: e.target.value })
              }
              type="text"
            />
          </div>
          <div className={baseCss + '--fieldRow'}>
            <AdoptechTextInput
              label="Trading Name"
              value={manualForm.tradingName}
              id="tradingName"
              onChange={e =>
                setManualForm({ ...manualForm, tradingName: e.target.value })
              }
              type="text"
            />
          </div>
          <div className={baseCss + '--fieldRow'}>
            <AdoptechCountrySelect
              onChange={e => {
                setManualForm({
                  ...manualForm,
                  selectedCountry: e.value,
                });
              }}
              value={manualForm.selectedCountry}
              label="Country of Incorporation *"
              hasError={!manualForm.selectedCountry}
            />
          </div>
          <div className={baseCss + '--fieldRow'}>
            <AdoptechTextInput
              htmlLabel={
                <AdoptechTooltip
                  identifier="companyIdentifier"
                  showTooltip
                  text="Company Number"
                  placement="top-start"
                >
                  <label htmlFor="companyIdentifier">Company Identifier</label>
                </AdoptechTooltip>
              }
              value={manualForm.companyIdentifier}
              id="companyIdentifier"
              placeholder="e.g. 10203040"
              onChange={e => {
                setManualForm({
                  ...manualForm,
                  companyIdentifier: e.target.value,
                });
              }}
              type="text"
            />
          </div>
          {error}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onHide}>Cancel</AdoptechButton>
        <AdoptechButton
          busy={false}
          disabled={!manualForm.companyName || !manualForm.selectedCountry}
          onClick={handleFormSubmit}
          variant={AdoptechButtonVariant.PrimaryOrange}
          rounded
        >
          Add Supplier
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
