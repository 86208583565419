import React, { useState } from 'react';
import AdoptechModal from '../../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import './AddSupplierConnectionScreen.scss';
import { SearchScreenProps } from '../AddSupplierSearchScreen';
import { AdoptechTextInput } from '../../../../components/AdoptechTextInput/AdoptechTextInput';
import { isValidEmailAddress } from '../../../../functions/isValidEmailAddress';
import { VendorConnectionCreatePayloadVendorConnection } from '../../../../swagger/models/VendorConnectionCreatePayloadVendorConnection';
import { selectCurrentVendor } from '../../../../selectors/selectCurrentVendor';
import { useSelector } from 'react-redux';
import {
  fetchSuppliers,
  postSupplierConnection,
} from '../../store/supplierThunks';
import { useErrorHandler } from '../AddSupplierInviteScreen/AddSupplierInviteScreen';
import { ApplicationState } from '../../../../types/applicationState';

export const AddSupplierConnectionScreen: React.FC<SearchScreenProps> = ({
  form,
  onHide,
  handleChange,
}) => {
  const modalTitle = 'Send a supplier connection request';
  const description =
    "Send a connection request to access the supplier's live security & compliance posture, including policies, controls, assessments and incident updates. Once connected, you can conduct due diligence and perform risk assessments.";
  const footerText =
    'We’ll notify you as soon as the supplier accepts your request.';
  const baseCss = 'addSupplierConnectionScreen';
  const dispatch = useAppDispatch();
  const [connectionForm, setConnectionForm] = useState<{
    recipientEmail: string;
  }>({
    recipientEmail: '',
  });
  const currentVendor = useSelector(selectCurrentVendor);

  const submitStatus = useSelector(
    (state: ApplicationState) => state.supplier.postSupplierConnectionStatus
  );

  const { error } = useErrorHandler({ submitStatus });

  const handleFormSubmit = async () => {
    const payload: VendorConnectionCreatePayloadVendorConnection = {
      vendorName: form.selectedSearchSupplier.name,
      producerId: form.selectedSearchSupplier.vendorId,
      recipientUserEmail: connectionForm.recipientEmail,
      consumerId: currentVendor?.id,
      permissions: {
        supplierRegister: true,
      },
    };

    const result = await dispatch(postSupplierConnection(payload));
    if (result.meta.requestStatus === 'rejected') return;
    onHide();
    dispatch(fetchSuppliers());
  };

  return (
    <AdoptechModal className={baseCss} onHide={onHide} rounded show>
      <Modal.Header>
        <div>{modalTitle}</div>
      </Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          <div className={baseCss + '--description'}>{description}</div>
          <div className={baseCss + '--fieldRow'}>
            <AdoptechTextInput
              label="Recipient email *"
              value={connectionForm.recipientEmail}
              id="recipient-email"
              onChange={e =>
                setConnectionForm({
                  ...connectionForm,
                  recipientEmail: e.target.value,
                })
              }
              type="email"
              hasError={
                !connectionForm.recipientEmail ||
                !isValidEmailAddress(connectionForm.recipientEmail)
              }
            />
          </div>
          {error}
          <div className={baseCss + '--footerText'}>{footerText}</div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onHide}>Cancel</AdoptechButton>
        <AdoptechButton
          busy={false}
          disabled={connectionForm.recipientEmail.length === 0}
          onClick={handleFormSubmit}
          variant={AdoptechButtonVariant.PrimaryOrange}
          rounded
        >
          Continue
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
