import { api } from '../../api/api';
import { ApplicationState } from '../../types/applicationState';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { handleThunkError } from '../helpers/thunkHelpers';
import {
  VendorConnectionsIdPatchRequest,
  VendorsVendorIdVendorConnectionsGetConnectionTypeEnum,
} from '../../swagger';

export const fetchClients = createAsyncThunk(
  'clientRegister/fetchClients',
  async (_, { dispatch, getState }) => {
    const vendorId = (getState() as ApplicationState).vendors.currentVendor?.id;
    try {
      const response = await api().vendorsVendorIdVendorConnectionsGet({
        vendorId,
        connectionType:
          VendorsVendorIdVendorConnectionsGetConnectionTypeEnum.Clients,
      });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching vendor connections. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchClient = createAsyncThunk(
  'clientRegister/fetchClient',
  async (clientId: string, { dispatch }) => {
    try {
      const response = await api().vendorConnectionsIdGet({ id: clientId });
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching client. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const deleteClient = createAsyncThunk(
  'clientRegister/deleteClient',
  async (clientId: string, { dispatch }) => {
    try {
      await api().vendorConnectionsIdDelete({ id: clientId });
      return { id: clientId };
    } catch (error) {
      await handleThunkError(
        'An error occurred while deleting client. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchClient = createAsyncThunk(
  'clientRegister/patchClient',
  async (payload: VendorConnectionsIdPatchRequest, { dispatch }) => {
    try {
      return await api().vendorConnectionsIdPatch({
        id: payload.id,
        body: payload.body,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while patching client. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
