import React, { useEffect } from 'react';
import './ClienRegisterPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { useAdoptechSearch } from '../../../hooks/useAdoptechSearch';
import { setPageTitle } from '../../../components/PageHeader/PageHeader';
import { setPageHeaderLabel } from '../../../store/global/globalSlice';
import { fetchClients } from '../../../store/clientRegister/clientRegisterThunks';
import AdoptechFlexTable from '../../../components/AdoptechFlexTable/AdoptechFlexTable';
import { adoptechGridTableCss } from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { ClientRegisterState } from '../../../store/clientRegister/clientRegisterState';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';
import classNames from 'classnames';
import { EditClientDrawer } from '../EditClientDrawer/EditClientDrawer';
import { VendorSupplyContractorModel } from '../../../swagger/models/VendorSupplyContractorModel';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import { Grid } from '../../../types/grid';
import { VendorConnectionModel } from '../../../swagger';
import { selectClients } from './selectClients';
import { StatusBadge } from '../../supplier/SuppliersPage/StatusBadge/StatusBadge';

export const ClientRegisterPage: React.FC = () => {
  const dispatch = useDispatch();

  const collection = useSelector(selectClients);

  const {
    search,
    setSearch,
    results: clients,
  } = useAdoptechSearch({
    keys: ['vendorName'],
    collection,
  });
  useEffect(() => {
    setPageTitle('Client Management');
    dispatch(setPageHeaderLabel('Client Management'));
    dispatch(fetchClients());
  }, []);

  const [showEditClientDrawer, setShowEditClientDrawer] = React.useState(false);
  const [selectedClientId, setSelectedClientId] = React.useState<string>(null);
  const baseCss = 'clientsPage';
  const handleRowClick = (id: string) => {
    setSelectedClientId(id);
    setShowEditClientDrawer(true);
  };

  const handleDrawerClose = () => {
    setShowEditClientDrawer(false);
    setSelectedClientId(null);
  };

  return (
    <div className={baseCss}>
      <AdoptechFlexTable
        draggable={false}
        tableHeaders={
          <div className={adoptechGridTableCss + '--header'}>
            <SortableTableHeader<VendorConnectionModel>
              columnName="vendorName"
              grid={Grid.SupplierRegister}
              label="Client name"
              notInTable
            />
            <SortableTableHeader<VendorConnectionModel>
              columnName="status"
              grid={Grid.SupplierRegister}
              label="Connection Status"
              notInTable
            />
            <SortableTableHeader<VendorConnectionModel>
              columnName="status"
              grid={Grid.SupplierRegister}
              label="Owner"
              notInTable
            />
            <SortableTableHeader<VendorConnectionModel>
              columnName="recipientUserEmail"
              grid={Grid.SupplierRegister}
              label="Client Contact"
              notInTable
            />
          </div>
        }
        tableRows={clients.map(client => (
          <ClientRow
            key={client.id}
            handleRowClick={handleRowClick}
            client={client}
            baseCss={baseCss}
          />
        ))}
        extraClass=""
        collection={clients}
        header={
          <ClientsPageHeader
            clients={clients}
            search={search}
            setSearch={setSearch}
          />
        }
        noDataText="No clients to show"
      />
      {showEditClientDrawer && (
        <EditClientDrawer onClose={handleDrawerClose} id={selectedClientId} />
      )}
    </div>
  );
};

const ClientsPageHeader: React.FC<{
  clients: ClientRegisterState['clients'];
  search: string;
  setSearch: (val: string) => void;
}> = ({ clients, search, setSearch }) => {
  const baseCss = 'clientsPage';

  return (
    <div className={baseCss + '--tableHeader'}>
      <div>
        <div className={baseCss + '--counters'}>
          All Clients
          <Lozenge value={clients.length} />
        </div>
      </div>
      <div className={baseCss + '--filterButtons'}>
        <div className={baseCss + '--search'}>
          <AdoptechTextInput
            id="search"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
            type="text"
            placeholder="Search"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
      </div>
    </div>
  );
};

const ClientRow: React.FC<{
  client: any;
  baseCss: string;
  handleRowClick: (id: string) => void;
}> = ({ client, baseCss, handleRowClick }) => {
  return (
    <div
      key={client.id}
      onClick={() => handleRowClick(client.id)}
      className={`${adoptechGridTableCss}--row ${baseCss}--tableRow`}
    >
      <AdoptechOverflowLine>
        <div>{client.vendorName}</div>
      </AdoptechOverflowLine>
      <StatusBadge status={client.status} />
      <AdoptechOverflowLine>
        <div>
          <UserAvatar user={client.owner} size="small" />
        </div>
      </AdoptechOverflowLine>
      <AdoptechOverflowLine>
        <div>{client.recipientUserEmail}</div>
      </AdoptechOverflowLine>
    </div>
  );
};
