/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SupplyContractorUpdatePayloadSupplyContractor,
  SupplyContractorUpdatePayloadSupplyContractorFromJSON,
  SupplyContractorUpdatePayloadSupplyContractorFromJSONTyped,
  SupplyContractorUpdatePayloadSupplyContractorToJSON,
} from './';

/**
 *
 * @export
 * @interface SupplyContractorUpdatePayload
 */
export interface SupplyContractorUpdatePayload {
  /**
   *
   * @type {SupplyContractorUpdatePayloadSupplyContractor}
   * @memberof SupplyContractorUpdatePayload
   */
  supplyContractor: SupplyContractorUpdatePayloadSupplyContractor;
}

export function SupplyContractorUpdatePayloadFromJSON(
  json: any
): SupplyContractorUpdatePayload {
  return SupplyContractorUpdatePayloadFromJSONTyped(json, false);
}

export function SupplyContractorUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractorUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    supplyContractor: SupplyContractorUpdatePayloadSupplyContractorFromJSON(
      json['supply_contractor']
    ),
  };
}

export function SupplyContractorUpdatePayloadToJSON(
  value?: SupplyContractorUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    supply_contractor: SupplyContractorUpdatePayloadSupplyContractorToJSON(
      value.supplyContractor
    ),
  };
}
