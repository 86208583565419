import classNames from 'classnames';
import React from 'react';
import { Modal } from 'react-bootstrap';
import './AdoptechModal.scss';

interface AdoptechModalProps {
  className?: string;
  show: boolean;
  onHide: any;
  backdrop?: any;
  rounded?: boolean;
}

const AdoptechModal: React.FC<AdoptechModalProps> = ({
  className,
  children,
  show,
  onHide,
  backdrop,
  rounded,
}) => {
  const classes = classNames('adoptechModal', className, { rounded });

  if (!show) {
    return null;
  }

  return (
    <Modal
      centered
      className={classes}
      show={show}
      onHide={onHide ? onHide : () => {}}
      backdrop={backdrop}
    >
      <>{children}</>
    </Modal>
  );
};

export default AdoptechModal;
