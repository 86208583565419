import React, { useEffect } from 'react';
import { setPageTitle } from '../../../components/PageHeader/PageHeader';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSuppliers } from '../store/supplierThunks';
import { adoptechGridTableCss } from '../../../components/AdoptechGridTable/AdoptechGridTable';
import { ApplicationState } from '../../../types/applicationState';
import { SortableTableHeader } from '../../../components/SortableTableHeader/SortableTableHeader';
import { Grid } from '../../../types/grid';
import './SuppliersPage.scss';
import { setPageHeaderLabel } from '../../../store/global/globalSlice';
import { Lozenge } from '../../../components/Lozenge/Lozenge';
import { AdoptechTextInput } from '../../../components/AdoptechTextInput/AdoptechTextInput';
import { faSearch } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons/faAngleDown';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { useAdoptechSearch } from '../../../hooks/useAdoptechSearch';
import {
  faCloudDownloadAlt,
  faColumns3,
} from '@fortawesome/pro-light-svg-icons';
import AdoptechFlexTable from '../../../components/AdoptechFlexTable/AdoptechFlexTable';
import { Dropdown } from 'react-bootstrap';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { AdoptechDropdownToggle } from '../../../components/AdoptechDropdownToggle/AdoptechDropdownToggle';
import { AddSupplierModal } from '../AddSupplierModal/AddSupplierModal';
import { VendorSupplyContractorModel } from '../../../swagger/models/VendorSupplyContractorModel';
import { SupplierRegistryState } from '../store/supplierState';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { push } from 'connected-react-router';
import { supplierPageItemRoute } from '../../../components/Routes/Routes';
import { UserAvatar } from '../../../components/UserAvatar/UserAvatar';
import classNames from 'classnames';
import { capitaliseFirst } from '../../../functions/capitaliseFirst';
import { StatusBadge } from './StatusBadge/StatusBadge';

export const SuppliersPage: React.FC = () => {
  const dispatch = useDispatch();
  const { suppliers: collection } = useSelector(
    (state: ApplicationState) => state.supplier
  );

  const {
    search,
    setSearch,
    results: suppliers,
  } = useAdoptechSearch({
    keys: ['name'],
    collection,
  });
  useEffect(() => {
    setPageTitle('Suppliers');
    dispatch(setPageHeaderLabel('Supplier Management'));
    dispatch(fetchSuppliers());
  }, []);
  const baseCss = 'suppliersPage';

  return (
    <div className={baseCss}>
      <AdoptechFlexTable
        draggable={false}
        tableHeaders={
          <div className={adoptechGridTableCss + '--header'}>
            <SortableTableHeader<VendorSupplyContractorModel>
              // @ts-ignore
              columnName="name"
              grid={Grid.SupplierRegister}
              label="Supplier name"
              notInTable
            />
            <div>LEI</div>
            <div>Supplier Owner</div>
            <div>Supplier Status</div>
            <div>Review Date</div>
            <div>No. contracts</div>
            <div>No. services</div>
          </div>
        }
        tableRows={suppliers.map(supplier => (
          <div
            key={supplier.id}
            onClick={() =>
              dispatch(push(supplierPageItemRoute.replace(':id', supplier.id)))
            }
            className={`${adoptechGridTableCss}--row ${baseCss}--tableRow`}
          >
            <div>
              <AdoptechOverflowLine>
                {supplier.supplyContractor.name}
              </AdoptechOverflowLine>
            </div>
            <div>{supplier.supplyContractor.legalEntityIdentifier}</div>
            <div>
              <AdoptechOverflowLine>
                <div>
                  <UserAvatar user={supplier.owner} size="small" />
                </div>
              </AdoptechOverflowLine>
            </div>
            <StatusBadge status={supplier.status} />
            <div>TODO BE</div>
            <div>TODO BE</div>
            <div>TODO BE</div>
          </div>
        ))}
        extraClass=""
        collection={suppliers}
        header={
          <SuppliersPageHeader
            suppliers={suppliers}
            search={search}
            setSearch={setSearch}
          />
        }
        noDataText="No suppliers to show"
      />
    </div>
  );
};

const SuppliersPageHeader: React.FC<{
  suppliers: SupplierRegistryState['suppliers'];
  search: string;
  setSearch: (val: string) => void;
}> = ({ suppliers, search, setSearch }) => {
  const baseCss = 'suppliersPage';
  // TODO: implement it on the backend
  const canCreateSuppliers = true;
  const [showAddSupplierModal, setShowAddSupplierModal] = React.useState(false);

  return (
    <div className={baseCss + '--tableHeader'}>
      <div>
        <div className={baseCss + '--counters'}>
          All Suppliers
          <Lozenge value={suppliers.length} />
        </div>
      </div>
      <div className={baseCss + '--filterButtons'}>
        <div className={baseCss + '--search'}>
          <AdoptechTextInput
            id="search"
            value={search}
            onChange={e => setSearch(e.currentTarget.value)}
            type="text"
            placeholder="Search"
            icon={faSearch}
            additionalClass="adoptechTextInput-search"
          />
        </div>
      </div>
      <div className={baseCss + '--actionsButtons'}>
        <AdoptechButton
          size="middle2"
          onClick={() => alert('TODO')}
          variant={AdoptechButtonVariant.Default}
          disabled
          icon={faCloudDownloadAlt}
        >
          Export
        </AdoptechButton>

        {canCreateSuppliers && (
          <>
            {showAddSupplierModal && (
              <AddSupplierModal onHide={() => setShowAddSupplierModal(false)} />
            )}
            <Dropdown className={baseCss + '--addButton'}>
              <Dropdown.Toggle as={AdoptechDropdownToggle}>
                <AdoptechButton
                  variant={AdoptechButtonVariant.PrimaryOrange}
                  icon={faPlus}
                  iconSize="large"
                  rounded
                >
                  Add Supplier
                </AdoptechButton>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item disabled onClick={() => console.log('TODO ')}>
                  Bulk upload (CSV)
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowAddSupplierModal(true)}>
                  Invite new supplier
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </>
        )}
      </div>
    </div>
  );
};
