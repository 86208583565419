import classNames from 'classnames';
import React from 'react';
import { capitaliseFirst } from '../../../../functions/capitaliseFirst';

import './StatusBadge.scss';

interface StatusBadgeProps {
  status: string;
  statusClass?: string;
  styles?: React.CSSProperties;
}

export const StatusBadge: React.FC<StatusBadgeProps> = props => {
  const baseCss = 'statusBadge';

  return (
    <div
      style={props.styles}
      className={classNames(baseCss, props.statusClass || props.status)}
    >
      {capitaliseFirst(props.status)}
    </div>
  );
};
