/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum VendorConnectionStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected',
}

export function VendorConnectionStatusFromJSON(
  json: any
): VendorConnectionStatus {
  return VendorConnectionStatusFromJSONTyped(json, false);
}

export function VendorConnectionStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorConnectionStatus {
  return json as VendorConnectionStatus;
}

export function VendorConnectionStatusToJSON(
  value?: VendorConnectionStatus | null
): any {
  return value as any;
}
