import classNames from 'classnames';
import googleLogo from 'images/google.svg';
import microsoftLogo from 'images/microsoft.png';
import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import './AdoptechButton.scss';

export enum AdoptechButtonVariant {
  Default,
  Inverse,
  Primary,
  Warning,
  Success,
  SuccessFilled,
  Google,
  PrimaryTransparent,
  SuccessTransparent,
  WarningTransparent,
  Gray,
  White,
  OrangeOnOrange,
  OrangeOnWhite,
  Transparent,
  PrimaryOrange,
  WhiteOnDarkOrange,
  DarkOrangeOnWhite,
  DarkGray,
  Microsoft,
}

export interface AdoptechButtonProps {
  busy?: boolean;
  disabled?: boolean;
  icon?: IconDefinition;
  rawIcon?: string;
  iconSize?: 'large';
  iconAlignment?: 'left' | 'right';
  uppercase?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  variant?: AdoptechButtonVariant;
  disabledTooltip?: string;
  type?: 'button' | 'reset' | 'submit';
  extraClass?: string;
  disablePointerEvents?: boolean;
  shadow?: boolean;
  rounded?: boolean;
  size?: 'middle' | 'middle2';
  theme?: 'asSearchInputTheme';
}

export const AdoptechButton: React.FC<AdoptechButtonProps> = ({
  children,
  busy,
  disabled,
  icon,
  iconSize,
  uppercase,
  onClick,
  variant,
  disabledTooltip,
  type,
  extraClass,
  disablePointerEvents,
  shadow,
  rounded,
  iconAlignment,
  rawIcon,
  size,
  theme,
}) => {
  const mainClasses = classNames({
    adoptechButton: true,
    'adoptechButton-uppercase': uppercase,
    'adoptechButton-inverse': variant === AdoptechButtonVariant.Inverse,
    'adoptechButton-primary': variant === AdoptechButtonVariant.Primary,
    'adoptechButton-primaryOrange':
      variant === AdoptechButtonVariant.PrimaryOrange,
    'adoptechButton-whiteOnDarkOrange':
      variant === AdoptechButtonVariant.WhiteOnDarkOrange,
    'adoptechButton-darkOrangeOnWhite':
      variant === AdoptechButtonVariant.DarkOrangeOnWhite,
    'adoptechButton-primaryTransparent':
      variant === AdoptechButtonVariant.PrimaryTransparent,
    'adoptechButton-warning': variant === AdoptechButtonVariant.Warning,
    'adoptechButton-warningTransparent':
      variant === AdoptechButtonVariant.WarningTransparent,
    'adoptechButton-success': variant === AdoptechButtonVariant.Success,
    'adoptechButton-successFilled':
      variant === AdoptechButtonVariant.SuccessFilled,
    'adoptechButton-successTransparent':
      variant === AdoptechButtonVariant.SuccessTransparent,
    'adoptechButton-gray': variant === AdoptechButtonVariant.Gray,
    'adoptechButton-darkGray': variant === AdoptechButtonVariant.DarkGray,
    'adoptechButton-white': variant === AdoptechButtonVariant.White,
    'adoptechButton-google': variant === AdoptechButtonVariant.Google,
    'adoptechButton-microsoft': variant === AdoptechButtonVariant.Microsoft,
    'adoptechButton-orangeOnOrange':
      variant === AdoptechButtonVariant.OrangeOnOrange,
    'adoptechButton-orangeOnWhite':
      variant === AdoptechButtonVariant.OrangeOnWhite,
    'adoptechButton-transparent': variant === AdoptechButtonVariant.Transparent,
    'adoptechButton-pointer-events-none': disablePointerEvents,
    'adoptechButton--shadow': shadow,
    'adoptechButton--rounded': rounded,
    'adoptechButton--with-large-icon': iconSize === 'large',
    'adoptechButton--with-right-icon': iconAlignment === 'right',
    'adoptechButton--middleSize': size === 'middle',
    'adoptechButton--middleSize2': size === 'middle2',
    'adoptechButton--asSearchInputTheme': theme === 'asSearchInputTheme',
    [extraClass || '']: !!extraClass,
  });

  return (
    <div className="adoptechButtonContainer">
      <Button
        className={mainClasses}
        disabled={disabled || busy}
        onClick={!busy && onClick ? onClick : undefined}
        type={type}
        title={disabled ? disabledTooltip : undefined}
      >
        {busy && <Spinner animation="border" as="span" size="sm" />}
        {!busy && (
          <div className="adoptechButton--content">
            {variant === AdoptechButtonVariant.Google && (
              <img
                alt="Google MainMenuLogo"
                src={googleLogo}
                className="adoptechButton--googleLogo"
              />
            )}
            {variant === AdoptechButtonVariant.Microsoft && (
              <img
                alt="Microsoft"
                src={microsoftLogo}
                className="adoptechButton--microsoftLogo"
              />
            )}
            {iconAlignment == 'right' ? (
              <>
                {children}
                {icon && (
                  <FontAwesomeIcon
                    className="adoptechButton--icon"
                    icon={icon}
                  />
                )}
              </>
            ) : (
              <>
                {icon && (
                  <FontAwesomeIcon
                    className="adoptechButton--icon"
                    icon={icon}
                  />
                )}
                {rawIcon && <img src={rawIcon} />}
                {children}
              </>
            )}
          </div>
        )}
      </Button>
    </div>
  );
};

AdoptechButton.defaultProps = {
  variant: AdoptechButtonVariant.Default,
};
