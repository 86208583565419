import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDefaultSelectionOptions } from '../../../functions/getDefaultSelectionOptions';
import { fetchTable } from '../../../store/vendors/vendorsThunks';
import { QuestionTableTypeEnum } from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { SelectionOption } from '../../../types/selectionOption';
import { AdoptechReactSelect } from '../../../components/AdoptechReactSelect/AdoptechReactSelect';
import { AdoptechSelectDescriptionOption } from '../RegulatoryInformation/RegulatoryInformation';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';

interface AdoptechBusinessTypeSelectProps {
  onChange?: (value: SelectionOption) => void;
  value: string;
  disabled?: boolean;
  placeholder?: string;
  isMultiSelect?: boolean;
  theme?: 'new';
  label?: string;
}

export const AdoptechBusinessTypeSelect: React.FC<
  AdoptechBusinessTypeSelectProps
> = ({
  onChange,
  value,
  disabled,
  placeholder,
  isMultiSelect,
  theme,
  label,
}) => {
  const dispatch = useDispatch();

  const rawOptions = useSelector((state: ApplicationState) =>
    getDefaultSelectionOptions(QuestionTableTypeEnum.OrganisationType, state)
  );
  const isTableFetched = rawOptions.length > 0;

  useEffect(() => {
    !isTableFetched &&
      dispatch(fetchTable(QuestionTableTypeEnum.OrganisationType));
  }, []);

  const descriptions: { [key: string]: string } = {
    CONS: 'Individual acting in a business capacity such as a self-employed consultant or tradesperson',
  };

  const options = useMemo(
    () =>
      rawOptions.map(option => ({
        value: option.value,
        label: option.label,
        description: descriptions[option.value],
      })),
    [rawOptions]
  );

  const SelectComponent =
    theme === 'new' ? AdoptechReactSelect2 : AdoptechReactSelect;

  return (
    <SelectComponent
      id="adoptechBusinessTypeSelect"
      isDisabled={disabled}
      isMulti={isMultiSelect}
      options={options}
      onChange={onChange}
      value={options.find(x => x.value === value)}
      placeholder={placeholder}
      components={{ Option: AdoptechSelectDescriptionOption }}
      label={label}
    />
  );
};
