/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CompanyIdentifierCreatePayload,
  CompanyIdentifierCreatePayloadFromJSON,
  CompanyIdentifierCreatePayloadFromJSONTyped,
  CompanyIdentifierCreatePayloadToJSON,
  Vendor,
  VendorFromJSON,
  VendorFromJSONTyped,
  VendorToJSON,
  VendorsCreatePayloadCompany,
  VendorsCreatePayloadCompanyFromJSON,
  VendorsCreatePayloadCompanyFromJSONTyped,
  VendorsCreatePayloadCompanyToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorsCreatePayload
 */
export interface VendorsCreatePayload {
  /**
   *
   * @type {Vendor}
   * @memberof VendorsCreatePayload
   */
  vendor: Vendor;
  /**
   *
   * @type {VendorsCreatePayloadCompany}
   * @memberof VendorsCreatePayload
   */
  company: VendorsCreatePayloadCompany;
  /**
   *
   * @type {Array<CompanyIdentifierCreatePayload>}
   * @memberof VendorsCreatePayload
   */
  companyIdentifiers: Array<CompanyIdentifierCreatePayload>;
  /**
   *
   * @type {string}
   * @memberof VendorsCreatePayload
   */
  vendorConnectionId?: string;
}

export function VendorsCreatePayloadFromJSON(json: any): VendorsCreatePayload {
  return VendorsCreatePayloadFromJSONTyped(json, false);
}

export function VendorsCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorsCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendor: VendorFromJSON(json['vendor']),
    company: VendorsCreatePayloadCompanyFromJSON(json['company']),
    companyIdentifiers: (json['company_identifiers'] as Array<any>).map(
      CompanyIdentifierCreatePayloadFromJSON
    ),
    vendorConnectionId: !exists(json, 'vendor_connection_id')
      ? undefined
      : json['vendor_connection_id'],
  };
}

export function VendorsCreatePayloadToJSON(
  value?: VendorsCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor: VendorToJSON(value.vendor),
    company: VendorsCreatePayloadCompanyToJSON(value.company),
    company_identifiers: (value.companyIdentifiers as Array<any>).map(
      CompanyIdentifierCreatePayloadToJSON
    ),
    vendor_connection_id: value.vendorConnectionId,
  };
}
