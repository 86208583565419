import React from 'react';
import './AdoptechFlexTable.scss';
import useDragToScroll from '../../hooks/useDragToScroll';
import { NoDataText } from '../NoDataText/NoDataText';
import AdoptechGridTable from '../AdoptechGridTable/AdoptechGridTable';
import classNames from 'classnames';

interface Props {
  tableHeaders: React.ReactElement;
  tableRows: React.ReactNode;
  extraClass: string;
  collection: { length: number };
  header: React.ReactNode;
  noDataText: string;
  draggable: boolean;
  theme?: 'small';
}

const AdoptechFlexTable: React.FC<Props> = ({
  tableHeaders,
  tableRows,
  extraClass,
  collection,
  header,
  noDataText,
  draggable,
  theme,
}) => {
  const baseCss = 'adoptechFlexTable';
  const {
    ref: tableRef,
    handleMouseDown,
    handleMouseLeave,
    handleMouseUp,
    handleMouseMove,
  } = useDragToScroll();

  return (
    <div
      className={classNames(
        `${baseCss} ${baseCss}--tableWrapper`,
        draggable ? 'draggable' : 'notDraggable',
        theme === 'small' ? 'smallTheme' : '',
        collection.length <= 0 ? 'emptyTheme' : ''
      )}
    >
      {header}
      <div
        className={`${baseCss}--table ${baseCss}--dynamicColumns ${draggable ? 'draggableTableTheme' : ''} ${extraClass}`}
        ref={draggable ? tableRef : undefined}
        onMouseDown={draggable ? handleMouseDown : undefined}
        onMouseLeave={draggable ? handleMouseLeave : undefined}
        onMouseUp={draggable ? handleMouseUp : undefined}
        onMouseMove={draggable ? handleMouseMove : undefined}
      >
        {collection.length <= 0 ? (
          <NoDataText text={noDataText} />
        ) : (
          <AdoptechGridTable header={tableHeaders}>
            {tableRows}
          </AdoptechGridTable>
        )}
      </div>
    </div>
  );
};

export default AdoptechFlexTable;
