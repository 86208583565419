import React, { useEffect } from 'react';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import './EditClientDrawer.scss';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  deleteClient,
  fetchClient,
  patchClient,
} from '../../../store/clientRegister/clientRegisterThunks';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  VendorConnectionExtended,
  VendorConnectionStatus,
  VendorConnectionUpdatePayload,
} from '../../../swagger';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { formatShortDateTime } from '../../../functions/formatShortDateTime';
import { formatDate } from '../../../functions/formatDate';
import { ApplicationState } from '../../../types/applicationState';
import { useSelector } from 'react-redux';
import { useFormChangedFields } from '../../../hooks/useFormChangedFields';

interface EditClientDrawerProps {
  onClose(): void;
  id: string;
}

interface ClientForm {
  vendorName: VendorConnectionExtended['vendorName'];
  status: VendorConnectionExtended['status'];
  approver: VendorConnectionExtended['approver'];
  approvedAt: VendorConnectionExtended['approvedAt'];
  ownerId: VendorConnectionExtended['owner']['id'];
  recipientUserEmail: VendorConnectionExtended['recipientUserEmail'];
}

export const EditClientDrawer: React.FC<EditClientDrawerProps> = props => {
  const isPatching = useSelector(
    (state: ApplicationState) =>
      state.clientRegister.patchClientStatus === 'loading'
  );
  const isDeleting = useSelector(
    (state: ApplicationState) =>
      state.clientRegister.deleteClientStatus === 'loading'
  );
  const dispatch = useAppDispatch();

  const handleDoneClick = async () => {
    const changedFields = getChangedFields();

    if (changedFields.length === 0) {
      props.onClose();
      return;
    }

    const changedFieldsWithValues = getChangedFieldsWithValues();
    const body: VendorConnectionUpdatePayload = {
      vendorConnection: changedFieldsWithValues,
    };
    const result = await dispatch(
      patchClient({
        id: props.id,
        body,
      })
    );
    if (result.meta.requestStatus === 'rejected') return;

    props.onClose();
  };

  const initialFormValues: ClientForm = {
    vendorName: '',
    status: null,
    approver: null,
    approvedAt: '',
    ownerId: null,
    recipientUserEmail: '',
  };
  const {
    form,
    setForm,
    getChangedFieldsWithValues,
    getChangedFields,
    resetForm,
  } = useFormChangedFields(initialFormValues);

  useEffect(() => {
    if (!props.id) return;
    const fetchDetails = async () => {
      const clientDetails = await dispatch(fetchClient(props.id)).unwrap();
      const formPayload = {
        vendorName: clientDetails.vendorName,
        status: clientDetails.status,
        approver: clientDetails.approver,
        approvedAt: clientDetails.approvedAt,
        ownerId: clientDetails.owner?.id,
        recipientUserEmail: clientDetails.recipientUserEmail,
      };
      resetForm(formPayload);
    };
    fetchDetails();
  }, [props.id]);
  return (
    <AdoptechHS
      title={`Edit ${form.vendorName || 'Client'}`}
      onClose={props.onClose}
      showConfirmationWarning={false}
      theme="medium"
      show
      footer={
        <>
          <AdoptechButton
            onClick={props.onClose}
            variant={AdoptechButtonVariant.White}
            disabled={isDeleting || isPatching}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={handleDoneClick}
            variant={AdoptechButtonVariant.Primary}
            busy={isPatching}
            disabled={isDeleting}
          >
            Update
          </AdoptechButton>
        </>
      }
    >
      <EditClientForm
        form={form}
        setForm={setForm}
        id={props.id}
        onClose={props.onClose}
        isPatching={isPatching}
        isDeleting={isDeleting}
      />
    </AdoptechHS>
  );
};

const EditClientForm: React.FC<{
  form: ClientForm;
  id: string;
  setForm: (form: ClientForm) => void;
  onClose: () => void;
  isPatching: boolean;
  isDeleting: boolean;
}> = props => {
  const baseCss = 'editClientForm';
  const { users, ownerOptions } = useOwnerOptions({
    onlyAdminable: false,
  });

  const statusOptions = [
    { label: 'Pending', value: VendorConnectionStatus.Pending },
    { label: 'Approved', value: VendorConnectionStatus.Approved },
    { label: 'Rejected', value: VendorConnectionStatus.Rejected },
  ];
  const dispatch = useAppDispatch();
  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this client?')) return;
    const result = await dispatch(deleteClient(props.id));
    if (result.meta.requestStatus === 'rejected') return;
    props.onClose();
  };

  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>Client information</div>
      <div className={baseCss + '--fields'}>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id="vendorName"
            type="text"
            label="Supplier Name"
            disabled
            value={props.form.vendorName}
            onChange={e =>
              props.setForm({ ...props.form, vendorName: e.target.value })
            }
          />
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechReactSelect2
            id="status"
            label="Connection status"
            isDisabled
            options={statusOptions}
            onChange={(e: any) =>
              props.setForm({
                ...props.form,
                status: e.value,
              })
            }
            value={statusOptions.find(
              option => option.value == props.form.status
            )}
            placeholder="Select status"
          />
          {props.form.status === VendorConnectionStatus.Approved && (
            <div className={baseCss + '--approvedBadge'}>
              <FontAwesomeIcon icon={faCheckCircle} />
              <b>Approved by</b>
              <span>{props.form.approver?.fullName}</span>
              <span>{formatDate(props.form.approvedAt, 'longDateTime2')}</span>
            </div>
          )}
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechReactSelect2
            id="owner"
            label="Owner"
            isDisabled={false}
            options={ownerOptions}
            onChange={(e: any) => {
              props.setForm({
                ...props.form,
                ownerId: e.value,
              });
            }}
            value={ownerOptions.find(
              option => option.value == props.form.ownerId
            )}
            placeholder="Select owner"
            showUserAvatar
          />
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id="recipientUserEmail"
            type="email"
            disabled
            label="Client contact"
            value={props.form.recipientUserEmail}
            onChange={e =>
              props.setForm({
                ...props.form,
                recipientUserEmail: e.target.value,
              })
            }
          />
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechButton
            onClick={handleDelete}
            variant={AdoptechButtonVariant.Warning}
            busy={props.isDeleting}
            disabled={props.isPatching}
          >
            Delete
          </AdoptechButton>
        </div>
      </div>
    </div>
  );
};
