import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../api/api';
import { ApplicationState } from '../../../types/applicationState';
import { handleThunkError } from '../../../store/helpers/thunkHelpers';
import { VendorSupplyContractorCreatePayloadVendorSupplyContractor } from '../../../swagger/models/VendorSupplyContractorCreatePayloadVendorSupplyContractor';
import { VendorConnectionCreatePayloadVendorConnection } from '../../../swagger/models/VendorConnectionCreatePayloadVendorConnection';
import {
  SupplyContractorsIdPatchRequest,
  SupplyContractsIdPatchRequest,
  VendorSupplyContractorsIdPatchRequest,
  VendorsVendorIdSupplyContractsPostRequest,
} from '../../../swagger';
import { WithoutVendorId } from '../../calendar/store/calendarThunksApiHelpers';

export const fetchSuppliers = createAsyncThunk(
  'supplier/fetchSuppliers',
  async (_, { dispatch, getState }) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;
      const result = await api().vendorsVendorIdVendorSupplyContractorsGet({
        vendorId,
      });
      return result;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching suppliers. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchSearchedSuppliers = createAsyncThunk(
  'supplier/fetchSearchedSuppliers',
  async (payload: { name: string }, { dispatch, getState }) => {
    try {
      return await api().vendorsVendorIdSupplyContractorsSearchGet({
        vendorId: (getState() as ApplicationState).vendors.currentVendor?.id,
        name: payload.name,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while searching supply contractors. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const postSupplier = createAsyncThunk(
  'supplier/postSupplier',
  async (
    payload: VendorSupplyContractorCreatePayloadVendorSupplyContractor,
    { dispatch, getState }
  ) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;
      return await api().vendorsVendorIdVendorSupplyContractorsPost({
        vendorId,
        body: {
          vendorSupplyContractor: payload,
        },
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while posting supplier. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const postSupplierConnection = createAsyncThunk(
  'supplier/postSupplierConnection',
  async (
    payload: VendorConnectionCreatePayloadVendorConnection,
    { dispatch, getState }
  ) => {
    const vendorId = (getState() as ApplicationState).vendors.currentVendor?.id;
    try {
      await api().vendorsVendorIdVendorConnectionsPost({
        vendorId,
        body: {
          vendorConnection: payload,
        },
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while posting supplier connection. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchSupplier = createAsyncThunk(
  'supplier/fetchSupplier',
  async (id: string, { dispatch }) => {
    try {
      const response = await api().vendorSupplyContractorsIdGet({
        id,
      });
      console.log('api response after swag', response);
      return response;
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching supplier. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchSupplier = createAsyncThunk(
  'supplier/patchSupplier',
  async (payload: SupplyContractorsIdPatchRequest, { dispatch }) => {
    try {
      return await api().supplyContractorsIdPatch(payload);
    } catch (error) {
      await handleThunkError(
        'An error occurred while patching supplier. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const deleteSupplier = createAsyncThunk(
  'supplier/deleteSupplier',
  async (id: string, { dispatch }) => {
    try {
      return await api().vendorSupplyContractorsIdDelete({
        id,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while deleting supplier. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchSupplierApproval = createAsyncThunk(
  'supplier/patchSupplierApproval',
  async (payload: VendorSupplyContractorsIdPatchRequest, { dispatch }) => {
    try {
      return await api().vendorSupplyContractorsIdPatch(payload);
    } catch (error) {
      await handleThunkError(
        'An error occurred while patching vendor supply contractor. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchContracts = createAsyncThunk(
  'supplier/fetchContracts',
  async (_, { dispatch, getState }) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;

      return await api().vendorsVendorIdSupplyContractsGet({
        vendorId,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching contracts. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const postContract = createAsyncThunk(
  'supplier/postContract',
  async (
    payload: WithoutVendorId<VendorsVendorIdSupplyContractsPostRequest>,
    { dispatch, getState }
  ) => {
    try {
      const vendorId = (getState() as ApplicationState).vendors.currentVendor
        ?.id;
      return await api().vendorsVendorIdSupplyContractsPost({
        ...payload,
        ...{ vendorId },
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while posting contract. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const fetchContract = createAsyncThunk(
  'supplier/fetchContract',
  async (id: string, { dispatch }) => {
    try {
      return await api().supplyContractsIdGet({
        id,
      });
    } catch (error) {
      await handleThunkError(
        'An error occurred while fetching contract. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const deleteContract = createAsyncThunk(
  'supplier/deleteContract',
  async (id: string, { dispatch }) => {
    try {
      await api().supplyContractsIdDelete({
        id,
      });
      return id;
    } catch (error) {
      await handleThunkError(
        'An error occurred while deleting contract. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);

export const patchContract = createAsyncThunk(
  'supplier/patchContract',
  async (payload: SupplyContractsIdPatchRequest, { dispatch }) => {
    try {
      console.log('payload', payload);
      console.log('documentLinks', payload.supplyContractDocumentLinks);
      return await api().supplyContractsIdPatch(payload);
    } catch (error) {
      await handleThunkError(
        'An error occurred while patching contract. Please try again or contact support.',
        { dispatch, error }
      );
    }
  }
);
