/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedAttachmentModel,
  SharedAttachmentModelFromJSON,
  SharedAttachmentModelFromJSONTyped,
  SharedAttachmentModelToJSON,
  SupplyContractDocumentLink,
  SupplyContractDocumentLinkFromJSON,
  SupplyContractDocumentLinkFromJSONTyped,
  SupplyContractDocumentLinkToJSON,
  SupplyContractItemBringServiceInHouseComplexity,
  SupplyContractItemBringServiceInHouseComplexityFromJSON,
  SupplyContractItemBringServiceInHouseComplexityFromJSONTyped,
  SupplyContractItemBringServiceInHouseComplexityToJSON,
  SupplyContractItemDataSensitivity,
  SupplyContractItemDataSensitivityFromJSON,
  SupplyContractItemDataSensitivityFromJSONTyped,
  SupplyContractItemDataSensitivityToJSON,
  SupplyContractItemExtendedAllOf,
  SupplyContractItemExtendedAllOfFromJSON,
  SupplyContractItemExtendedAllOfFromJSONTyped,
  SupplyContractItemExtendedAllOfToJSON,
  SupplyContractItemImpactOfDiscontinuing,
  SupplyContractItemImpactOfDiscontinuingFromJSON,
  SupplyContractItemImpactOfDiscontinuingFromJSONTyped,
  SupplyContractItemImpactOfDiscontinuingToJSON,
  SupplyContractItemModel,
  SupplyContractItemModelFromJSON,
  SupplyContractItemModelFromJSONTyped,
  SupplyContractItemModelToJSON,
  SupplyContractItemPortability,
  SupplyContractItemPortabilityFromJSON,
  SupplyContractItemPortabilityFromJSONTyped,
  SupplyContractItemPortabilityToJSON,
  SupplyContractItemReliance,
  SupplyContractItemRelianceFromJSON,
  SupplyContractItemRelianceFromJSONTyped,
  SupplyContractItemRelianceToJSON,
  SupplyContractItemStatus,
  SupplyContractItemStatusFromJSON,
  SupplyContractItemStatusFromJSONTyped,
  SupplyContractItemStatusToJSON,
  SupplyContractItemSubstitutionChallenge,
  SupplyContractItemSubstitutionChallengeFromJSON,
  SupplyContractItemSubstitutionChallengeFromJSONTyped,
  SupplyContractItemSubstitutionChallengeToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface SupplyContractItemExtended
 */
export interface SupplyContractItemExtended extends SupplyContractItemModel {
  /**
   *
   * @type {SharedAttachmentModel}
   * @memberof SupplyContractItemExtended
   */
  sla: SharedAttachmentModel;
}

export function SupplyContractItemExtendedFromJSON(
  json: any
): SupplyContractItemExtended {
  return SupplyContractItemExtendedFromJSONTyped(json, false);
}

export function SupplyContractItemExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractItemExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...SupplyContractItemModelFromJSONTyped(json, ignoreDiscriminator),
    sla: SharedAttachmentModelFromJSON(json['sla']),
  };
}

export function SupplyContractItemExtendedToJSON(
  value?: SupplyContractItemExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...SupplyContractItemModelToJSON(value),
    sla: SharedAttachmentModelToJSON(value.sla),
  };
}
