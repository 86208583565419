import { useQueryParams } from '../../../../src/hooks/useQueryParams';

// How it works:

// If we invite supplier by email, we send the link r/createAccount?vendor_connection_id=wvVSAw
// where vendor_connection_id saved to localstorage and send to POST /vendor after signup

// Save to localstorage (not to state) because there is not connection_id in the second email
// ( confirmation email on the backend )

const cacheKey = 'connection';
const cacheStorage = window.localStorage;
const oneDayInMs = 24 * 60 * 60 * 1000;

interface ConnectionData {
  id: string;
  timestamp: number;
}

export const useConnectionId = () => {
  const params = useQueryParams();
  const paramsId = params.get('vendor_connection_id');
  const setConnectionId = () => {
    const connectionData: ConnectionData = {
      id: paramsId,
      timestamp: new Date().getTime(),
    };
    cacheStorage.setItem(cacheKey, JSON.stringify(connectionData));
  };
  return { setConnectionId };
};

export const getConnectionId = () => {
  const connectionData = cacheStorage.getItem(cacheKey);
  if (!connectionData) return null;
  const { id, timestamp }: ConnectionData = JSON.parse(connectionData);
  const currentTime = new Date().getTime();
  const expired = currentTime - timestamp > oneDayInMs;
  if (expired) {
    cleanupConnectionId();
    return null;
  }
  return id;
};

export const cleanupConnectionId = () => {
  cacheStorage.removeItem(cacheKey);
};
