/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BusinessFunctionModel
 */
export interface BusinessFunctionModel {
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  functionIdentifier: string;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  functionalTag?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  impactOfDiscontinuing?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof BusinessFunctionModel
   */
  critical?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  licencedActivity?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  licencedActivityName?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  reasonForImportance?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  reviewDate?: string | null;
  /**
   *
   * @type {number}
   * @memberof BusinessFunctionModel
   */
  rpoHours?: number | null;
  /**
   *
   * @type {number}
   * @memberof BusinessFunctionModel
   */
  rtoHours?: number | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionModel
   */
  updatedAt?: string;
}

export function BusinessFunctionModelFromJSON(
  json: any
): BusinessFunctionModel {
  return BusinessFunctionModelFromJSONTyped(json, false);
}

export function BusinessFunctionModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessFunctionModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    functionIdentifier: json['function_identifier'],
    description: !exists(json, 'description') ? undefined : json['description'],
    functionalTag: !exists(json, 'functional_tag')
      ? undefined
      : json['functional_tag'],
    impactOfDiscontinuing: !exists(json, 'impact_of_discontinuing')
      ? undefined
      : json['impact_of_discontinuing'],
    critical: !exists(json, 'critical') ? undefined : json['critical'],
    licencedActivity: !exists(json, 'licenced_activity')
      ? undefined
      : json['licenced_activity'],
    licencedActivityName: !exists(json, 'licenced_activity_name')
      ? undefined
      : json['licenced_activity_name'],
    reasonForImportance: !exists(json, 'reason_for_importance')
      ? undefined
      : json['reason_for_importance'],
    reviewDate: !exists(json, 'review_date') ? undefined : json['review_date'],
    rpoHours: !exists(json, 'rpo_hours') ? undefined : json['rpo_hours'],
    rtoHours: !exists(json, 'rto_hours') ? undefined : json['rto_hours'],
    createdAt: !exists(json, 'created_at') ? undefined : json['created_at'],
    updatedAt: !exists(json, 'updated_at') ? undefined : json['updated_at'],
  };
}

export function BusinessFunctionModelToJSON(
  value?: BusinessFunctionModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    function_identifier: value.functionIdentifier,
    description: value.description,
    functional_tag: value.functionalTag,
    impact_of_discontinuing: value.impactOfDiscontinuing,
    critical: value.critical,
    licenced_activity: value.licencedActivity,
    licenced_activity_name: value.licencedActivityName,
    reason_for_importance: value.reasonForImportance,
    review_date: value.reviewDate,
    rpo_hours: value.rpoHours,
    rto_hours: value.rtoHours,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
