/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Access, AccessFromJSON, AccessFromJSONTyped, AccessToJSON } from './';

/**
 *
 * @export
 * @interface SupplyContractorModel
 */
export interface SupplyContractorModel {
  /**
   *
   * @type {Access}
   * @memberof SupplyContractorModel
   */
  access?: Access;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  identifier: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  tradeName: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  countryOfIncorporation: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  countryOfIncorporationName: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  registeredAddress: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  headOfficeAddress: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  businessSummary: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  businessType: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  businessTypeName: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  companyIdentifier: string | null;
  /**
   *
   * @type {Date}
   * @memberof SupplyContractorModel
   */
  dateOfIncorporation: Date | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  legalEntityIdentifier: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  currencyCode?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  financialEntityType?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  financialEntityName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  competentAuthority?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  competentAuthorityName?: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  parentCompanyName: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  parentCompanyLegalEntityIdentifier: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  vendorId: string | null;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof SupplyContractorModel
   */
  updatedAt: string;
}

export function SupplyContractorModelFromJSON(
  json: any
): SupplyContractorModel {
  return SupplyContractorModelFromJSONTyped(json, false);
}

export function SupplyContractorModelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractorModel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    access: !exists(json, 'access')
      ? undefined
      : AccessFromJSON(json['access']),
    id: json['id'],
    identifier: json['identifier'],
    name: json['name'],
    type: json['type'],
    tradeName: json['trade_name'],
    countryOfIncorporation: json['country_of_incorporation'],
    countryOfIncorporationName: json['country_of_incorporation_name'],
    registeredAddress: json['registered_address'],
    headOfficeAddress: json['head_office_address'],
    businessSummary: json['business_summary'],
    businessType: json['business_type'],
    businessTypeName: json['business_type_name'],
    companyIdentifier: json['company_identifier'],
    dateOfIncorporation:
      json['date_of_incorporation'] === null
        ? null
        : new Date(json['date_of_incorporation']),
    legalEntityIdentifier: json['legal_entity_identifier'],
    currencyCode: !exists(json, 'currency_code')
      ? undefined
      : json['currency_code'],
    financialEntityType: !exists(json, 'financial_entity_type')
      ? undefined
      : json['financial_entity_type'],
    financialEntityName: !exists(json, 'financial_entity_name')
      ? undefined
      : json['financial_entity_name'],
    competentAuthority: !exists(json, 'competent_authority')
      ? undefined
      : json['competent_authority'],
    competentAuthorityName: !exists(json, 'competent_authority_name')
      ? undefined
      : json['competent_authority_name'],
    parentCompanyName: json['parent_company_name'],
    parentCompanyLegalEntityIdentifier:
      json['parent_company_legal_entity_identifier'],
    vendorId: json['vendor_id'],
    createdAt: json['created_at'],
    updatedAt: json['updated_at'],
  };
}

export function SupplyContractorModelToJSON(
  value?: SupplyContractorModel | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access: AccessToJSON(value.access),
    id: value.id,
    identifier: value.identifier,
    name: value.name,
    type: value.type,
    trade_name: value.tradeName,
    country_of_incorporation: value.countryOfIncorporation,
    country_of_incorporation_name: value.countryOfIncorporationName,
    registered_address: value.registeredAddress,
    head_office_address: value.headOfficeAddress,
    business_summary: value.businessSummary,
    business_type: value.businessType,
    business_type_name: value.businessTypeName,
    company_identifier: value.companyIdentifier,
    date_of_incorporation:
      value.dateOfIncorporation === null
        ? null
        : value.dateOfIncorporation.toISOString().substr(0, 10),
    legal_entity_identifier: value.legalEntityIdentifier,
    currency_code: value.currencyCode,
    financial_entity_type: value.financialEntityType,
    financial_entity_name: value.financialEntityName,
    competent_authority: value.competentAuthority,
    competent_authority_name: value.competentAuthorityName,
    parent_company_name: value.parentCompanyName,
    parent_company_legal_entity_identifier:
      value.parentCompanyLegalEntityIdentifier,
    vendor_id: value.vendorId,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
  };
}
