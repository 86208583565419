/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SupplyContractItemImpactOfDiscontinuing {
  LowOrMedium = 'low_or_medium',
  High = 'high',
  AssessmentNotPerformed = 'assessment_not_performed',
}

export function SupplyContractItemImpactOfDiscontinuingFromJSON(
  json: any
): SupplyContractItemImpactOfDiscontinuing {
  return SupplyContractItemImpactOfDiscontinuingFromJSONTyped(json, false);
}

export function SupplyContractItemImpactOfDiscontinuingFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractItemImpactOfDiscontinuing {
  return json as SupplyContractItemImpactOfDiscontinuing;
}

export function SupplyContractItemImpactOfDiscontinuingToJSON(
  value?: SupplyContractItemImpactOfDiscontinuing | null
): any {
  return value as any;
}
