/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SharedAttachmentModel,
  SharedAttachmentModelFromJSON,
  SharedAttachmentModelFromJSONTyped,
  SharedAttachmentModelToJSON,
  SupplyContractContractType,
  SupplyContractContractTypeFromJSON,
  SupplyContractContractTypeFromJSONTyped,
  SupplyContractContractTypeToJSON,
  SupplyContractDocumentLink,
  SupplyContractDocumentLinkFromJSON,
  SupplyContractDocumentLinkFromJSONTyped,
  SupplyContractDocumentLinkToJSON,
  SupplyContractExtendedAllOf,
  SupplyContractExtendedAllOfFromJSON,
  SupplyContractExtendedAllOfFromJSONTyped,
  SupplyContractExtendedAllOfToJSON,
  SupplyContractItemModel,
  SupplyContractItemModelFromJSON,
  SupplyContractItemModelFromJSONTyped,
  SupplyContractItemModelToJSON,
  SupplyContractModel,
  SupplyContractModelFromJSON,
  SupplyContractModelFromJSONTyped,
  SupplyContractModelToJSON,
  SupplyContractStatus,
  SupplyContractStatusFromJSON,
  SupplyContractStatusFromJSONTyped,
  SupplyContractStatusToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface SupplyContractExtended
 */
export interface SupplyContractExtended extends SupplyContractModel {
  /**
   *
   * @type {SharedAttachmentModel}
   * @memberof SupplyContractExtended
   */
  document: SharedAttachmentModel;
  /**
   *
   * @type {Array<SupplyContractItemModel>}
   * @memberof SupplyContractExtended
   */
  supplyContractItems?: Array<SupplyContractItemModel>;
}

export function SupplyContractExtendedFromJSON(
  json: any
): SupplyContractExtended {
  return SupplyContractExtendedFromJSONTyped(json, false);
}

export function SupplyContractExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...SupplyContractModelFromJSONTyped(json, ignoreDiscriminator),
    document: SharedAttachmentModelFromJSON(json['document']),
    supplyContractItems: !exists(json, 'supply_contract_items')
      ? undefined
      : (json['supply_contract_items'] as Array<any>).map(
          SupplyContractItemModelFromJSON
        ),
  };
}

export function SupplyContractExtendedToJSON(
  value?: SupplyContractExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...SupplyContractModelToJSON(value),
    document: SharedAttachmentModelToJSON(value.document),
    supply_contract_items:
      value.supplyContractItems === undefined
        ? undefined
        : (value.supplyContractItems as Array<any>).map(
            SupplyContractItemModelToJSON
          ),
  };
}
