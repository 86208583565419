import React, { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { deleteSupplier, patchSupplier } from '../store/supplierThunks';
import {
  SupplyContractorsIdPatchRequest,
  VendorSupplyContractorExtended,
} from '../../../swagger';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import './EditSupplierDrawer.scss';
import { AdoptechCountrySelect } from '../../../components/AdoptechCountrySelect/AdoptechCountrySelect';
import { AdoptechDatePicker2 } from '../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import { AdoptechBusinessTypeSelect } from '../../companyProfile/AdoptechBusinessTypeSelect/AdoptechBusinessTypeSelect';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { leiError } from '../../companyProfile/CompanyInformation/CompanyInformation';
import { AdoptechTextArea2 } from '../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { supplierPageRoute } from '../../../components/Routes/Routes';
import moment from 'moment';
import { useFormChangedFields } from '../../../hooks/useFormChangedFields';

interface EditSupplierDrawerProps {
  onClose(): void;
  supplierContractorId: string;
  supplierId: string; // used for deletion
  supplierDetails: VendorSupplyContractorExtended;
}

type Contractor = VendorSupplyContractorExtended['supplyContractor'];

interface SupplierForm {
  name: Contractor['name'];
  tradeName: Contractor['tradeName'];
  countryOfIncorporation: Contractor['countryOfIncorporation'];
  dateOfIncorporation: Contractor['dateOfIncorporation'];
  businessType: Contractor['businessType'];
  companyIdentifier: Contractor['companyIdentifier'];
  legalEntityIdentifier: Contractor['legalEntityIdentifier'];
  registeredAddress: Contractor['registeredAddress'];
  businessSummary: Contractor['businessSummary'];
}

export const EditSupplierDrawer: React.FC<EditSupplierDrawerProps> = props => {
  const isPatching = useSelector(
    (state: ApplicationState) =>
      state.supplier.patchSupplierStatus === 'loading'
  );
  const isDeleting = useSelector(
    (state: ApplicationState) =>
      state.supplier.deleteSupplierStatus === 'loading'
  );
  const dispatch = useAppDispatch();

  const initialFormValues: SupplierForm = {
    name: null,
    tradeName: null,
    countryOfIncorporation: null,
    dateOfIncorporation: null,
    businessType: null,
    companyIdentifier: null,
    legalEntityIdentifier: null,
    registeredAddress: null,
    businessSummary: null,
  };

  const {
    form,
    setForm,
    getChangedFieldsWithValues,
    getChangedFields,
    resetForm,
  } = useFormChangedFields(initialFormValues);

  useEffect(() => {
    if (!props.supplierContractorId) return;
    const fetchDetails = async () => {
      const { supplyContractor } = props.supplierDetails;
      const formPayload: SupplierForm = {
        name: supplyContractor.name,
        tradeName: supplyContractor.tradeName,
        countryOfIncorporation: supplyContractor.countryOfIncorporation,
        dateOfIncorporation: supplyContractor.dateOfIncorporation,
        businessType: supplyContractor.businessType,
        companyIdentifier: supplyContractor.companyIdentifier,
        legalEntityIdentifier: supplyContractor.legalEntityIdentifier,
        registeredAddress: supplyContractor.registeredAddress,
        businessSummary: supplyContractor.businessSummary,
      };
      resetForm(formPayload);
    };
    fetchDetails();
  }, [props.supplierContractorId]);

  const handleDoneClick = async () => {
    const changedFields = getChangedFields();
    if (changedFields.length === 0) {
      props.onClose();
      return;
    }

    const changedFieldsWithValues = getChangedFieldsWithValues();
    const body: SupplyContractorsIdPatchRequest = {
      id: props.supplierContractorId,
      body: {
        supplyContractor: {
          ...changedFieldsWithValues,
          ...(changedFieldsWithValues.dateOfIncorporation && {
            dateOfIncorporation:
              changedFieldsWithValues.dateOfIncorporation.toISOString(),
          }),
        },
      },
    };
    const result = await dispatch(patchSupplier(body));
    if (result.meta.requestStatus === 'rejected') return;
    props.onClose();
  };

  const hasLeiError = leiError(form.legalEntityIdentifier);
  const hasNameError = (form.name || '').length === 0;

  return (
    <AdoptechHS
      title="Edit profile"
      onClose={props.onClose}
      showConfirmationWarning={false}
      theme="medium"
      show
      footer={
        <>
          <AdoptechButton
            onClick={props.onClose}
            variant={AdoptechButtonVariant.White}
            disabled={isPatching || isDeleting}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={handleDoneClick}
            variant={AdoptechButtonVariant.Primary}
            busy={isPatching}
            disabled={hasLeiError || hasNameError || isDeleting}
          >
            Update
          </AdoptechButton>
        </>
      }
    >
      <EditSupplierForm
        form={form}
        setForm={setForm}
        supplierId={props.supplierId}
        onClose={props.onClose}
        isPatching={isPatching}
        isDeleting={isDeleting}
      />
    </AdoptechHS>
  );
};

const EditSupplierForm: React.FC<{
  form: SupplierForm;
  supplierId: string;
  setForm: (form: SupplierForm) => void;
  onClose: () => void;
  isPatching: boolean;
  isDeleting: boolean;
}> = props => {
  const baseCss = 'editSupplierForm';
  const dispatch = useAppDispatch();
  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this supplier?'))
      return;
    const result = await dispatch(deleteSupplier(props.supplierId));
    if (result.meta.requestStatus === 'rejected') return;
    props.onClose();
    window.location.href = supplierPageRoute;
  };

  const handleChange = (field: keyof SupplierForm, value: any) => {
    props.setForm({ ...props.form, [field]: value });
  };

  const hasLeiError = leiError(props.form.legalEntityIdentifier);
  const hasNameError = (props.form.name || '').length === 0;

  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>Supplier information</div>
      <div className={baseCss + '--fields'}>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id="legalName"
            type="text"
            label="Legal name"
            value={props.form.name}
            onChange={e => handleChange('name', e.target.value)}
            hasError={hasNameError}
          />
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id="tradingName"
            type="text"
            label="Trading name (used in company policies)"
            value={props.form.tradeName}
            onChange={e => handleChange('tradeName', e.target.value)}
          />
        </div>

        <div className={baseCss + '--field'}>
          <AdoptechCountrySelect
            theme="new"
            label="Country of Incorporation"
            value={props.form.countryOfIncorporation}
            onChange={e => handleChange('countryOfIncorporation', e.value)}
          />
        </div>

        <div className={baseCss + '--field'}>
          <AdoptechDatePicker2
            deadLines={[]}
            disabled={false}
            labelText="Date of Incorporation"
            minDate={null}
            popperPlacement="bottom-start"
            outputDate={
              props.form.dateOfIncorporation
                ? new Date(props.form.dateOfIncorporation)
                : null
            }
            onDateSelect={date => {
              const endOfDay = moment(date).endOf('day');
              const endOfDayToDateTime = endOfDay.toDate();
              handleChange('dateOfIncorporation', endOfDayToDateTime);
            }}
          />
        </div>

        <div className={baseCss + '--field'}>
          <AdoptechBusinessTypeSelect
            theme="new"
            onChange={option => handleChange('businessType', option.value)}
            value={props.form.businessType}
            label="Business Type"
          />
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id="companyIdentifier"
            type="text"
            label="Company Identifier"
            value={props.form.companyIdentifier}
            onChange={e => handleChange('companyIdentifier', e.target.value)}
          />
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id="legalEntityIdentifier"
            type="text"
            label="Legal Entity Identifier(LEI)"
            hasError={hasLeiError}
            value={props.form.legalEntityIdentifier}
            onChange={e =>
              handleChange('legalEntityIdentifier', e.target.value)
            }
          />
        </div>

        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            id="registeredAddress"
            type="text"
            label="Registered Address"
            value={props.form.registeredAddress}
            onChange={e => handleChange('registeredAddress', e.target.value)}
          />
        </div>

        <div className={baseCss + '--field'}>
          <AdoptechTextArea2
            id="businessSummary"
            label="Business Summary"
            value={props.form.businessSummary}
            onChange={e => handleChange('businessSummary', e.target.value)}
          />
        </div>

        <div className={baseCss + '--field'}>
          <AdoptechButton
            onClick={handleDelete}
            variant={AdoptechButtonVariant.Warning}
            disabled={props.isPatching}
            busy={props.isDeleting}
          >
            Delete
          </AdoptechButton>
        </div>
      </div>
    </div>
  );
};
