/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorSupplyContractorUpdatePayloadVendorSupplyContractor,
  VendorSupplyContractorUpdatePayloadVendorSupplyContractorFromJSON,
  VendorSupplyContractorUpdatePayloadVendorSupplyContractorFromJSONTyped,
  VendorSupplyContractorUpdatePayloadVendorSupplyContractorToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorSupplyContractorUpdatePayload
 */
export interface VendorSupplyContractorUpdatePayload {
  /**
   *
   * @type {VendorSupplyContractorUpdatePayloadVendorSupplyContractor}
   * @memberof VendorSupplyContractorUpdatePayload
   */
  vendorSupplyContractor: VendorSupplyContractorUpdatePayloadVendorSupplyContractor;
}

export function VendorSupplyContractorUpdatePayloadFromJSON(
  json: any
): VendorSupplyContractorUpdatePayload {
  return VendorSupplyContractorUpdatePayloadFromJSONTyped(json, false);
}

export function VendorSupplyContractorUpdatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplyContractorUpdatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorSupplyContractor:
      VendorSupplyContractorUpdatePayloadVendorSupplyContractorFromJSON(
        json['vendor_supply_contractor']
      ),
  };
}

export function VendorSupplyContractorUpdatePayloadToJSON(
  value?: VendorSupplyContractorUpdatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_supply_contractor:
      VendorSupplyContractorUpdatePayloadVendorSupplyContractorToJSON(
        value.vendorSupplyContractor
      ),
  };
}
