import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyProfileState } from './companyProfileState';
import { CertificateModel } from '../../../swagger/models/CertificateModel';
import {
  deleteBusinessFunction,
  fetchBusinessFunction,
  fetchBusinessFunctions,
  fetchCompanyConfig,
  fetchVendorTree,
  patchBusinessFunction,
  postBusinessFunction,
} from './companyProfileThunks';

const companyProfileSlice = createSlice({
  initialState: {
    // company profile page
    certificates: [],
    fetchingCertificatesStatus: 'idle',
    deletingCertificateStatus: 'idle',
    creatingCertificateStatus: 'idle',
    // preview page
    currentCertificate: null,
    fetchingCertificateStatus: 'idle',
    fetchingCertificateDocumentStatus: 'idle',
    fetchingCompanyConfigStatus: 'idle',
    fetchVendorTreeStatus: 'idle',
    // business functions tab
    fetchBusinessFunctionsStatus: 'idle',
    businessFunctions: [],
    postBusinessFunctionStatus: 'idle',
    patchBusinessFunctionStatus: 'idle',
    deleteBusinessFunctionStatus: 'idle',
    businessFunction: null,
  } as unknown as CompanyProfileState,
  name: 'companyProfileSlice',
  reducers: {
    fetchCertificatesRequest: state => {
      state.fetchingCertificatesStatus = 'idle';
    },
    fetchCertificatesSuccess: (
      state,
      action: PayloadAction<CertificateModel[]>
    ) => {
      state.certificates = action.payload;
      state.fetchingCertificatesStatus = 'succeeded';
    },
    fetchCertificatesFailure: (state, action: PayloadAction<string>) => {
      state.fetchingCertificatesStatus = 'failed';
    },
    deleteCertificateRequest: state => {
      state.deletingCertificateStatus = 'loading';
    },
    deleteCertificateSuccess: (
      state,
      action: PayloadAction<CertificateModel['id']>
    ) => {
      state.certificates = state.certificates.filter(
        certificate => certificate.id !== action.payload
      );
      state.deletingCertificateStatus = 'succeeded';
    },
    deleteCertificateFailure: (state, action: PayloadAction<string>) => {
      state.deletingCertificateStatus = 'failed';
    },
    createCertificateRequest: state => {
      state.creatingCertificateStatus = 'loading';
    },
    createCertificateSuccess: (
      state,
      action: PayloadAction<CertificateModel>
    ) => {
      let existing = false;
      state.certificates = state.certificates.map(certificate => {
        if (certificate.id === action.payload.id) {
          existing = true;
          return action.payload;
        }
        return certificate;
      });

      if (!existing) {
        state.certificates.push(action.payload);
      }

      state.creatingCertificateStatus = 'succeeded';
    },

    createCertificateFailure: (state, action: PayloadAction<string>) => {
      state.deletingCertificateStatus = 'failed';
    },

    fetchCertificateRequest: state => {
      state.fetchingCertificateStatus = 'loading';
    },
    fetchCertificateSuccess: (
      state,
      action: PayloadAction<CertificateModel>
    ) => {
      state.currentCertificate = action.payload;
      state.fetchingCertificateStatus = 'succeeded';
    },
    fetchCertificateFailure: (state, action: PayloadAction<string>) => {
      state.fetchingCertificateStatus = 'failed';
    },
    cleanCurrentCertificate: state => {
      state.currentCertificate = null;
    },

    fetchCertificateDocumentRequest: state => {
      state.fetchingCertificateDocumentStatus = 'loading';
    },
    fetchCertificateDocumentSuccess: state => {
      state.fetchingCertificateDocumentStatus = 'succeeded';
    },
    fetchCertificateDocumentFailure: (state, action: PayloadAction<string>) => {
      state.fetchingCertificateDocumentStatus = 'failed';
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCompanyConfig.pending, state => {
        state.fetchingCompanyConfigStatus = 'loading';
      })
      .addCase(fetchCompanyConfig.fulfilled, state => {
        state.fetchingCompanyConfigStatus = 'succeeded';
      })
      .addCase(fetchCompanyConfig.rejected, state => {
        state.fetchingCompanyConfigStatus = 'failed';
      })
      .addCase(fetchVendorTree.pending, state => {
        state.fetchVendorTreeStatus = 'loading';
      })
      .addCase(fetchVendorTree.fulfilled, state => {
        state.fetchVendorTreeStatus = 'succeeded';
      })
      .addCase(fetchVendorTree.rejected, state => {
        state.fetchVendorTreeStatus = 'failed';
      })
      .addCase(fetchBusinessFunctions.pending, state => {
        state.fetchBusinessFunctionsStatus = 'loading';
      })
      .addCase(fetchBusinessFunctions.fulfilled, (state, action) => {
        state.fetchBusinessFunctionsStatus = 'succeeded';
        state.businessFunctions = action.payload;
      })
      .addCase(fetchBusinessFunctions.rejected, state => {
        state.fetchBusinessFunctionsStatus = 'failed';
      })
      .addCase(fetchBusinessFunction.pending, state => {
        state.fetchBusinessFunctionStatus = 'loading';
      })
      .addCase(fetchBusinessFunction.fulfilled, (state, action) => {
        state.fetchBusinessFunctionStatus = 'succeeded';
        state.businessFunction = action.payload;
      })
      .addCase(fetchBusinessFunction.rejected, state => {
        state.fetchBusinessFunctionStatus = 'failed';
      })
      .addCase(postBusinessFunction.pending, state => {
        state.postBusinessFunctionStatus = 'loading';
      })
      .addCase(postBusinessFunction.fulfilled, (state, action) => {
        state.postBusinessFunctionStatus = 'succeeded';
        state.businessFunctions.push(action.payload);
      })
      .addCase(postBusinessFunction.rejected, state => {
        state.postBusinessFunctionStatus = 'failed';
      })
      .addCase(patchBusinessFunction.pending, state => {
        state.patchBusinessFunctionStatus = 'loading';
      })
      .addCase(patchBusinessFunction.fulfilled, (state, action) => {
        state.patchBusinessFunctionStatus = 'succeeded';
        state.businessFunctions = state.businessFunctions.map(
          businessFunction =>
            businessFunction.id === action.payload.id
              ? action.payload
              : businessFunction
        );
      })
      .addCase(patchBusinessFunction.rejected, state => {
        state.patchBusinessFunctionStatus = 'failed';
      })
      .addCase(deleteBusinessFunction.pending, state => {
        state.deleteBusinessFunctionStatus = 'loading';
      })
      .addCase(deleteBusinessFunction.fulfilled, (state, action) => {
        state.deleteBusinessFunctionStatus = 'succeeded';
        state.businessFunctions = state.businessFunctions.filter(
          businessFunction => businessFunction.id !== action.payload
        );
      })
      .addCase(deleteBusinessFunction.rejected, state => {
        state.deleteBusinessFunctionStatus = 'failed';
      });
  },
});

export const {
  fetchCertificatesRequest,
  fetchCertificatesSuccess,
  fetchCertificatesFailure,
  deleteCertificateRequest,
  deleteCertificateSuccess,
  deleteCertificateFailure,
  createCertificateRequest,
  createCertificateSuccess,
  createCertificateFailure,
  fetchCertificateRequest,
  fetchCertificateSuccess,
  fetchCertificateFailure,
  cleanCurrentCertificate,
  fetchCertificateDocumentRequest,
  fetchCertificateDocumentSuccess,
  fetchCertificateDocumentFailure,
} = companyProfileSlice.actions;

export default companyProfileSlice.reducer;
