import React from 'react';
import AdoptechModal from '../../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../../components/AdoptechButton/AdoptechButton';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { AddSupplierForm } from '../AddSupplierModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeCircle,
  faUserCircle,
} from '@fortawesome/pro-solid-svg-icons';
import './AddSupplierManualScreen.scss';
import classNames from 'classnames';
import { SearchScreenProps } from '../AddSupplierSearchScreen';

export const AddSupplierManualScreen: React.FC<SearchScreenProps> = ({
  form,
  onHide,
  handleChange,
}) => {
  const modalTitle = 'Add supplier';
  const description =
    'You can add a supplier by sending them an invitation or set them up yourself.';
  const baseCss = 'addSupplierManualScreen';
  const dispatch = useAppDispatch();
  const [mode, setMode] = React.useState<'invite' | 'fill-details'>(null);
  return (
    <AdoptechModal className={baseCss} onHide={onHide} rounded show>
      <Modal.Header>
        <div>{modalTitle}</div>
      </Modal.Header>
      <Modal.Body>
        <div className={baseCss + '--body'}>
          <div className={baseCss + '--description'}>{description}</div>
          <div className={baseCss + '--fieldRow'}>
            <div className={baseCss + '--modes'}>
              <div
                className={classNames([
                  baseCss + '--mode',
                  {
                    active: mode === 'invite',
                  },
                ])}
                onClick={() => setMode('invite')}
              >
                <div className={baseCss + '--modeIcon'}>
                  <FontAwesomeIcon icon={faEnvelopeCircle} />
                </div>
                <div className={baseCss + '--modeDescription'}>
                  <div className={baseCss + '--modeTitle'}>Invite supplier</div>
                  <div className={baseCss + '--modeText'}>
                    Send an invitation for the supplier to create a profile and
                    share real-time security and compliance information with you
                    and other clients
                  </div>
                </div>
              </div>
              <div
                className={classNames([
                  baseCss + '--mode',
                  {
                    active: mode === 'fill-details',
                  },
                ])}
                onClick={() => setMode('fill-details')}
              >
                <div className={baseCss + '--modeIcon'}>
                  <FontAwesomeIcon icon={faUserCircle} />
                </div>
                <div className={baseCss + '--modeDescription'}>
                  <div className={baseCss + '--modeTitle'}>Add supplier</div>
                  <div className={baseCss + '--modeText'}>
                    If you wish to manually add and maintain the supplier
                    information yourself.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onHide}>Cancel</AdoptechButton>
        <AdoptechButton
          disabled={!mode}
          busy={false}
          onClick={() => {
            if (mode === 'invite') {
              handleChange({ currentScreen: 'invite-form' });
            } else {
              handleChange({ currentScreen: 'manual-details' });
            }
          }}
          variant={AdoptechButtonVariant.PrimaryOrange}
          rounded
        >
          Confirm
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
