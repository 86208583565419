/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorSupplyContractorCreatePayloadVendorSupplyContractor,
  VendorSupplyContractorCreatePayloadVendorSupplyContractorFromJSON,
  VendorSupplyContractorCreatePayloadVendorSupplyContractorFromJSONTyped,
  VendorSupplyContractorCreatePayloadVendorSupplyContractorToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorSupplyContractorCreatePayload
 */
export interface VendorSupplyContractorCreatePayload {
  /**
   *
   * @type {VendorSupplyContractorCreatePayloadVendorSupplyContractor}
   * @memberof VendorSupplyContractorCreatePayload
   */
  vendorSupplyContractor: VendorSupplyContractorCreatePayloadVendorSupplyContractor;
}

export function VendorSupplyContractorCreatePayloadFromJSON(
  json: any
): VendorSupplyContractorCreatePayload {
  return VendorSupplyContractorCreatePayloadFromJSONTyped(json, false);
}

export function VendorSupplyContractorCreatePayloadFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplyContractorCreatePayload {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    vendorSupplyContractor:
      VendorSupplyContractorCreatePayloadVendorSupplyContractorFromJSON(
        json['vendor_supply_contractor']
      ),
  };
}

export function VendorSupplyContractorCreatePayloadToJSON(
  value?: VendorSupplyContractorCreatePayload | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    vendor_supply_contractor:
      VendorSupplyContractorCreatePayloadVendorSupplyContractorToJSON(
        value.vendorSupplyContractor
      ),
  };
}
