import { useSelector } from 'react-redux';
import { AccessObject } from '../types/accessObject';
import { ApplicationState } from '../types/applicationState';
import { Access, ShareAccessLevel } from '../swagger';

type AccessObjectKeys = keyof typeof AccessObject;
const accessMapping: { readonly [key in AccessObjectKeys]+?: any } = {
  [AccessObject.assessments]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.payment_plan_vendors]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.manage_approvals]: (state: ApplicationState) =>
    state.vendors.documentState.policyDocuments?.[0],
  [AccessObject.manage_read_requests]: (state: ApplicationState) =>
    state.vendors.documentState.policyDocuments?.[0],
  [AccessObject.manage_versions]: (state: ApplicationState) =>
    state.vendors.documentState.policyDocuments?.[0],
  [AccessObject.vendor_users]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendor_teams]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendor_documents]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendor_details]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendor_dashboard]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendor_checklists]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.checklist_templates]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.company_identifiers]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendor_policies]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.companies]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.agreement_templates]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.agreements]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.data_room_entities]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.company_documents]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.people]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.legal_register]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendor_register]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.pestel]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.escrow]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.policy_categories]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.audits]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.risk_register]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.people_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.user_dashboard]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.company_dashboard]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.frameworks]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.controls]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.tasks]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.frameworks_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.controls_create]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.controls_update]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.tasks_create]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.tasks_update]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.calendar]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.company_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.checklists]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.checklists_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.agreements_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.legal_register_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendor_register_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.audits_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.risk_register_create]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.risk_register_update]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.calendar_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.pestel_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.reports]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.reports_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.integrations]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.dataroom_root_tree_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.dataroom_control_evidence_manage]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.company_certificates]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.clients]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.trusthub]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.dashboard]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.vendors_create]: (state: ApplicationState) =>
    state.vendors.currentVendor,
  [AccessObject.supply_contractors]: (state: ApplicationState) =>
    state.vendors.currentVendor,
};
/**
 * @deprecated use `useCanCreate` instead
 */
export const canCreate = (
  accessObject: AccessObject,
  state?: ApplicationState
) => {
  const selector = accessMapping[accessObject];
  // TODO: fix this
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const parentObject = state ? selector(state) : useSelector(selector);

  return Boolean(parentObject?.access?.create?.includes(accessObject));
};
export const useCanFeature = (accessObject: AccessObject) => {
  const selector = accessMapping[accessObject];
  const parentObject = useSelector(selector) as {
    access?: { feature?: AccessObject[] };
  };
  return Boolean(parentObject?.access?.feature?.includes(accessObject));
};

/**
 * @deprecated use `useHasFeature` instead
 */

export const hasFeature = (
  accessObject: AccessObject,
  access: Access
): boolean => {
  return Boolean(
    access && access.feature && access.feature.indexOf(accessObject) !== -1
  );
};

/**
 * @deprecated use `useCanIndex` instead
 */

export const canIndex = (
  accessObject: AccessObject,
  state?: ApplicationState
) => {
  const selector = accessMapping[accessObject];
  // TODO: fix this
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const parentObject = state ? selector(state) : useSelector(selector);
  return Boolean(parentObject?.access?.index?.includes(accessObject));
};

/**
 * @deprecated use `useCanView` instead
 */

export const canView = (
  accessObject: AccessObject,
  state?: ApplicationState
) => {
  const selector = accessMapping[accessObject];
  // TODO: fix this
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const parentObject = state ? selector(state) : useSelector(selector);

  return Boolean(parentObject?.access?.index?.includes(accessObject));
};

export const havePermissions = (
  accessObject: AccessObject,
  state?: ApplicationState
) => {
  return false;
};

// TODO rename all hooks in this file with use prefix
// because additionalHooks for eslint rule doesn't work
// https://github.com/facebook/react/blob/main/packages/eslint-plugin-react-hooks/README.md
export const useCanCreate = canCreate;
export const useCanIndex = canIndex;
export const useCanView = canView;
export const useHasFeature = hasFeature;

const shareFeaturesAndAccessLevels: { [key: string]: ShareAccessLevel } = {
  share__manager: ShareAccessLevel.Manager,
  share__contributor: ShareAccessLevel.Contributor,
  share__viewer: ShareAccessLevel.Viewer,
};

export const mapShareFeaturesToAccessLevels = (
  shareAccessFeatures: string[]
): ShareAccessLevel[] => {
  if (!shareAccessFeatures) {
    return [];
  }
  return (
    shareAccessFeatures
      .map((feature: string) => shareFeaturesAndAccessLevels[feature])
      .filter((feature: string) => !!feature) || []
  );
};
