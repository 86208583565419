/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SupplyContractItemReliance {
  Insignificant = 'insignificant',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export function SupplyContractItemRelianceFromJSON(
  json: any
): SupplyContractItemReliance {
  return SupplyContractItemRelianceFromJSONTyped(json, false);
}

export function SupplyContractItemRelianceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractItemReliance {
  return json as SupplyContractItemReliance;
}

export function SupplyContractItemRelianceToJSON(
  value?: SupplyContractItemReliance | null
): any {
  return value as any;
}
