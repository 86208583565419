/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorSupplyContractorStatus,
  VendorSupplyContractorStatusFromJSON,
  VendorSupplyContractorStatusFromJSONTyped,
  VendorSupplyContractorStatusToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorSupplyContractorUpdatePayloadVendorSupplyContractor
 */
export interface VendorSupplyContractorUpdatePayloadVendorSupplyContractor {
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorUpdatePayloadVendorSupplyContractor
   */
  ownerId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorUpdatePayloadVendorSupplyContractor
   */
  additionalNotes?: string;
  /**
   *
   * @type {VendorSupplyContractorStatus}
   * @memberof VendorSupplyContractorUpdatePayloadVendorSupplyContractor
   */
  status?: VendorSupplyContractorStatus;
}

export function VendorSupplyContractorUpdatePayloadVendorSupplyContractorFromJSON(
  json: any
): VendorSupplyContractorUpdatePayloadVendorSupplyContractor {
  return VendorSupplyContractorUpdatePayloadVendorSupplyContractorFromJSONTyped(
    json,
    false
  );
}

export function VendorSupplyContractorUpdatePayloadVendorSupplyContractorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplyContractorUpdatePayloadVendorSupplyContractor {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
    additionalNotes: !exists(json, 'additional_notes')
      ? undefined
      : json['additional_notes'],
    status: !exists(json, 'status')
      ? undefined
      : VendorSupplyContractorStatusFromJSON(json['status']),
  };
}

export function VendorSupplyContractorUpdatePayloadVendorSupplyContractorToJSON(
  value?: VendorSupplyContractorUpdatePayloadVendorSupplyContractor | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    owner_id: value.ownerId,
    additional_notes: value.additionalNotes,
    status: VendorSupplyContractorStatusToJSON(value.status),
  };
}
