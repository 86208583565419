/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SupplyContractItemDataSensitivity {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export function SupplyContractItemDataSensitivityFromJSON(
  json: any
): SupplyContractItemDataSensitivity {
  return SupplyContractItemDataSensitivityFromJSONTyped(json, false);
}

export function SupplyContractItemDataSensitivityFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractItemDataSensitivity {
  return json as SupplyContractItemDataSensitivity;
}

export function SupplyContractItemDataSensitivityToJSON(
  value?: SupplyContractItemDataSensitivity | null
): any {
  return value as any;
}
