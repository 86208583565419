/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Access,
  AccessFromJSON,
  AccessFromJSONTyped,
  AccessToJSON,
  SupplyContractorModel,
  SupplyContractorModelFromJSON,
  SupplyContractorModelFromJSONTyped,
  SupplyContractorModelToJSON,
  SupplyContractorSearchItemAllOf,
  SupplyContractorSearchItemAllOfFromJSON,
  SupplyContractorSearchItemAllOfFromJSONTyped,
  SupplyContractorSearchItemAllOfToJSON,
} from './';

/**
 *
 * @export
 * @interface SupplyContractorSearchItem
 */
export interface SupplyContractorSearchItem extends SupplyContractorModel {
  /**
   *
   * @type {boolean}
   * @memberof SupplyContractorSearchItem
   */
  availableForConnection: boolean;
}

export function SupplyContractorSearchItemFromJSON(
  json: any
): SupplyContractorSearchItem {
  return SupplyContractorSearchItemFromJSONTyped(json, false);
}

export function SupplyContractorSearchItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractorSearchItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...SupplyContractorModelFromJSONTyped(json, ignoreDiscriminator),
    availableForConnection: json['available_for_connection'],
  };
}

export function SupplyContractorSearchItemToJSON(
  value?: SupplyContractorSearchItem | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...SupplyContractorModelToJSON(value),
    available_for_connection: value.availableForConnection,
  };
}
