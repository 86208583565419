import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';

interface UseAdoptechSearchProps<T> {
  keys: string[];
  collection: T[];
}

export const useAdoptechSearch = <T>({
  keys,
  collection,
}: UseAdoptechSearchProps<T>) => {
  const [search, setSearch] = useState('');

  const fuse = useMemo(
    () =>
      new Fuse(collection, {
        ignoreLocation: true,
        includeScore: true,
        keys,
        threshold: 0,
      }),
    [collection, keys]
  );

  const results = useMemo(() => {
    return search ? fuse.search(search).map(x => x.item) : collection;
  }, [search, fuse, collection]);

  return { search, setSearch, results };
};
