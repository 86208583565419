/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface VendorSupplyContractorCreatePayloadVendorSupplyContractor
 */
export interface VendorSupplyContractorCreatePayloadVendorSupplyContractor {
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorCreatePayloadVendorSupplyContractor
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorCreatePayloadVendorSupplyContractor
   */
  tradeName?: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorCreatePayloadVendorSupplyContractor
   */
  countryOfIncorporation?: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorCreatePayloadVendorSupplyContractor
   */
  companyIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorCreatePayloadVendorSupplyContractor
   */
  supplyContractorId?: string;
  /**
   *
   * @type {string}
   * @memberof VendorSupplyContractorCreatePayloadVendorSupplyContractor
   */
  ownerId?: string;
}

export function VendorSupplyContractorCreatePayloadVendorSupplyContractorFromJSON(
  json: any
): VendorSupplyContractorCreatePayloadVendorSupplyContractor {
  return VendorSupplyContractorCreatePayloadVendorSupplyContractorFromJSONTyped(
    json,
    false
  );
}

export function VendorSupplyContractorCreatePayloadVendorSupplyContractorFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorSupplyContractorCreatePayloadVendorSupplyContractor {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    tradeName: !exists(json, 'trade_name') ? undefined : json['trade_name'],
    countryOfIncorporation: !exists(json, 'country_of_incorporation')
      ? undefined
      : json['country_of_incorporation'],
    companyIdentifier: !exists(json, 'company_identifier')
      ? undefined
      : json['company_identifier'],
    supplyContractorId: !exists(json, 'supply_contractor_id')
      ? undefined
      : json['supply_contractor_id'],
    ownerId: !exists(json, 'owner_id') ? undefined : json['owner_id'],
  };
}

export function VendorSupplyContractorCreatePayloadVendorSupplyContractorToJSON(
  value?: VendorSupplyContractorCreatePayloadVendorSupplyContractor | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    trade_name: value.tradeName,
    country_of_incorporation: value.countryOfIncorporation,
    company_identifier: value.companyIdentifier,
    supply_contractor_id: value.supplyContractorId,
    owner_id: value.ownerId,
  };
}
