/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum SupplyContractStatus {
  Draft = 'draft',
  PendingExecution = 'pending_execution',
  Live = 'live',
  Terminated = 'terminated',
}

export function SupplyContractStatusFromJSON(json: any): SupplyContractStatus {
  return SupplyContractStatusFromJSONTyped(json, false);
}

export function SupplyContractStatusFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SupplyContractStatus {
  return json as SupplyContractStatus;
}

export function SupplyContractStatusToJSON(
  value?: SupplyContractStatus | null
): any {
  return value as any;
}
