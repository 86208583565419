import React, { useState } from 'react';
import './AddSupplierSearchScreen.scss';
import { AddSupplierSearchScreen } from './AddSupplierSearchScreen';
import { AddSupplierManualScreen } from './AddSupplierManualScreen/AddSupplierManualScreen';
import { AddSupplierManualDetailsScreen } from './AddSupplierManualDetailsScreen/AddSupplierManualDetailsScreen';
import { AddSupplierManualFormScreen } from './AddSupplierManualFormScreen/AddSupplierManualFormScreen';
import { SupplyContractorSearchItem } from '../../../swagger/models/SupplyContractorSearchItem';
import { AddSupplierConnectionScreen } from './AddSupplierConnectionScreen/AddSupplierConnectionScreen';
import { AddSupplierInviteScreen } from './AddSupplierInviteScreen/AddSupplierInviteScreen';

export interface AddSupplierForm {
  currentScreen:
    | 'search'
    | 'manual-choose'
    | 'manual-details'
    | 'manual-form'
    | 'connection-form'
    | 'invite-form';
  selectedSearchSupplier: {
    vendorId: SupplyContractorSearchItem['vendorId'];
    name: SupplyContractorSearchItem['name'];
  };
}
export const AddSupplierModal: React.FC<{
  onHide: () => void;
}> = props => {
  const [form, setForm] = useState<AddSupplierForm>({
    currentScreen: 'search',
    selectedSearchSupplier: null,
  });

  const handleChange = (changes: { [key: string]: any }) => {
    setForm(prevForm => ({ ...prevForm, ...changes }));
  };

  if (form.currentScreen === 'search') {
    return (
      <AddSupplierSearchScreen
        form={form}
        onHide={props.onHide}
        handleChange={handleChange}
      />
    );
  }

  if (form.currentScreen === 'manual-choose') {
    return (
      <AddSupplierManualScreen
        form={form}
        onHide={props.onHide}
        handleChange={handleChange}
      />
    );
  }

  if (form.currentScreen === 'manual-details') {
    return (
      <AddSupplierManualDetailsScreen
        form={form}
        onHide={props.onHide}
        handleChange={handleChange}
      />
    );
  }

  if (form.currentScreen === 'manual-form') {
    return (
      <AddSupplierManualFormScreen
        form={form}
        onHide={props.onHide}
        handleChange={handleChange}
      />
    );
  }

  if (form.currentScreen === 'connection-form') {
    return (
      <AddSupplierConnectionScreen
        form={form}
        onHide={props.onHide}
        handleChange={handleChange}
      />
    );
  }

  if (form.currentScreen === 'invite-form') {
    return (
      <AddSupplierInviteScreen
        form={form}
        onHide={props.onHide}
        handleChange={handleChange}
      />
    );
  }

  return <div>No screen selected</div>;
};
