/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BusinessFunctionUpdatePayloadBusinessFunction
 */
export interface BusinessFunctionUpdatePayloadBusinessFunction {
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  functionIdentifier?: string;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  description?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  functionalTag?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  impactOfDiscontinuing?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  critical?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  licencedActivity?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  reasonForImportance?: string | null;
  /**
   *
   * @type {string}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  reviewDate?: string | null;
  /**
   *
   * @type {number}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  rpoHours?: number | null;
  /**
   *
   * @type {number}
   * @memberof BusinessFunctionUpdatePayloadBusinessFunction
   */
  rtoHours?: number | null;
}

export function BusinessFunctionUpdatePayloadBusinessFunctionFromJSON(
  json: any
): BusinessFunctionUpdatePayloadBusinessFunction {
  return BusinessFunctionUpdatePayloadBusinessFunctionFromJSONTyped(
    json,
    false
  );
}

export function BusinessFunctionUpdatePayloadBusinessFunctionFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BusinessFunctionUpdatePayloadBusinessFunction {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: !exists(json, 'name') ? undefined : json['name'],
    functionIdentifier: !exists(json, 'function_identifier')
      ? undefined
      : json['function_identifier'],
    description: !exists(json, 'description') ? undefined : json['description'],
    functionalTag: !exists(json, 'functional_tag')
      ? undefined
      : json['functional_tag'],
    impactOfDiscontinuing: !exists(json, 'impact_of_discontinuing')
      ? undefined
      : json['impact_of_discontinuing'],
    critical: !exists(json, 'critical') ? undefined : json['critical'],
    licencedActivity: !exists(json, 'licenced_activity')
      ? undefined
      : json['licenced_activity'],
    reasonForImportance: !exists(json, 'reason_for_importance')
      ? undefined
      : json['reason_for_importance'],
    reviewDate: !exists(json, 'review_date') ? undefined : json['review_date'],
    rpoHours: !exists(json, 'rpo_hours') ? undefined : json['rpo_hours'],
    rtoHours: !exists(json, 'rto_hours') ? undefined : json['rto_hours'],
  };
}

export function BusinessFunctionUpdatePayloadBusinessFunctionToJSON(
  value?: BusinessFunctionUpdatePayloadBusinessFunction | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    function_identifier: value.functionIdentifier,
    description: value.description,
    functional_tag: value.functionalTag,
    impact_of_discontinuing: value.impactOfDiscontinuing,
    critical: value.critical,
    licenced_activity: value.licencedActivity,
    reason_for_importance: value.reasonForImportance,
    review_date: value.reviewDate,
    rpo_hours: value.rpoHours,
    rto_hours: value.rtoHours,
  };
}
