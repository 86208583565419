/* tslint:disable */
/* eslint-disable */
/**
 * API V1
 * !!!!!! AUTOMATICALLY-GENERATED FILE, DO NOT EDIT DIRECTLY !!!!!!
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  VendorConnectionExtendedAllOf,
  VendorConnectionExtendedAllOfFromJSON,
  VendorConnectionExtendedAllOfFromJSONTyped,
  VendorConnectionExtendedAllOfToJSON,
  VendorConnectionModel,
  VendorConnectionModelFromJSON,
  VendorConnectionModelFromJSONTyped,
  VendorConnectionModelToJSON,
  VendorConnectionPermissions,
  VendorConnectionPermissionsFromJSON,
  VendorConnectionPermissionsFromJSONTyped,
  VendorConnectionPermissionsToJSON,
  VendorConnectionStatus,
  VendorConnectionStatusFromJSON,
  VendorConnectionStatusFromJSONTyped,
  VendorConnectionStatusToJSON,
  VendorUser,
  VendorUserFromJSON,
  VendorUserFromJSONTyped,
  VendorUserToJSON,
} from './';

/**
 *
 * @export
 * @interface VendorConnectionExtended
 */
export interface VendorConnectionExtended extends VendorConnectionModel {
  /**
   *
   * @type {VendorConnectionPermissions}
   * @memberof VendorConnectionExtended
   */
  permissions: VendorConnectionPermissions;
  /**
   *
   * @type {VendorUser}
   * @memberof VendorConnectionExtended
   */
  approver: VendorUser;
}

export function VendorConnectionExtendedFromJSON(
  json: any
): VendorConnectionExtended {
  return VendorConnectionExtendedFromJSONTyped(json, false);
}

export function VendorConnectionExtendedFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): VendorConnectionExtended {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ...VendorConnectionModelFromJSONTyped(json, ignoreDiscriminator),
    permissions: VendorConnectionPermissionsFromJSON(json['permissions']),
    approver: VendorUserFromJSON(json['approver']),
  };
}

export function VendorConnectionExtendedToJSON(
  value?: VendorConnectionExtended | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ...VendorConnectionModelToJSON(value),
    permissions: VendorConnectionPermissionsToJSON(value.permissions),
    approver: VendorUserToJSON(value.approver),
  };
}
