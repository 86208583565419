import React from 'react';

import './EditSupplierStatusDrawer.scss';
import { VendorSupplyContractorModel } from '../../../swagger/models/VendorSupplyContractorModel';
import { VendorUser } from '../../../swagger/models/VendorUser';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  ReviewFrequencyEnum,
  VendorSupplyContractorsIdPatchRequest,
  VendorSupplyContractorStatus,
} from '../../../swagger';
import { patchSupplierApproval } from '../store/supplierThunks';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { SelectionOption } from '../../../types/selectionOption';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { AdoptechTextArea2 } from '../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { useFormChangedFields } from '../../../hooks/useFormChangedFields';

interface EditSupplierStatusDrawerProps {
  onClose(): void;
  vendorSupplyContractor: {
    id: VendorSupplyContractorModel['id'];
    status: VendorSupplyContractorModel['status'];
    additionalNotes: VendorSupplyContractorModel['additionalNotes'];
    ownerId: VendorSupplyContractorModel['owner']['id'];
    // TODO add new fields here when BE will be done
  };
}

interface SupplierStatusForm {
  status: VendorSupplyContractorStatus;
  nextReview: '6 months' | '12 months' | '18 months' | '24 months';
  reviewers: VendorUser['id'][];
  ownerId: VendorUser['id'];
  action: 'approve' | 'reject' | 'none';
  additionalNotes: VendorSupplyContractorModel['additionalNotes'];
}

export const EditSupplierStatusDrawer: React.FC<
  EditSupplierStatusDrawerProps
> = props => {
  const isPatching = useSelector(
    (state: ApplicationState) =>
      state.supplier.patchSupplierApprovalStatus === 'loading'
  );
  const dispatch = useAppDispatch();

  const initialFormValues: SupplierStatusForm = {
    status: props.vendorSupplyContractor.status,
    nextReview: '12 months',
    reviewers: [],
    ownerId: props.vendorSupplyContractor.ownerId,
    action: 'none',
    additionalNotes: props.vendorSupplyContractor.additionalNotes,
  };

  const {
    form,
    setForm,
    getChangedFieldsWithValues,
    getChangedFields,
    resetForm,
  } = useFormChangedFields(initialFormValues);

  const handleDoneClick = async () => {
    const changedFields = getChangedFields();
    if (changedFields.length === 0) {
      props.onClose();
      return;
    }
    const changedFieldsWithValues = getChangedFieldsWithValues();

    const body: VendorSupplyContractorsIdPatchRequest = {
      id: props.vendorSupplyContractor.id,
      body: {
        vendorSupplyContractor: changedFieldsWithValues,
      },
    };
    const result = await dispatch(patchSupplierApproval(body));
    if (result.meta.requestStatus === 'rejected') return;
    props.onClose();
  };
  return (
    <AdoptechHS
      title="Update Status"
      onClose={props.onClose}
      showConfirmationWarning={false}
      theme="medium"
      noFormPadding
      show
      footer={
        <>
          <AdoptechButton
            onClick={props.onClose}
            variant={AdoptechButtonVariant.White}
            disabled={isPatching}
          >
            Cancel
          </AdoptechButton>
          <AdoptechButton
            onClick={handleDoneClick}
            variant={AdoptechButtonVariant.Primary}
            busy={isPatching}
          >
            Update
          </AdoptechButton>
        </>
      }
    >
      <EditSupplierStatusForm
        form={form}
        setForm={setForm}
        onClose={props.onClose}
        isPatching={isPatching}
        {...props}
      />
    </AdoptechHS>
  );
};

const EditSupplierStatusForm: React.FC<{
  isPatching: boolean;
  form: SupplierStatusForm;
  setForm: (form: SupplierStatusForm) => void;
  onClose(): void;
  vendorSupplyContractor: EditSupplierStatusDrawerProps['vendorSupplyContractor'];
}> = props => {
  const baseCss = 'editSupplierStatusForm';

  const handleChange = (field: keyof SupplierStatusForm, value: any) => {
    props.setForm({ ...props.form, [field]: value });
  };
  const { users, ownerOptions } = useOwnerOptions();

  const reviewFrequencyOptions = Object.values(ReviewFrequencyEnum).map(
    value => {
      return { label: value, value };
    }
  );

  const statusOptions = [
    {
      value: VendorSupplyContractorStatus.Approved,
      label: 'Approved',
    },
    {
      value: VendorSupplyContractorStatus.Pending,
      label: 'Pending',
    },
    {
      value: VendorSupplyContractorStatus.DueDiligence,
      label: 'Due Diligence',
    },
    {
      value: VendorSupplyContractorStatus.Offboarding,
      label: 'Offboarding',
    },
    {
      value: VendorSupplyContractorStatus.Closed,
      label: 'Closed',
    },
  ];
  return (
    <div className={baseCss}>
      <div className={baseCss + '--title'}>Status</div>
      <div className={baseCss + '--fields'}>
        <div className={baseCss + '--2-columns-fields'}>
          <div className={baseCss + '--field'}>
            <AdoptechReactSelect2
              id="status"
              label="Status"
              options={statusOptions}
              value={statusOptions.find(
                option => option.value === props.form.status
              )}
              onChange={(option: SelectionOption) =>
                handleChange('status', option.value)
              }
            />
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechReactSelect2
              id="review-frequency"
              options={reviewFrequencyOptions}
              onChange={(selectedOption: SelectionOption) => {
                handleChange('nextReview', selectedOption.value);
              }}
              value={reviewFrequencyOptions.find(
                option => option.value === props.form.nextReview
              )}
              placeholder="Set review frequency"
              label="Next review"
              isDisabled
            />
          </div>
        </div>
      </div>
      <div className={baseCss + '--line'} />
      <div className={baseCss + '--title'}>Reviewers</div>
      <div className={baseCss + '--fields'}>
        <div className={baseCss + '--field'}>
          <AdoptechReactSelect2
            id="reviewers"
            options={[]}
            onChange={() => {}}
            value={[]}
            placeholder="Select reviewer"
            isDisabled
          />
        </div>
        <AdoptechButton
          variant={AdoptechButtonVariant.White}
          extraClass="addButton"
          onClick={() => {}}
          icon={faPlus}
          disabled
        >
          Add
        </AdoptechButton>
      </div>
      <div className={baseCss + '--line'} />
      <div className={baseCss + '--title'}>Approval request</div>
      <div className={baseCss + '--fields'}>
        <div className={baseCss + '--field'}>
          Enter an email address of the person responsible for this supplier and
          choose an action below.
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechReactSelect2
            id="owner"
            options={ownerOptions}
            onChange={(option: SelectionOption) => {
              props.setForm({ ...props.form, ownerId: option.value });
            }}
            value={ownerOptions.find(
              option => option.value === props.form.ownerId
            )}
            label="Email address"
            placeholder="Select owner"
          />
        </div>
      </div>
      <div className={baseCss + '--line'} />
      <div className={baseCss + '--title'}>Add notes</div>
      <div className={baseCss + '--fields'}>
        <div className={baseCss + '--field'}>
          <AdoptechTextArea2
            id="additional-notes"
            onChange={e => {
              handleChange('additionalNotes', e.currentTarget.value);
            }}
            value={props.form.additionalNotes}
            label="Additional notes"
            placeholder="Add notes"
          />
        </div>
      </div>
    </div>
  );
};
