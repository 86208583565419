import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Company,
  CompanyExtended,
  ConfigsConfigIdGetConfigIdEnum,
} from '../../../swagger';
import { ApplicationState } from '../../../types/applicationState';
import { patchCompanyProfile } from '../../../store/vendors/vendorsThunks';
import { fetchCompanyConfig } from '../store/companyProfileThunks';
import { useAppDispatch } from '../../../hooks/useAppDispatch';

export interface ConfigItem {
  identifier: string;
  name: string;
  description?: string;
  guidance?: string;
}

export const useRegulatoryInformation = (company: CompanyExtended) => {
  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [updatedCompany, setUpdatedCompany] =
    useState<CompanyExtended>(company);
  const dispatch = useAppDispatch();

  const { updateCompanyProfileRequest } = useSelector(
    (state: ApplicationState) => state.vendors
  );

  const handleEditClick = useCallback(() => {
    setUpdatedCompany({ ...company });
    setIsInEditMode(true);
  }, [company]);

  const getChangedFields = (): Partial<Company> => {
    return Object.keys(updatedCompany).reduce(
      (acc: Partial<Company>, key: keyof Company) => {
        if (updatedCompany[key] !== company[key]) {
          (acc as any)[key] = updatedCompany[key];
        }
        return acc;
      },
      {} as Partial<Company>
    );
  };

  const handleSaveClick = () => {
    const changedFields = getChangedFields();
    const formHasChanges = Object.keys(changedFields).length > 0;
    if (formHasChanges) {
      dispatch(
        patchCompanyProfile({ company: { ...changedFields, id: company.id } })
      );
    }

    setIsInEditMode(false);
  };

  const [config, setConfig] = useState({
    financialEntityTypes: [],
    currencies: [],
    authorities: [],
  });

  useEffect(() => {
    if (!isInEditMode) return;

    const fetchEntitiesConfig = async () => {
      const financialEntityTypes =
        (await dispatch(
          fetchCompanyConfig({
            configId: ConfigsConfigIdGetConfigIdEnum.FinancialEntityTypes,
          })
        ).unwrap()) || [];

      setConfig(prevConfig => ({ ...prevConfig, financialEntityTypes }));
    };

    const fetchCurrenciesConfig = async () => {
      const currencies =
        (await dispatch(
          fetchCompanyConfig({
            configId: ConfigsConfigIdGetConfigIdEnum.Currencies,
          })
        ).unwrap()) || [];

      setConfig(prevConfig => ({ ...prevConfig, currencies }));
    };

    const fetchAuthoritiesConfig = async () => {
      const authorities =
        (await dispatch(
          fetchCompanyConfig({
            configId: ConfigsConfigIdGetConfigIdEnum.CompetentAuthorities,
          })
        ).unwrap()) || [];

      setConfig(prevConfig => ({ ...prevConfig, authorities }));
    };

    fetchEntitiesConfig();
    fetchCurrenciesConfig();
    fetchAuthoritiesConfig();
  }, [isInEditMode]);

  const configItemName = (
    identifier: string,
    collection: ConfigItem[]
  ): string => {
    return collection.find((item: ConfigItem) => item.identifier === identifier)
      ?.name;
  };

  return {
    isInEditMode,
    setIsInEditMode,
    updatedCompany,
    setUpdatedCompany,
    company,
    handleSaveClick,
    updateCompanyProfileRequest,
    handleEditClick,
    config,
    configItemName,
  };
};
