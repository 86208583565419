// TODO: replace this object with VendorAccessFeatureEnum
// Please specify new keys the same order as in VendorAccessFeatureEnum
export enum AccessObject {
  vendor_dashboard = 'vendor_dashboard',
  user_dashboard = 'user_dashboard',
  company_dashboard = 'company_dashboard',
  company_manage = 'company_manage',
  frameworks = 'frameworks',
  frameworks_manage = 'frameworks_manage',
  controls = 'controls',
  controls_create = 'controls_create',
  controls_update = 'controls_update',
  tasks = 'tasks',
  tasks_create = 'tasks_create',
  tasks_update = 'tasks_update',
  company_documents = 'company_documents',
  checklists = 'checklists',
  checklists_manage = 'checklists_manage',
  agreements = 'agreements',
  agreements_manage = 'agreements_manage',
  people = 'people',
  people_manage = 'people_manage',
  legal_register = 'legal_register',
  legal_register_manage = 'legal_register_manage',
  vendor_register = 'vendor_register',
  vendor_register_manage = 'vendor_register_manage',
  pestel = 'pestel',
  pestel_manage = 'pestel_manage',
  escrow = 'escrow',
  audits = 'audits',
  audits_manage = 'audits_manage',
  risk_register = 'risk_register',
  risk_register_create = 'risk_register_create',
  risk_register_update = 'risk_register_update',
  calendar = 'calendar',
  calendar_manage = 'calendar_manage',
  reports = 'reports',
  reports_manage = 'reports_manage',
  dataroom_root_tree_manage = 'dataroom_root_tree_manage',
  dataroom_control_evidence_manage = 'dataroom_control_evidence_manage',
  assessments = 'assessments',
  company_certificates = 'company_certificates',
  trusthub = 'trusthub',
  dashboard = 'dashboard',
  vendors_create = 'vendors_create',
  supply_contractors = 'supply_contractors',
  // Not added yet to the backend VendorAccessFeatureEnum:
  // Please don't add new values below, add it to spec/support/api/v1/schemas/vendor.yaml + above
  payment_plan_vendors = 'payment_plan_vendors',
  manage_approvals = 'manage_approvals',
  manage_read_requests = 'manage_read_requests',
  manage_versions = 'manage_versions',
  vendor_teams = 'vendor_teams',
  vendor_users = 'vendor_users',
  vendor_documents = 'vendor_documents',
  vendor_details = 'vendor_details',
  vendor_checklists = 'vendor_checklists',
  checklist_templates = 'checklist_templates',
  agreement_templates = 'agreement_templates',
  set_review_reminder = 'set_review_reminder',
  manage_ownership = 'manage_ownership',
  company_identifiers = 'company_identifiers',
  vendor_policies = 'vendor_policies',
  companies = 'companies',
  share = 'share',
  data_room_entities = 'data_room_entities',
  move = 'move',
  policy_categories = 'policy_categories',
  integrations = 'integrations',
  clients = 'clients',
}
