import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import {
  deleteContract,
  fetchContract,
  patchContract,
  postContract,
} from '../store/supplierThunks';
import {
  SupplyContractContractType,
  SupplyContractExtended,
  SupplyContractModel,
  SupplyContractModelTerminationReasonEnum,
  SupplyContractorModel,
  SupplyContractsIdPatchRequest,
  SupplyContractsIdPatchSupplyContractStatusEnum,
  VendorSupplyContractorModel,
  VendorsVendorIdSupplyContractsPostRequest,
  VendorsVendorIdSupplyContractsPostSupplyContractContractTypeEnum,
} from '../../../swagger';
import AdoptechHS from '../../../components/AdoptechHS/AdoptechHS';
import {
  AdoptechButton,
  AdoptechButtonVariant,
} from '../../../components/AdoptechButton/AdoptechButton';
import { AdoptechTextInput2 } from '../../../components/AdoptechTextInput2/AdoptechTextInput2';
import './EditContractDrawer.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../types/applicationState';
import { useFormChangedFields } from '../../../hooks/useFormChangedFields';
import { useOwnerOptions } from '../../../hooks/useOwnerOptions';
import { SelectionOption } from '../../../types/selectionOption';
import { AdoptechDatePicker2 } from '../../../components/AdoptechDatePicker2/AdoptechDatePicker2';
import moment from 'moment';
import { AdoptechTextArea2 } from '../../../components/AdoptechTextArea2/AdoptechTextArea2';
import { AdoptechCountrySelect } from '../../../components/AdoptechCountrySelect/AdoptechCountrySelect';
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import AdoptechModal from '../../../components/AdoptechModal/AdoptechModal';
import { Modal } from 'react-bootstrap';
import { AdoptechReactSelect2 } from '../../../components/AdoptechReacSelect2/AdoptechReactSelect2';
import { isValidUrl } from '../../../functions/isValidUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import AdoptechOverflowLine from '../../../components/AdoptechOverflowLine/AdoptechOverflowLine';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import AdoptechFlexTable from '../../../components/AdoptechFlexTable/AdoptechFlexTable';
import { adoptechGridTableCss } from '../../../components/AdoptechGridTable/AdoptechGridTable';

interface EditContractDrawerProps {
  onClose(): void;
  contractId: SupplyContractModel['id'];
  vendorSupplyContractorId: VendorSupplyContractorModel['supplyContractor']['id'];
}

type CreatePayload = VendorsVendorIdSupplyContractsPostRequest;
type UpdatePayload = SupplyContractsIdPatchRequest;
interface ContractForm {
  // overview section
  name: CreatePayload['supplyContractName'];
  contractId: CreatePayload['supplyContractContractId'];
  ownerId: CreatePayload['supplyContractVendorSupplyContractorId'];
  startDate: CreatePayload['supplyContractStartDate'];
  endDate: CreatePayload['supplyContractEndDate'];
  description: CreatePayload['supplyContractDescription'];
  status: UpdatePayload['supplyContractStatus'];
  // details section
  type: CreatePayload['supplyContractContractType'];
  intraGroup: CreatePayload['supplyContractIntragroup'];
  governingLaw: CreatePayload['supplyContractGoverningLaw'];
  noticePeriod: CreatePayload['supplyContractCustomerTerminationNoticeDaysAmount'];
  supplierNoticePeriod: CreatePayload['supplyContractSupplierTerminationNoticeDaysAmount'];
  signatory: CreatePayload['supplyContractSignatory'];
  signedOnBehalfOf: CreatePayload['supplyContractSignedOnBehalfOf'];
  location: CreatePayload['supplyContractLocation'];
  terminationReason: SupplyContractModelTerminationReasonEnum;
  masterContractId: CreatePayload['supplyContractMasterContractId'];
  documentLinks: { url: string; name: string }[];

  // view only
  supplyContractItems: SupplyContractExtended['supplyContractItems'];
}

export const EditContractDrawer: React.FC<EditContractDrawerProps> = props => {
  const isPatching = useSelector(
    (state: ApplicationState) =>
      state.supplier.patchContractStatus === 'loading'
  );
  const isDeleting = useSelector(
    (state: ApplicationState) =>
      state.supplier.deleteContractStatus === 'loading'
  );

  const isFetching = useSelector(
    (state: ApplicationState) =>
      state.supplier.fetchContractStatus === 'loading'
  );

  const isPosting = useSelector(
    (state: ApplicationState) => state.supplier.postContractStatus === 'loading'
  );

  const dispatch = useAppDispatch();

  const initialFormValues: ContractForm = {
    contractId: undefined,
    name: undefined,
    ownerId: undefined,
    startDate: undefined,
    endDate: undefined,
    description: undefined,
    status: undefined, // skip status for create
    type: VendorsVendorIdSupplyContractsPostSupplyContractContractTypeEnum.Standalone,
    intraGroup: undefined,
    governingLaw: undefined,
    noticePeriod: undefined,
    supplierNoticePeriod: undefined,
    signatory: undefined,
    signedOnBehalfOf: undefined,
    terminationReason: undefined,
    masterContractId: undefined,
    location: undefined,
    documentLinks: [],
    supplyContractItems: [],
  };

  const {
    form,
    setForm,
    getChangedFieldsWithValues,
    getChangedFields,
    resetForm,
  } = useFormChangedFields(initialFormValues);

  useEffect(() => {
    if (!props.contractId) return;
    const fetchDetails = async () => {
      const result = await dispatch(fetchContract(props.contractId)).unwrap();
      const formPayload: ContractForm = {
        contractId: result.contractId,
        name: result.name,
        ownerId: result.owner?.id,
        startDate: result.startDate,
        endDate: result.endDate,
        description: result.description,
        type: result.contractType as unknown as ContractForm['type'],
        intraGroup: result.intragroup,
        governingLaw: result.governingLaw,
        noticePeriod: result.customerTerminationNoticeDaysAmount,
        supplierNoticePeriod: result.supplierTerminationNoticeDaysAmount,
        signatory: result.signatory,
        signedOnBehalfOf: result.signedOnBehalfOf,
        terminationReason: result.terminationReason,
        status: result.status as unknown as ContractForm['status'],
        masterContractId: result.masterContractId,
        location: result.location,
        // backend has incorrect type, we must use and send array of objects
        documentLinks:
          result.documentLinks as unknown as ContractForm['documentLinks'],
        supplyContractItems: result.supplyContractItems,
      };
      resetForm(formPayload);
    };
    fetchDetails();
  }, []);

  const handleDoneClick = async () => {
    const mappedFields = {
      supplyContractName: 'name',
      supplyContractDescription: 'description',
      supplyContractContractId: 'contractId',
      supplyContractContractType: 'type',
      supplyContractSignatory: 'signatory',
      supplyContractSignedOnBehalfOf: 'signedOnBehalfOf',
      supplyContractStartDate: 'startDate',
      supplyContractEndDate: 'endDate',
      supplyContractGoverningLaw: 'governingLaw',
      supplyContractIntragroup: 'intraGroup',
      supplyContractTerminationReason: 'terminationReason',
      supplyContractSupplierTerminationNoticeDaysAmount: 'supplierNoticePeriod',
      supplyContractCustomerTerminationNoticeDaysAmount: 'noticePeriod',
      supplyContractMasterContractId: 'masterContractId',
      supplyContractStatus: 'status',
      supplyContractOwnerId: 'ownerId',
      supplyContractLocation: 'location',
      supplyContractDocumentLinks: 'documentLinks',
    };

    // Explanation: on the frontend->backend we use formData which doesn't allow nested objects
    // => on the frontend we need to stringify the documentLinks array
    // On the backend we use "string" as a type for documentLinks to prevent changes the defaultApi.ts

    const stringifyDocumentLinks = JSON.stringify(form.documentLinks);

    const createPayload = () => {
      const body: VendorsVendorIdSupplyContractsPostRequest =
        {} as VendorsVendorIdSupplyContractsPostRequest;
      Object.keys(mappedFields).forEach(apiKey => {
        const formKey = mappedFields[apiKey as keyof typeof mappedFields];
        // @ts-ignore
        body[apiKey as keyof VendorsVendorIdSupplyContractsPostRequest] =
          apiKey === 'supplyContractDocumentLinks'
            ? stringifyDocumentLinks
            : form[formKey as keyof ContractForm];
      });
      return body;
    };

    const updatePayload = () => {
      const changedFieldsWithValues = getChangedFieldsWithValues();
      const body: Partial<SupplyContractsIdPatchRequest> = {};
      Object.keys(changedFieldsWithValues).forEach(formKey => {
        const apiKey = Object.keys(mappedFields).find(
          key => mappedFields[key as keyof typeof mappedFields] === formKey
        );
        if (apiKey) {
          // @ts-ignore
          body[apiKey as keyof SupplyContractsIdPatchRequest] =
            apiKey === 'supplyContractDocumentLinks'
              ? stringifyDocumentLinks
              : changedFieldsWithValues[formKey as keyof ContractForm];
        }
      });
      return body;
    };

    if (!props.contractId) {
      const body = createPayload();
      const result = await dispatch(
        postContract({
          ...body,
          supplyContractVendorSupplyContractorId:
            props.vendorSupplyContractorId,
        })
      );
      if (result.meta.requestStatus === 'rejected') return;
      props.onClose();
      return;
    }

    const changedFields = getChangedFields();
    if (changedFields.length === 0) {
      props.onClose();
      return;
    }

    const body: SupplyContractsIdPatchRequest = {
      id: props.contractId,
      ...{
        supplyContractVendorSupplyContractorId: props.vendorSupplyContractorId,
      },
      ...updatePayload(),
    };
    const result = await dispatch(patchContract(body));
    if (result.meta.requestStatus === 'rejected') return;
    props.onClose();
  };

  return (
    <AdoptechHS
      noFormPadding
      title={props.contractId ? 'Edit contract' : 'Create new contract'}
      onClose={props.onClose}
      showConfirmationWarning={false}
      theme="medium"
      show
      footer={
        isFetching ? (
          <></>
        ) : (
          <>
            <AdoptechButton
              onClick={props.onClose}
              variant={AdoptechButtonVariant.White}
              disabled={isPatching || isDeleting || isPosting}
            >
              Cancel
            </AdoptechButton>
            <AdoptechButton
              onClick={handleDoneClick}
              variant={AdoptechButtonVariant.Primary}
              busy={isPatching || isPosting}
              disabled={isDeleting || !form.name || !form.contractId}
            >
              {props.contractId ? 'Update' : 'Create'}
            </AdoptechButton>
          </>
        )
      }
    >
      {isFetching ? (
        <LoadingSpinner />
      ) : (
        <EditContractForm
          form={form}
          setForm={setForm}
          contractId={props.contractId}
          onClose={props.onClose}
          isPatching={isPatching}
          isDeleting={isDeleting}
        />
      )}
    </AdoptechHS>
  );
};

const OverviewSection: React.FC<{
  form: ContractForm;
  setForm: (form: ContractForm) => void;
  contractId: string;
}> = ({ form, setForm, contractId }) => {
  const baseCss = 'editContractForm';
  const { ownerOptions } = useOwnerOptions();
  const statusOptions = [
    {
      value: SupplyContractsIdPatchSupplyContractStatusEnum.Draft,
      label: 'Draft',
    },
    {
      value: SupplyContractsIdPatchSupplyContractStatusEnum.PendingExecution,
      label: 'Pending Execution',
    },
    {
      value: SupplyContractsIdPatchSupplyContractStatusEnum.Live,
      label: 'Live',
    },
    {
      value: SupplyContractsIdPatchSupplyContractStatusEnum.Terminated,
      label: 'Terminated',
    },
  ];

  const handleChange = (field: keyof ContractForm, value: any) => {
    setForm({ ...form, [field]: value });
  };

  return (
    <>
      <div className={baseCss + '--title'}>Overview</div>
      <div className={baseCss + '--fields'}>
        <div className={baseCss + '--2-columns-fields'}>
          <div className={baseCss + '--field'}>
            <AdoptechTextInput2
              type="text"
              id="contract-id"
              onChange={e => {
                handleChange('contractId', e.currentTarget.value);
              }}
              value={form.contractId}
              label="Contract ID"
              placeholder="Enter contract ID"
              hasError={!form.contractId}
            />
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechTextInput2
              type="text"
              id="name"
              onChange={e => {
                handleChange('name', e.currentTarget.value);
              }}
              hasError={!form.name}
              value={form.name}
              label="Contract Name"
              placeholder="Enter contract name"
            />
          </div>
        </div>

        <div className={baseCss + '--2-columns-fields'}>
          <div className={baseCss + '--field'}>
            <AdoptechReactSelect2
              id="owner"
              options={ownerOptions}
              onChange={(option: SelectionOption) => {
                setForm({ ...form, ownerId: option.value });
              }}
              value={ownerOptions.find(option => option.value === form.ownerId)}
              label="Owner"
              placeholder="Select owner"
            />
          </div>

          {contractId && (
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                id="status"
                label="Status"
                options={statusOptions}
                value={statusOptions.find(
                  option => option.value === form.status
                )}
                onChange={(option: SelectionOption) =>
                  handleChange('status', option.value)
                }
              />
            </div>
          )}
        </div>

        <div className={baseCss + '--2-columns-fields'}>
          <div className={baseCss + '--field'}>
            <AdoptechDatePicker2
              deadLines={[]}
              disabled={false}
              labelText="Start Date"
              minDate={null}
              maxDate={form.endDate ? new Date(form.endDate) : null}
              popperPlacement="bottom-start"
              outputDate={form.startDate ? new Date(form.startDate) : null}
              onDateSelect={date => {
                const endOfDay = moment(date).endOf('day');
                const endOfDayToDateTime = endOfDay.toDate();
                handleChange('startDate', endOfDayToDateTime);
              }}
            />
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechDatePicker2
              deadLines={[]}
              disabled={false}
              labelText="End Date"
              minDate={form.startDate ? new Date(form.startDate) : null}
              maxDate={null}
              popperPlacement="bottom-start"
              outputDate={form.endDate ? new Date(form.endDate) : null}
              onDateSelect={date => {
                const endOfDay = moment(date).endOf('day');
                const endOfDayToDateTime = endOfDay.toDate();
                handleChange('endDate', endOfDayToDateTime);
              }}
            />
          </div>
        </div>

        <div className={baseCss + '--field'}>
          <AdoptechTextArea2
            id="description"
            onChange={e => {
              handleChange('description', e.currentTarget.value);
            }}
            value={form.description}
            label="Contract Description"
            placeholder="Enter contract description"
          />
        </div>
      </div>
    </>
  );
};
const DetailsSection: React.FC<{
  form: ContractForm;
  setForm: (form: ContractForm) => void;
}> = ({ form, setForm }) => {
  const baseCss = 'editContractForm';

  const contractTypeOptions = [
    {
      value:
        VendorsVendorIdSupplyContractsPostSupplyContractContractTypeEnum.Standalone,
      label: 'Standalone',
    },
    {
      value:
        VendorsVendorIdSupplyContractsPostSupplyContractContractTypeEnum.Master,
      label: 'Master',
    },
    {
      value:
        VendorsVendorIdSupplyContractsPostSupplyContractContractTypeEnum.Associated,
      label: 'Associated',
    },
  ];

  return (
    <>
      <div className={baseCss + '--title'}>Details</div>
      <div className={baseCss + '--fields'}>
        <div className={baseCss + '--2-columns-fields'}>
          <div className={baseCss + '--field'}>
            <AdoptechReactSelect2
              id="contractType"
              options={contractTypeOptions}
              onChange={(option: SelectionOption) => {
                setForm({
                  ...form,
                  type: option.value as VendorsVendorIdSupplyContractsPostSupplyContractContractTypeEnum,
                });
              }}
              value={contractTypeOptions.find(
                option => option.value === form.type
              )}
              label="Contract Type"
              placeholder="Select contract type"
            />
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechTextInput2
              type="text"
              id="masterContractId"
              onChange={e => {
                setForm({
                  ...form,
                  masterContractId: e.currentTarget.value,
                });
              }}
              value={form.masterContractId}
              label="Master Agreement ID"
              placeholder="N/A"
            />
          </div>
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechReactSelect2
            id="intraGroup"
            options={[
              { value: 'yes', label: 'Yes' },
              { value: 'no', label: 'No' },
            ]}
            onChange={(option: SelectionOption) => {
              setForm({
                ...form,
                intraGroup: option.value === 'yes',
              });
            }}
            value={
              form.intraGroup !== null
                ? {
                    value: form.intraGroup ? 'yes' : 'no',
                    label: form.intraGroup ? 'Yes' : 'No',
                  }
                : null
            }
            label="Intra group contract"
            placeholder="Select Yes or No"
          />
        </div>
        <div className={baseCss + '--2-columns-fields'}>
          <div className={baseCss + '--field'}>
            <AdoptechCountrySelect
              onChange={(option: SelectionOption) => {
                setForm({
                  ...form,
                  governingLaw: option.value,
                });
              }}
              theme="new"
              value={form.governingLaw}
              label="Governing Law"
              placeholder="Select governing law"
            />
          </div>
        </div>
        <div className={baseCss + '--2-columns-fields'}>
          <div className={baseCss + '--field'}>
            <AdoptechTextInput2
              type="number"
              id="noticePeriod"
              min={0}
              max={999}
              onChange={e => {
                setForm({
                  ...form,
                  noticePeriod: e.currentTarget.value as unknown as number,
                });
              }}
              value={form.noticePeriod as unknown as string}
              label="Notice Period ( days )"
              placeholder="Enter notice period"
            />
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechTextInput2
              type="number"
              id="supplierNoticePeriod"
              min={0}
              max={999}
              onChange={e => {
                setForm({
                  ...form,
                  supplierNoticePeriod: e.currentTarget
                    .value as unknown as number,
                });
              }}
              value={form.supplierNoticePeriod as unknown as string}
              label="Supplier Notice Period ( days )"
              placeholder="Enter supplier notice period"
            />
          </div>
        </div>
        <div className={baseCss + '--2-columns-fields'}>
          <div className={baseCss + '--field'}>
            <AdoptechTextInput2
              type="text"
              id="signatory"
              onChange={e => {
                setForm({
                  ...form,
                  signatory: e.currentTarget.value,
                });
              }}
              value={form.signatory}
              label="Signatory"
              placeholder="Enter signatory"
            />
          </div>
          <div className={baseCss + '--field'}>
            <AdoptechTextInput2
              type="text"
              id="signedOnBehalfOf"
              onChange={e => {
                setForm({
                  ...form,
                  signedOnBehalfOf: e.currentTarget.value,
                });
              }}
              value={form.signedOnBehalfOf}
              label="Signed on behalf of"
              placeholder="Enter signed on behalf of"
            />
          </div>
        </div>
        <div className={baseCss + '--field'}>
          <AdoptechTextInput2
            type="text"
            id="location"
            onChange={e => {
              setForm({
                ...form,
                location: e.currentTarget.value,
              });
            }}
            value={form.location}
            label="Location"
            placeholder="Enter location"
          />
        </div>
      </div>
    </>
  );
};

const EditContractForm: React.FC<{
  form: ContractForm;
  contractId: string;
  setForm: (form: ContractForm) => void;
  onClose: () => void;
  isPatching: boolean;
  isDeleting: boolean;
}> = props => {
  const baseCss = 'editContractForm';
  const dispatch = useAppDispatch();
  const handleDelete = async () => {
    if (!window.confirm('Are you sure you want to delete this contract?'))
      return;
    const result = await dispatch(deleteContract(props.contractId));
    if (result.meta.requestStatus === 'rejected') return;
    props.onClose();
  };

  const terminationReasonOptions = [
    {
      value: SupplyContractModelTerminationReasonEnum.Expired,
      label: 'Expired',
    },
    {
      value: SupplyContractModelTerminationReasonEnum.BreachOfApplicableLaw,
      label: 'Breach of Applicable Law',
    },
    {
      value: SupplyContractModelTerminationReasonEnum.ImpedimentsIdentified,
      label: 'Impediments Identified',
    },
    {
      value:
        SupplyContractModelTerminationReasonEnum.ManagementOrSecurityWeakness,
      label: 'Management or Security Weakness',
    },
    {
      value:
        SupplyContractModelTerminationReasonEnum.RequestedByCompetentAuthority,
      label: 'Requested by Competent Authority',
    },
    {
      value: SupplyContractModelTerminationReasonEnum.Other,
      label: 'Other',
    },
  ];

  return (
    <div className={baseCss}>
      <OverviewSection
        form={props.form}
        setForm={props.setForm}
        contractId={props.contractId}
      />
      <DocumentLinksSection form={props.form} setForm={props.setForm} />
      <div className={baseCss + '--line'} />
      <DetailsSection form={props.form} setForm={props.setForm} />

      {props.contractId && (
        <>
          <div className={baseCss + '--line'} />
          <div className={baseCss + '--title'}>Linked Services</div>
          <div className={baseCss + '--fields'}>
            <div className={baseCss + '--field' + ' linkedServiceTable'}>
              <AdoptechFlexTable
                draggable={false}
                theme="small"
                tableHeaders={
                  <div className={adoptechGridTableCss + '--header'}>
                    <div>Service ID</div>
                    <div>Service Type</div>
                    <div>Service Description</div>
                  </div>
                }
                tableRows={props.form.supplyContractItems.map(item => (
                  <div key={item.id} className={`${adoptechGridTableCss}--row`}>
                    <div>
                      <AdoptechOverflowLine>
                        {item.serviceId}
                      </AdoptechOverflowLine>
                    </div>
                    <div>
                      <AdoptechOverflowLine>
                        {item.serviceType}
                      </AdoptechOverflowLine>
                    </div>
                    <div>
                      <AdoptechOverflowLine>
                        {item.description}
                      </AdoptechOverflowLine>
                    </div>
                  </div>
                ))}
                extraClass=""
                collection={props.form.supplyContractItems}
                header={null}
                noDataText="No linked services to show"
              />
            </div>
          </div>

          <div className={baseCss + '--line'} />
          <div className={baseCss + '--title'}>Notes</div>
          <div className={baseCss + '--fields'}>
            <div className={baseCss + '--field'}>
              <AdoptechReactSelect2
                id="terminationReason"
                options={terminationReasonOptions}
                onChange={(option: SelectionOption) => {
                  props.setForm({
                    ...props.form,
                    terminationReason:
                      option.value as SupplyContractModelTerminationReasonEnum,
                  });
                }}
                value={terminationReasonOptions.find(
                  option => option.value === props.form.terminationReason
                )}
                label="Reason for termination"
                placeholder="Select reason for termination"
              />
            </div>
          </div>
          <div className={baseCss + '--line'} />
          <div className={baseCss + '--fields'}>
            <div className={baseCss + '--field'}>
              <AdoptechButton
                onClick={handleDelete}
                variant={AdoptechButtonVariant.Warning}
                busy={props.isDeleting}
                disabled={props.isPatching}
              >
                Delete
              </AdoptechButton>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const DocumentLinksSection: React.FC<{
  form: ContractForm;
  setForm: (form: ContractForm) => void;
}> = ({ form, setForm }) => {
  const baseCss = 'editContractForm';
  const [showEditDocumentLinkModal, setShowEditDocumentLinkModal] =
    useState(false);

  return (
    <>
      <div className={baseCss + '--line'} />
      <div className={baseCss + '--title'}>
        Document Links
        <AdoptechButton
          variant={AdoptechButtonVariant.White}
          icon={faPlus}
          iconSize="large"
          onClick={() => {
            setShowEditDocumentLinkModal(true);
          }}
          rounded
        >
          Add
        </AdoptechButton>
      </div>
      {form.documentLinks.length > 0 && (
        <div className={baseCss + '--fields'}>
          <div className={baseCss + '--field' + ' documentLinksTable'}>
            <AdoptechFlexTable
              draggable={false}
              theme="small"
              tableHeaders={
                <div className={adoptechGridTableCss + '--header'}>
                  <div>Name</div>
                  <div>Link</div>
                  <div></div>
                </div>
              }
              tableRows={form.documentLinks.map((link, index) => (
                <div key={index} className={`${adoptechGridTableCss}--row`}>
                  <div>
                    <AdoptechOverflowLine className="linkText">
                      {link.name}
                    </AdoptechOverflowLine>
                  </div>
                  <div>
                    <AdoptechOverflowLine className="linkText">
                      <a href={link.url} target="_blank" rel="noreferrer">
                        {link.url}
                      </a>
                    </AdoptechOverflowLine>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() => {
                        if (
                          window.confirm(
                            'Are you sure you want to remove this document link?'
                          )
                        ) {
                          const newLinks = form.documentLinks.filter(
                            documentLink => documentLink.url !== link.url
                          );
                          setForm({ ...form, documentLinks: newLinks });
                        }
                      }}
                    />
                  </div>
                </div>
              ))}
              extraClass=""
              collection={form.documentLinks}
              header={null}
              noDataText="No document links to show"
            />
          </div>
        </div>
      )}
      {showEditDocumentLinkModal && (
        <EditDocumentLinkModal
          onClose={() => setShowEditDocumentLinkModal(false)}
          onCreate={(name, url) => {
            setForm({
              ...form,
              documentLinks: [...form.documentLinks, { name, url }],
            });
            setShowEditDocumentLinkModal(false);
          }}
        />
      )}
    </>
  );
};

const EditDocumentLinkModal: React.FC<{
  onClose: () => void;
  onCreate: (name: string, url: string) => void;
}> = ({ onClose, onCreate }) => {
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');

  const handleCreate = () => {
    onCreate(name, url);
    setName('');
    setUrl('');
  };

  return (
    <AdoptechModal className="editDocumentLinkModal" onHide={onClose} show>
      <Modal.Header>Add Document Link</Modal.Header>
      <Modal.Body>
        <AdoptechTextInput2
          type="text"
          value={name}
          id="document-link-name"
          label="Name"
          onChange={e => setName(e.target.value)}
          hasError={!name}
        />
        <p></p>
        <AdoptechTextInput2
          type="text"
          value={url}
          id="document-link-url"
          label="URL"
          hasError={!url || !isValidUrl(url)}
          onChange={e => setUrl(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <AdoptechButton onClick={onClose}>Cancel</AdoptechButton>
        <AdoptechButton
          onClick={handleCreate}
          variant={AdoptechButtonVariant.Primary}
          disabled={!name || !url || !isValidUrl(url)}
        >
          Create
        </AdoptechButton>
      </Modal.Footer>
    </AdoptechModal>
  );
};
